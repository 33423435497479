import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import { loadAllParents } from '../../../../actions/activeModule';
import TopicBrowserTopNav from '../parent-topic-browser/TopicBrowserTopNav';

import BgShardUrl from '../../../../assets/images/img-parent-bg-models.png';
import MobileTopicCarousel from './MobileTopicCarousel';
import { clamp, sortParentTopics } from '../../../utils/TopicBrowserUtils';

const MobileParentTopicBrowser = ({
  dispatchLoadParents,
  token,
  parentTopics,
  completedModules,
  isLoading,
}) => {
  MobileParentTopicBrowser.defaultProps = {
    token: '',
  };

  const history = useHistory();

  const [isNavVisible, setIsNavVisible] = useState(false);
  const [selectedTopic, setSelectedTopic] = useState(0);
  const [xDown, setXDown] = useState(null);
  const [yDown, setYDown] = useState(null);
  const [scrollAmount, setScrollAmount] = useState(0);
  const SCROLL_THRESHOLD = 40;

  const selectNextTopic = () => {
    let nextTopic = selectedTopic + 1;
    if (nextTopic >= 5) {
      nextTopic = 4;
    }

    setSelectedTopic(nextTopic);
  };

  const selectPrevTopic = () => {
    let prevTopic = selectedTopic - 1;
    if (prevTopic < 0) {
      prevTopic = 0;
    }

    setSelectedTopic(prevTopic);
  };

  const selectTopic = () => {
    const loc = `/content/${parentTopics[selectedTopic].UID}`;
    history.push(loc);
  };

  const calcSwipe = (xVal, yVal) => {
    if (!xDown || !yDown) {
      return;
    }

    const deltaX = xDown - xVal;
    const deltaY = yDown - yVal;

    if (Math.abs(deltaX) > Math.abs(deltaY)) {
      if (Math.abs(deltaX) > 50) {
        if (isNavVisible) {
          if (deltaX > 0) {
            /* right swipe */
            setScrollAmount(scrollAmount + SCROLL_THRESHOLD);
            selectNextTopic();
          } else {
            /* left swipe */
            setScrollAmount(scrollAmount - SCROLL_THRESHOLD);
            selectPrevTopic();
          }
        }
      }
    } else if (Math.abs(deltaX) <= Math.abs(deltaY)) {
      if (Math.abs(deltaY) > 50) {
        if (deltaY > 0) {
          /* down swipe */
          if (!isNavVisible) {
            setIsNavVisible(true);
          }
        } else {
          /* up swipe */
        }
      }
    }

    setXDown(null);
    setYDown(null);
  };

  const handleOnTopNavClick = (index) => {
    const newIndex = index - 3;
    setScrollAmount(SCROLL_THRESHOLD * newIndex);
    setSelectedTopic(newIndex);
  };

  const handleTouchStart = (e) => {
    if (e.targetTouches.length > 0) {
      setXDown(e.targetTouches[0].clientX);
      setYDown(e.targetTouches[0].clientY);
    }
  };

  const handleTouchEnd = (e) => {
    if (e.changedTouches.length > 0) {
      calcSwipe(e.changedTouches[0].clientX, e.changedTouches[0].clientY);
    }
  };

  const handleDown = (e) => {
    setXDown(e.clientX);
    setYDown(e.clientY);
  };

  const handleUp = (e) => {
    if (!xDown || !yDown) {
      return;
    }

    const xVal = e.clientX;
    const yVal = e.clientY;

    const deltaX = xDown - xVal;
    const deltaY = yDown - yVal;

    if (Math.abs(deltaX) > Math.abs(deltaY)) {
      if (Math.abs(deltaX) > 50) {
        if (isNavVisible) {
          if (deltaX > 0) {
            /* right swipe */
            setScrollAmount(scrollAmount + SCROLL_THRESHOLD);
            selectNextTopic();
          } else {
            /* left swipe */
            setScrollAmount(scrollAmount - SCROLL_THRESHOLD);
            selectPrevTopic();
          }
        }
      }
    } else if (Math.abs(deltaX) <= Math.abs(deltaY)) {
      if (Math.abs(deltaY) > 50) {
        if (deltaY > 0) {
          /* down swipe */
          if (!isNavVisible) {
            setIsNavVisible(true);
          }
        } else {
          /* up swipe */
        }
      }
    }

    setXDown(null);
    setYDown(null);
  };

  const handleWheel = (e) => {
    if (!isNavVisible) {
      if (e.deltaY > 0) {
        setIsNavVisible(true);
      }
    } else {
      // get current scroll amount
      let s = scrollAmount;

      // increment or decrement the value depending on the scroll direction
      if (e.deltaX > 0) {
        s += 1;
      } else {
        s -= 1;
      }

      // calculate the maximum value the scroll can have based off of the number of parent topics
      const max = SCROLL_THRESHOLD * (parentTopics.length - 2);

      // make sure the new scroll value falls within the valid range
      s = clamp(s, 0, max);

      // calculate which parent topic index the user scrolled to
      // +1 because the first index is a special case
      let index = Math.floor(s / SCROLL_THRESHOLD) + 1;

      // special case for the first and last item to be at the beginning and end of the valid scroll range
      if (s < 5) {
        index = 0;
      } else if (s > max - 5) {
        index = parentTopics.length - 1;
      }

      // set the new scroll amount
      setScrollAmount(s);

      // set the selected topic
      setSelectedTopic(index);
    }
  };

  useEffect(async () => {
    dispatchLoadParents(token);
  });

  useEffect(() => {
    const handleKeyUp = (e) => {
      if (!isNavVisible) {
        if (e.keyCode === 38) {
          setIsNavVisible(true);
        }
      } else {
        switch (e.keyCode) {
          case 37:
            setScrollAmount(scrollAmount - SCROLL_THRESHOLD);
            selectPrevTopic();
            break;
          case 39:
            setScrollAmount(scrollAmount + SCROLL_THRESHOLD);
            selectNextTopic();
            break;
          case 13:
            selectTopic();
            break;
          default:
            break;
        }
      }
    };

    window.addEventListener('keyup', handleKeyUp);

    return () => {
      window.removeEventListener('keyup', handleKeyUp);
    };
  });

  return (
    <>
      {isLoading && !completedModules.progressTracker.modulesLoaded ? (
        <p>Loading</p>
      ) : (
        <div
          className="mobile-parent-topic-browser"
          role="menu"
          tabIndex="0"
          onMouseDown={handleDown}
          onMouseUp={handleUp}
          onTouchStart={handleTouchStart}
          onTouchEnd={handleTouchEnd}
          onWheel={handleWheel}
        >
          <img
            className={['bg-shards', isNavVisible ? 'hidden' : ''].join(' ')}
            src={BgShardUrl}
            alt="floating topic shards"
          />
          <TopicBrowserTopNav
            parentTopics={sortParentTopics(parentTopics)}
            currentActiveTopic={selectedTopic}
            isVisible={isNavVisible}
            handleTopNavClick={handleOnTopNavClick}
          />
          <div className={['instructions', isNavVisible ? 'hidden' : ''].join(' ')}>
            <figure className="scroll-image" />
            <p>Swipe to explore</p>
          </div>
          <MobileTopicCarousel
            isVisible={isNavVisible}
            topics={sortParentTopics(parentTopics)}
            progress={completedModules.progressTracker.completedModules}
            selectedTopic={selectedTopic}
          />
        </div>
      )}
    </>
  );
};

MobileParentTopicBrowser.propTypes = {
  dispatchLoadParents: PropTypes.func.isRequired,
  token: PropTypes.string,
  parentTopics: PropTypes.arrayOf(PropTypes.any).isRequired,
  completedModules: PropTypes.objectOf(PropTypes.any).isRequired,
  isLoading: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  token: state.auth.token,
  parentTopics: state.activeModule.parentTopics,
  isLoading: state.activeModule.loadingTopics,
  completedModules: state,
});

export default connect(mapStateToProps, { dispatchLoadParents: loadAllParents }, null, {
  forwardRef: true,
})(MobileParentTopicBrowser);
