import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import marked from 'marked';

import defaultPlaceholderImage from '../../../assets/images/video-placeholder.png';

const InstructionContent = ({ instructionVideo }) => {
  const [hide, setHide] = useState(false);
  const [play, setPlay] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const videoRef = useRef();

  const APIURL = process.env.REACT_APP_API_URL;

  const playVideo = () => {
    setPlay(true);
    setHide(true);
  };

  useEffect(() => {
    if (instructionVideo) {
      setIsLoading(false);
    }
  }, [instructionVideo]);

  // useEffect(() => {
  //   setHide(true);
  //   setPlay(true);
  // }, [isLoading])

  return (
    !isLoading && (
      <>
        <h1>{instructionVideo.pageTitle}</h1>
        {instructionVideo.pageDescription && (
          <div
            className="page-description"
            dangerouslySetInnerHTML={{
              __html: marked(instructionVideo.pageDescription),
            }}
          />
        )}
        <div className="video-wrapper">
          <div className="video-holder">
            <button
              className={`play-button ${hide && 'hide-placeholder'}`}
              type="button"
              onClick={() => {
                playVideo();
              }}
            >
              {instructionVideo.placeholderImage ? (
                <img
                  className="place-holder"
                  src={APIURL + instructionVideo.placeholderImage.url}
                  alt="instructional video"
                />
              ) : (
                <img
                  className="place-holder"
                  src={defaultPlaceholderImage}
                  alt="instructional video"
                />
              )}
              <div className="overlay" />
              <span className="video-play-icon">▶</span>
            </button>
            <iframe
              src={instructionVideo.url}
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="Instruction video"
            />
          </div>
        </div>
      </>
    )
  );
};

InstructionContent.propTypes = {
  instructionVideo: PropTypes.any.isRequired,
};

export default InstructionContent;
