import React, { useEffect, useState, Fragment } from 'react';
import { Link, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import axios from 'axios';

import Content from './Content';
import SavedModules from './profile/savedItems/SavedModules';
import Resources from './profile/resources/Resources';
import Account from './profile/Account';
import Settings from './profile/Settings';
import Particles from '../../shared/Particles';
import { setAlert } from '../../../actions/alert';
import InstructionContent from './InstructionContent';

const StandardContent = ({ auth, dispatchSetAlert }) => {
  const [about, setAbout] = useState();
  const [privacy, setPrivacy] = useState();
  const [termsConditions, setTermsConditions] = useState();
  const [instructionVideo, setInstructionVideo] = useState();
  const [loading, setLoading] = useState(false);
  const { pathname } = window.location;

  const history = useHistory();

  function wait(ms) {
    return new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  }

  useEffect(async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/content-pages`);
      setAbout(response.data.about);
      setPrivacy(response.data.privacy);
      setTermsConditions(response.data.terms_conditions);
      setLoading(true);
    } catch (err) {
      if (err) {
        const errors = err.response?.data.message[0].messages;

        if (errors) {
          errors.forEach((error) => dispatchSetAlert(error.message, 'danger'));
        }
      }
    }
  }, []);

  // Get Instruction video from db
  useEffect(async () => {
    if (!instructionVideo) {
      axios
        .get(`${process.env.REACT_APP_API_URL}/instruction-video`)
        // .then(await wait(2000))
        .then((response) => {
          // Handle state updates
          setInstructionVideo(response.data);
        })
        .catch((err) => {});
    }
  }, []);

  const closeSecondaryModal = (e) => {
    e.preventDefault();
    history.push('/dashboard');
  };

  const Body = () => {
    // Reset the value back to -1 since we're on a secondary page outside the child topics
    localStorage.setItem('parentVisited', -1);

    // Switcher changes the content of the page while menu is also visible
    const switcher = () => {
      switch (pathname) {
        default:
          return 'default';
        case '/saved':
          return <SavedModules />;
        case '/account':
          return <Account auth={auth} />;
        case '/settings':
          return <Settings />;
        case '/about':
          return <Content title="About" data={about} />;
        case '/resources':
          return <Resources />;
        case '/privacy':
          return <Content title="Privacy" data={privacy} />;
        case '/terms-and-conditions':
          return <Content title="Terms &amp; Conditions" data={termsConditions} />;
        case '/instructions':
          return <InstructionContent title="Instructions" instructionVideo={instructionVideo} />;
      }
    };

    return switcher();
  };

  return (
    <Fragment key="standard-content">
      <div className="standard-content-top-nav">
        <div className="close-link white">
          <Link to="/dashboard">
            <span className="close-link-icon" />
            <span className="close-link-text">close</span>
          </Link>
        </div>
      </div>
      <div className="profile-navigation">
        <ul>
          <li>
            <Link className={`${pathname === '/account' ? 'active' : ''} clickable`} to="/account">
              <i className="user-icon" />
              Account
            </Link>
          </li>
          <li>
            <Link className={`${pathname === '/saved' && 'active'} clickable`} to="/saved">
              <i className="saved-icon" />
              Saved
            </Link>
          </li>
          <li>
            <Link className={`${pathname === '/about' ? 'active' : ''} clickable`} to="/about">
              <i className="about-icon" />
              About
            </Link>
          </li>
          <li>
            <Link
              className={`${pathname === '/resources' ? 'active' : ''} clickable`}
              to="/resources"
            >
              <i className="resources-icon" />
              Resources
            </Link>
          </li>
        </ul>
        <div>
          <Link
            className={`${pathname === '/instructions' ? 'active' : ''} clickable`}
            to="/instructions"
          >
            Instructions
          </Link>
          <br />
          <Link className={`${pathname === '/privacy' ? 'active' : ''} clickable`} to="/privacy">
            Privacy Policy
          </Link>
          <br />
          <Link
            className={`${pathname === '/terms-and-conditions' ? 'active' : ''} clickable`}
            to="/terms-and-conditions"
          >
            Terms &amp; Conditions
          </Link>
          <br />
          <br />
          <span style={{ fontSize: '10px' }}>©2022 Dementia In New Light</span>
        </div>
      </div>
      <div className="standard-content-container">
        <section className="content-wrapper">{loading && <Body />}</section>
      </div>
      {pathname !== '/resources' ? <Particles /> : null}
    </Fragment>
  );
};

StandardContent.propTypes = {
  auth: PropTypes.shape({
    token: PropTypes.string,
    isAuthenticated: PropTypes.bool,
    user: PropTypes.objectOf(PropTypes.any),
  }),
  dispatchSetAlert: PropTypes.func.isRequired,
};

StandardContent.defaultProps = {
  auth: {
    token: null,
    user: null,
    isAuthenticated: false,
  },
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { dispatchSetAlert: setAlert })(StandardContent);
