import React, { useState, Fragment } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { setAlert } from '../../../actions/alert';

const ResetPassword = ({ dispatchSetAlert }) => {
  // Get Password Reset Token from URL
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const token = urlParams.get('code');

  // Setup component state
  const [formData, setFormData] = useState({
    newPassword: '',
    newPassword2: '',
  });

  const { newPassword, newPassword2 } = formData;

  // To Redirect user when form submitted
  const history = useHistory();
  const routeChange = (path) => {
    history.push(path);
  };

  // Handle user input
  const handleChange = (e) =>
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();

    axios
      .post(`${process.env.REACT_APP_API_URL}/auth/reset-password`, {
        code: token,
        password: newPassword,
        passwordConfirmation: newPassword2,
      })
      .then((response) => {
        routeChange('/login');
      })
      .catch((err) => {
        if (err) {
          const errors = err.response?.data.message[0].messages;

          if (errors) {
            errors.forEach((error) => dispatchSetAlert(error.message, 'danger'));
          }
        }
      });
  };

  return (
    <div className="container container-auth text-center">
      <h2>Reset your Password</h2>
      <form onSubmit={handleSubmit}>
        <input
          type="password"
          placeholder="New Password"
          name="newPassword"
          value={newPassword}
          onChange={(e) => handleChange(e)}
        />
        <input
          type="password"
          placeholder="Confirm Password"
          name="newPassword2"
          value={newPassword2}
          onChange={(e) => handleChange(e)}
        />
        <input type="submit" className="btn btn-lg" value="Submit" />
      </form>
    </div>
  );
};

ResetPassword.propTypes = {
  dispatchSetAlert: PropTypes.func.isRequired,
};

export default connect(null, { dispatchSetAlert: setAlert })(ResetPassword);
