import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import MobileChildTopicTopNav from './MobileChildTopicTopNav';
import ParentCompleted from '../../parentTopics/parentCompleted/ParentCompleted';

import { loadAllParents } from '../../../../actions/activeModule';
import { setAlert } from '../../../../actions/alert';
import MobileChildTopicCarousel from './MobileChildTopicCarousel';
import { clamp } from '../../../utils/TopicBrowserUtils';
import SubMenuIntro from '../child-topic-browser/SubMenuIntro';

const MobileChildTopicBrowser = ({
  parentTopic,
  progress,
  progressTracker,
  activeModule,
  dispatchSetAlert,
}) => {
  const [isNavVisible, setIsNavVisible] = useState(true);
  const [isIntroVisible, setIsIntroVisible] = useState(true);
  const [hasLoaded, setHasLoaded] = useState(true);
  const [selectedTopic, setSelectedTopic] = useState(0);
  const [xDown, setXDown] = useState(null);
  const [yDown, setYDown] = useState(null);
  const [isCompleted, setIsCompleted] = useState(false);
  const [currentModule, setCurrentModule] = useState({});
  const [nextModule, setNextModule] = useState({});
  const [scrollAmount, setScrollAmount] = useState(0);
  const SCROLL_THRESHOLD = 40;

  const history = useHistory();

  useEffect(() => {
    const currentIndex = activeModule.parentTopics.findIndex(
      (parent) => parent.id === parentTopic.id
    );

    let nextIndex;
    if (currentIndex + 1 < activeModule.parentTopics.length) {
      nextIndex = currentIndex + 1;
    } else {
      nextIndex = 0;
    }

    const current = progressTracker.completedModules.filter(
      (module) => module.id === parentTopic.id
    );

    setIsCompleted(current.completed);
    setCurrentModule(parentTopic);
    setNextModule(activeModule.parentTopics[nextIndex]);
  });

  const selectNextTopic = () => {
    let nextTopic = selectedTopic + 1;
    if (nextTopic >= parentTopic.child_topics.length) {
      nextTopic = parentTopic.child_topics.length - 1;
    }

    setSelectedTopic(nextTopic);
  };

  const selectPrevTopic = () => {
    let prevTopic = selectedTopic - 1;
    if (prevTopic < 0) {
      prevTopic = 0;
    }

    setSelectedTopic(prevTopic);
  };

  const selectTopic = () => {
    const loc = `/content/${parentTopic.UID}/${parentTopic.child_topics[selectedTopic].uid}`;
    history.push(loc);
  };

  const handleContinueClick = (e) => {
    setIsIntroVisible(false);
  };

  const calcSwipe = (xVal, yVal) => {
    if (!xDown || !yDown) {
      return;
    }

    const deltaX = xDown - xVal;
    const deltaY = yDown - yVal;

    if (isNavVisible) {
      if (Math.abs(deltaX) <= Math.abs(deltaY)) {
        if (Math.abs(deltaY) > 50) {
          if (deltaY > 0) {
            /* down swipe */
            // setIsNavVisible(true);
            setScrollAmount(scrollAmount + SCROLL_THRESHOLD);
            selectNextTopic();
          } else {
            /* up swipe */
            setScrollAmount(scrollAmount - SCROLL_THRESHOLD);
            selectPrevTopic();
          }
        }
      }
    }

    setXDown(null);
    setYDown(null);
  };

  const handleTouchStart = (e) => {
    if (e.targetTouches.length > 0) {
      setXDown(e.targetTouches[0].clientX);
      setYDown(e.targetTouches[0].clientY);
    }
  };

  const handleTouchEnd = (e) => {
    if (e.changedTouches.length > 0) {
      calcSwipe(e.changedTouches[0].clientX, e.changedTouches[0].clientY);
    }
  };

  const handleDown = (e) => {
    setXDown(e.clientX);
    setYDown(e.clientY);
  };

  const handleUp = (e) => {
    calcSwipe(e.clientX, e.clientY);
  };

  const handleKeyUp = (e) => {
    switch (e.keyCode) {
      case 38:
        setScrollAmount(scrollAmount - SCROLL_THRESHOLD);
        selectPrevTopic();
        break;
      case 40:
        setScrollAmount(scrollAmount + SCROLL_THRESHOLD);
        selectNextTopic();
        break;
      case 9:
        if (e.target.className.indexOf('mobile-child-topic-label') >= 0) {
          setSelectedTopic(e.target.tabIndex - 1);
        }
        break;
      case 32:
      case 13:
        if (!isIntroVisible) {
          selectTopic();
        }
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    window.addEventListener('keyup', handleKeyUp);

    return () => {
      window.removeEventListener('keyup', handleKeyUp);
    };
  });

  const handleWheel = (e) => {
    let s = scrollAmount;

    if (e.deltaY > 0) {
      s += 1;
    } else {
      s -= 1;
    }

    const max =
      SCROLL_THRESHOLD *
      (parentTopic.child_topics.length - (parentTopic.child_topics.length < 2 ? 1 : 2));

    s = clamp(s, 0, max);

    let index = Math.floor(s / SCROLL_THRESHOLD) + 1;

    if (s < 5) {
      index = 0;
    } else if (s > max - 5) {
      index = parentTopic.child_topics.length - 1;
    }

    setScrollAmount(s);

    setSelectedTopic(index);
  };

  const calculateCompleted = (topicId, prog) => {
    let comp = 0;
    let activeProgress = null;

    prog.forEach((element) => {
      const { id } = element;
      const elementId = id;

      if (elementId === topicId) {
        activeProgress = element;
      }
    });

    if (activeProgress == null) {
      dispatchSetAlert('an issue occured searching for the active module progress', 'danger');
    } else {
      activeProgress.child_topics.forEach((element) => {
        const { completed } = element;

        if (completed) {
          comp += 1;
        }
      });
    }

    return comp;
  };

  return (
    <>
      {isCompleted ? (
        <ParentCompleted topic={parentTopic} nextModule={nextModule} />
      ) : (
        <div
          className={['mobile-child-topic-browser', parentTopic.UID].join(' ')}
          role="menu"
          tabIndex="0"
          onMouseDown={handleDown}
          onMouseUp={handleUp}
          onTouchStart={handleTouchStart}
          onTouchEnd={handleTouchEnd}
          onWheel={handleWheel}
        >
          <figure className="bg-models" />
          <figure className="fg-models" />
          <MobileChildTopicTopNav
            topic={parentTopic.name}
            topicUid={parentTopic.UID}
            completed={calculateCompleted(parentTopic.id, progress)}
            max={parentTopic.child_topics.length}
            isVisible={isNavVisible}
          />
          <SubMenuIntro
            percentLoaded={100}
            hasLoaded={hasLoaded}
            topic={parentTopic}
            isVisible={isIntroVisible}
            onSubMenuContinueClick={handleContinueClick}
          />
          <MobileChildTopicCarousel
            isVisible={!isIntroVisible}
            parentTopic={parentTopic.UID}
            topics={parentTopic.child_topics}
            progress={progress.find((o) => o.id === parentTopic.id)}
            selectedTopic={selectedTopic}
          />
        </div>
      )}
    </>
  );
};

MobileChildTopicBrowser.propTypes = {
  parentTopic: PropTypes.objectOf(PropTypes.any).isRequired,
  progress: PropTypes.arrayOf(PropTypes.any).isRequired,
  progressTracker: PropTypes.objectOf(PropTypes.any).isRequired,
  activeModule: PropTypes.objectOf(PropTypes.any).isRequired,
  dispatchSetAlert: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  completedModules: state,
  progress: state.progressTracker.completedModules,
  progressTracker: state.progressTracker,
  activeModule: state.activeModule,
});

export default connect(
  mapStateToProps,
  {
    dispatchLoadParents: loadAllParents,
    dispatchSetAlert: setAlert,
  },
  null,
  {}
)(MobileChildTopicBrowser);
