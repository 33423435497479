import React from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';
import marked from 'marked';

import LoginOptions from '../auth/login/LoginOptions';
import Instructions from './Instructions';

const animationContainer = ({
  introText,
  position,
  goToInstructions,
  setGoToInstructions,
  goToOptions,
  setGoToOptions,
  instructionVideo,
  hideText,
  setInstructionClasses,
}) => {
  return (
    <div className="animationContainer">
      <CSSTransition
        in={!goToInstructions && !goToOptions && !hideText}
        timeout={1000}
        classNames="my-node"
        onExited={() => {
          setGoToInstructions(true);
          setInstructionClasses(true);
        }}
        unmountOnExit
      >
        <div className="intro-text">
          {introText.map((item, index) => {
            return (
              <div
                key={item.id}
                className={`serif-text text-xl ${position === index ? 'display' : 'hide'}`}
                dangerouslySetInnerHTML={{
                  __html: marked(item.TextSection),
                }}
              />
            );
          })}
        </div>
      </CSSTransition>
      <CSSTransition
        in={goToInstructions}
        timeout={1000}
        classNames="my-node"
        onExited={() => {
          setGoToOptions(true);
          setInstructionClasses(false);
        }}
        unmountOnExit
      >
        <div>
          <Instructions
            goToOptions={goToOptions}
            setGoToOptions={setGoToOptions}
            setGoToInstructions={setGoToInstructions}
            instructionVideo={instructionVideo}
          />
        </div>
      </CSSTransition>
      <CSSTransition in={goToOptions} timeout={1000} classNames="my-node" unmountOnExit>
        <div>
          <LoginOptions />
        </div>
      </CSSTransition>
    </div>
  );
};

animationContainer.propTypes = {
  introText: PropTypes.arrayOf(PropTypes.any).isRequired,
  position: PropTypes.number.isRequired,
  goToInstructions: PropTypes.bool.isRequired,
  setGoToInstructions: PropTypes.func.isRequired,
  goToOptions: PropTypes.bool.isRequired,
  setGoToOptions: PropTypes.func.isRequired,
  instructionVideo: PropTypes.any.isRequired,
  hideText: PropTypes.bool.isRequired,
  setInstructionClasses: PropTypes.func.isRequired,
};

export default animationContainer;
