import {
  CLEAR_MODULES,
  SET_MODULES,
  UPDATE_MODULES,
  USER_OVERRIDE,
  SESSION_TRACKER,
} from '../actions/types';

const initialState = {
  completedModules: [],
  loadingModules: true,
  modulesLoaded: false,
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_MODULES:
      if (!state.modulesLoaded) {
        return {
          ...state,
          completedModules: payload,
          loadingModules: false,
          modulesLoaded: true,
        };
      }
      return { ...state };

    case UPDATE_MODULES: {
      const updatedModules = [...state.completedModules];
      updatedModules[payload.index] = payload.parentTopic;
      return {
        ...state,
        completedModules: updatedModules,
        loadingModules: false,
      };
    }

    case CLEAR_MODULES:
      return {
        ...state,
        completedModules: payload,
        loadingModules: false,
        modulesLoaded: true,
      };

    case USER_OVERRIDE:
      return {
        ...state,
        modulesLoaded: false,
      };

    case SESSION_TRACKER:
      {
        const updatedModules = [...state.completedModules];
        updatedModules[payload.index] = payload.parentTopic;
        sessionStorage.setItem('progress', JSON.stringify(updatedModules));
      }
      return state;

    default:
      return state;
  }
};
