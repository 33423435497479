import React, { Component } from 'react';

import PropTypes from 'prop-types';

class ShardLabel extends Component {
  static STATE_LABEL_HIDDEN = 0;

  static STATE_LABEL_VISIBLE = 1;

  static STATE_LABEL_INACTIVE = 2;

  constructor(props) {
    super(props);

    this.delegate = null;

    this.state = {
      posX: 0,
      posY: 0,
    };
  }

  setDelegate(del) {
    this.delegate = del;
  }

  onLabelClick = () => {
    if (this.delegate != null) {
      this.delegate.handleLabelClick();
    }
  };

  transform(xVal, yVal) {
    this.setState({
      posX: xVal,
      posY: yVal,
    });
  }

  render() {
    const { label, labelState, completed, max, onLabelClick, tabIndex } = this.props;
    const { posX, posY } = this.state;

    let lState = ShardLabel.STATE_LABEL_HIDDEN;
    switch (labelState) {
      case ShardLabel.STATE_LABEL_HIDDEN:
        lState = 'shard-label-hidden';
        break;
      case ShardLabel.STATE_LABEL_VISIBLE:
        lState = 'shard-label-visible';
        break;
      case ShardLabel.STATE_LABEL_INACTIVE:
        lState = 'shard-label-inactive';
        break;
      default:
        lState = 'shard-label-hidden';
        break;
    }

    const percentCompleted = (completed / max) * 100;

    return (
      <div
        role="button"
        tabIndex={tabIndex}
        className={['shard-label', lState].join(' ')}
        onClick={onLabelClick}
        onKeyDown={() => {}}
        style={{ transform: `translate(-50%, -50%) translate(${posX}px, ${posY}px)` }}
      >
        <h3>{label}</h3>
        <div className="topic-status">
          <span className="topic-status-label">topics explored</span>
          <div className="progress-bar">
            <div
              className="progress"
              style={{
                width: `${percentCompleted}%`,
              }}
            />
          </div>
          <span className="topic-status-progress">
            {completed}
            /
            {max}
          </span>
        </div>
      </div>
    );
  }
}

ShardLabel.propTypes = {
  label: PropTypes.string.isRequired,
  labelState: PropTypes.number.isRequired,
  onLabelClick: PropTypes.func.isRequired,
  completed: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  tabIndex: PropTypes.number.isRequired,
};

export default ShardLabel;
