import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import ResourcesItem from './ResourcesItem';

const ResourcesList = ({ modules }) => {
  useEffect(() => {}, []);

  return (
    <>
      {modules.length === 0 ? (
        <p className="no-modules">No resources</p>
      ) : (
        modules
          .sort(function (a, b) {
            // Sort the links alphabetically by title
            if (a.LinkTitle < b.LinkTitle) return -1;
            if (a.LinkTitle > b.LinkTitle) return 1;
            return 0;
          })
          .map((module) => {
            return <ResourcesItem module={module} key={module.id} />;
          })
      )}
    </>
  );
};

ResourcesList.propTypes = {
  modules: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default ResourcesList;
