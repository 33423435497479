import React from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';

import marked from 'marked';

const ShortAnswer = ({
  handleSubmit,
  handleClear,
  disabled,
  handleType,
  reflectionQuestion,
  showWritten,
  typedAnswer,
}) => {
  return (
    <form className="mChoiceForm">
      <p dangerouslySetInnerHTML={{ __html: marked(reflectionQuestion.Question) }} />
      <textarea placeholder="Type your answer" onChange={handleType} value={typedAnswer} />
      <div className="actions">
        <button
          type="button"
          name="submit"
          className="submit"
          disabled={disabled}
          onClick={handleSubmit}
        >
          Continue
          <span className="next-arrow" />
        </button>
        <CSSTransition in={showWritten} timeout={300} classNames="display" unmountOnExit>
          <button
            type="button"
            name="clear"
            className="clear"
            disabled={disabled}
            onClick={handleClear}
          >
            Clear
            <span className="clear-x" />
          </button>
        </CSSTransition>
      </div>
    </form>
  );
};

ShortAnswer.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleClear: PropTypes.func.isRequired,
  handleType: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  reflectionQuestion: PropTypes.objectOf(PropTypes.any).isRequired,
  showWritten: PropTypes.bool.isRequired,
  typedAnswer: PropTypes.string.isRequired,
};

export default ShortAnswer;
