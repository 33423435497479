import * as THREE from 'three';

import { degreesToRadians, getRandomIntInclusive } from '../../../utils/TopicBrowserUtils';

import particleSpriteTexture from '../../../../assets/textures/particle-sprite.png';

class ParticleGroup extends THREE.Group {
  constructor(colour, numParticles, minRad, maxRad, minY, maxY) {
    super();

    this.colour = colour;
    this.numParticles = numParticles;
    this.minRad = minRad;
    this.maxRad = maxRad;
    this.minY = minY;
    this.maxY = maxY;

    this.init();
  }

  init = () => {
    // Load the sprite
    const particleSprite = new THREE.TextureLoader().load(particleSpriteTexture);

    const geometry = new THREE.BufferGeometry();
    const vertices = [];

    for (let i = 0; i < this.numParticles; i += 1) {
      const radius = getRandomIntInclusive(this.minRad, this.maxRad);
      const angle = degreesToRadians(getRandomIntInclusive(0, 360));

      const y = getRandomIntInclusive(this.minY, this.maxY);

      const x = radius * Math.cos(angle * i);
      const z = radius * Math.sin(angle * i);

      vertices.push(x, y, z);
    }

    geometry.setAttribute('position', new THREE.Float32BufferAttribute(vertices, 3));

    const material = new THREE.PointsMaterial({
      size: 8,
      color: this.colour,
      sizeAttenuation: true,
      map: particleSprite,
      //   alphaMap: particleAlphaMap,
      alphaTest: 0.5,
      opacity: 1,
      transparent: true,
    });
    // material.color.setHex(this.colour);

    const particles = new THREE.Points(geometry, material);
    this.add(particles);
  };
}

export default ParticleGroup;
