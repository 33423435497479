import * as THREE from 'three';

import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';

import modelUrl01 from '../../../../assets/models/sub-topic-model-01.glb';
import modelUrl02 from '../../../../assets/models/sub-topic-model-02.glb';
import modelUrl03 from '../../../../assets/models/sub-topic-model-03.glb';
import modelUrl04 from '../../../../assets/models/sub-topic-model-04.glb';
import modelUrl05 from '../../../../assets/models/sub-topic-model-05.glb';
import modelUrl06 from '../../../../assets/models/sub-topic-model-06.glb';
import modelUrl07 from '../../../../assets/models/sub-topic-model-07.glb';
import modelUrl08 from '../../../../assets/models/sub-topic-model-08.glb';

import { degreesToRadians, getRandomIntInclusive } from '../../../utils/TopicBrowserUtils';

class SubMenuTopicModel extends THREE.Group {
  constructor(index, topic) {
    super();

    this.delegate = null;

    const urls = [
      modelUrl01,
      modelUrl02,
      modelUrl03,
      modelUrl04,
      modelUrl05,
      modelUrl06,
      modelUrl07,
      modelUrl08,
    ];

    let ind = ((index + 1) % urls.length) - 1;
    if (ind < 0) ind = urls.length - 1;

    this.objUrl = urls[ind];

    this.initPos = new THREE.Vector3();
    this.destPos = new THREE.Vector3();

    this.hasLoaded = false;

    this.startAnimateIn = false;

    this.labelAnchor = new THREE.Vector2();

    this.clock = new THREE.Clock();

    this.lerpRate = getRandomIntInclusive(50, 80) / 100;

    this.topic = topic;

    this.init();
  }

  init = () => {
    let color;

    switch (this.topic) {
      case 'relationships':
        color = 0x977fff;
        break;
      case 'stigma':
        color = 0xff4a69;
        break;
      case 'identity':
        color = 0xd5db41;
        break;
      case 'current-culture-of-dementia-care':
        color = 0xfb5927;
        break;
      case 'possibilities':
        color = 0x86e3ed;
        break;
      default:
        color = 0xffffff;
        break;
    }

    const loader = new GLTFLoader();
    loader.load(
      this.objUrl,
      (gltf) => {
        const f = 10;
        gltf.scene.scale.set(f, f, f);

        const xRot = degreesToRadians(getRandomIntInclusive(0, 360));
        const yRot = degreesToRadians(getRandomIntInclusive(0, 360));
        const zRot = degreesToRadians(getRandomIntInclusive(0, 360));

        gltf.scene.rotation.set(xRot, yRot, zRot);

        gltf.scene.traverse(function (child) {
          const c = child;
          if (c instanceof THREE.Mesh) {
            c.material.specular = new THREE.Color(color);
            c.material.emissive = new THREE.Color(color);
            c.material.emissiveIntensity = 0.5;
          }
        });
        this.add(gltf.scene);
        this.hasLoaded = true;

        if (this.delegate != null) {
          this.delegate.onTopicModelLoadComplete();
        }
      },
      undefined,
      function (error) {
        // console.error(error);
      }
    );
  };

  setMenuDestPos(pos) {
    this.destPos = pos;
  }

  setInitPos(pos) {
    this.initPos = pos;
  }

  animateIn() {
    this.startAnimateIn = true;
  }

  destroy() {
    this.delegate = null;

    this.initPos = null;
    this.destPos = null;

    this.labelAnchor = null;

    this.clock = null;

    this.traverse((object) => {
      if (!object.isMesh) return;

      object.geometry.dispose();
    });
  }

  animate() {
    if (this.hasLoaded) {
      if (this.startAnimateIn) {
        const delta = this.clock.getDelta();
        const newPos = this.position.lerp(this.destPos, this.lerpRate * delta);
        this.position.set(newPos.x, newPos.y, newPos.z);
      }
    }
  }
}

export default SubMenuTopicModel;
