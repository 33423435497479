import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import marked from 'marked';

const Content = ({ title, data }) => {
  return (
    <div className="content">
      <h2>{title}</h2>
      {data.content && (
        <div
          className={title === 'Terms & Conditions' ? 'terms' : ''}
          dangerouslySetInnerHTML={{ __html: marked(data.content) }}
        />
      )}
    </div>
  );
};

Content.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.shape({
    sectionTitle: PropTypes.string,
    content: PropTypes.string,
  }).isRequired,
};

export default Content;
