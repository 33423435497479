import React, { useEffect, useState, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';

import SavedModulesList from './SavedModulesList';

import { loadAllParents, loadAllChildren } from '../../../../../actions/activeModule';
import { parentSort } from '../../../../utils/moduleSort';

const SavedModules = ({ auth, parentTopics, childTopics, dispatchSavedModules }) => {
  // Set up states for component
  const [savedModules, setSavedModules] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [editMode, setEditMode] = useState(false);
  const [update, setUpdate] = useState(true);

  useEffect(() => {
    // if user was previously editing, set component into editmode
    if (localStorage.getItem('edit')) {
      setEditMode(localStorage.getItem('edit'));
    }
    // remove localstorage item when component unmounts
    return () => {
      localStorage.removeItem('edit');
    };
  });

  useEffect(() => {
    // verify the user is loaded
    if (auth.user) {
      // verify the information is loaded in state
      if (childTopics.length !== 0 && parentTopics.length !== 0 && auth.user.data.savedModules) {
        // sort saved module information
        const save = parentSort(parentTopics, childTopics, auth.user.data.savedModules);
        // save sorted information in redux
        setSavedModules(save);
      }
      // if user is not authenticated
    } else {
      // get saved items from local storage
      const localSaved = JSON.parse(localStorage.getItem('savedModules'));
      if (childTopics.length !== 0 && parentTopics.length !== 0 && localSaved) {
        const save = parentSort(parentTopics, childTopics, localSaved);
        setSavedModules(save);
      }
    }
  }, [parentTopics, childTopics, auth]);

  useEffect(() => {
    // render component if information is loaded
    if (Object.keys(savedModules).length !== 0) {
      setIsLoading(false);
    }
  }, [savedModules]);

  useEffect(() => {
    if (auth.user) {
      // verify the information is loaded in state
      if (childTopics.length !== 0 && parentTopics.length !== 0 && auth.user.data.savedModules) {
        // sort saved module information
        const save = parentSort(parentTopics, childTopics, auth.user.data.savedModules);
        // save sorted information in redux
        setSavedModules(save);
      }
    }
  }, [auth.user?.data.savedModules]);

  useEffect(() => {
    if (!auth.user) {
      const localSaved = JSON.parse(localStorage.getItem('savedModules'));
      if (childTopics.length !== 0 && parentTopics.length !== 0 && localSaved) {
        const save = parentSort(parentTopics, childTopics, localSaved);
        const newSave = { ...save };
        setSavedModules(newSave);
      }
    }
  }, [update]);

  // handle click on edit button
  const handleEditClick = (e) => {
    e.preventDefault();
    if (!editMode) {
      setEditMode(true);
    } else {
      setEditMode(false);
    }

    // save state to local storage so it persists through refresh
    if (!localStorage.getItem('edit')) {
      localStorage.setItem('edit', true);
    } else {
      localStorage.removeItem('edit');
    }
  };

  return (
    <section className={`saved-modules ${isLoading && 'loading'}`}>
      <CSSTransition in={!isLoading} timeout={1000} classNames="intro-container-ani" unmountOnExit>
        <div>
          <div className="saved-modules-header">
            <h2>Saved</h2>
            <button type="button" className="edit-button" onClick={handleEditClick}>
              Edit
            </button>
          </div>
          {parentTopics.map((parent) => {
            return (
              <div className="saved-module-container" key={parent.UID}>
                <h3 className="saved-parent">{parent.name}</h3>
                <SavedModulesList
                  modules={savedModules[parent.UID]}
                  editMode={editMode}
                  auth={auth}
                  update={update}
                  setUpdate={setUpdate}
                />
              </div>
            );
          })}
        </div>
      </CSSTransition>
    </section>
  );
};

SavedModules.propTypes = {
  auth: PropTypes.objectOf(PropTypes.any).isRequired,
  parentTopics: PropTypes.arrayOf(PropTypes.any).isRequired,
  childTopics: PropTypes.arrayOf(PropTypes.any).isRequired,
  dispatchSavedModules: PropTypes.func.isRequired,
  // savedModules: PropTypes.objectOf(PropTypes.any).isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  childTopics: state.activeModule.childTopics,
  parentTopics: state.activeModule.parentTopics,
  // savedModules: state.activeModule.savedModules,
});

const mapDispatchToProps = (dispatch) => {
  return {
    dispatchSavedModules: (payload) => {
      dispatch({ type: 'SAVE_MODULES', payload });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SavedModules);
