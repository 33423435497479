import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import MobileChildTopicLabel from './MobileChildTopicLabel';

const MobileChildTopicCarouselItem = ({
  parentTopicUid,
  topic,
  isCompleted,
  index,
  isSelected,
  labelSide,
  tabIndex,
}) => {
  const history = useHistory();

  const onLabelClick = (e) => {
    const loc = `/content/${parentTopicUid}/${topic.uid}`;
    history.push(loc);
  };

  return (
    <div className={['mobile-child-topic-carousel-item', isSelected ? 'selected' : ''].join(' ')}>
      <figure className={['img', `img-${index}`].join(' ')} />
      <MobileChildTopicLabel
        label={topic.name}
        completed={isCompleted}
        onLabelClick={onLabelClick}
        side={labelSide}
        tabIndex={tabIndex}
      />
    </div>
  );
};

MobileChildTopicCarouselItem.propTypes = {
  parentTopicUid: PropTypes.string.isRequired,
  topic: PropTypes.objectOf(PropTypes.any).isRequired,
  isCompleted: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
  isSelected: PropTypes.bool.isRequired,
  labelSide: PropTypes.number.isRequired,
  tabIndex: PropTypes.number.isRequired,
};

export default MobileChildTopicCarouselItem;
