import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  USER_LOADED,
  AUTH_ERROR,
  TEMP_LOGIN_SUCESS,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  ACCOUNT_DELETED,
  PASSWORD_EMAIL,
  RESET_FAIL,
  SESSION_TRACKER,
  SAVE_MODULE,
  SET_USER_VOLUME,
  SET_TIPS,
  SET_LOW_BANDWIDTH,
  SAVE_ANSWER,
  SET_AUDIO,
  SET_TOKEN,
} from '../actions/types';

// State for Authentication
const initialState = {
  // The token we get back when someone registers,
  // it will be stored in the localstorage of the browser
  token: localStorage.getItem('token') || sessionStorage.getItem('token'),
  isAuthenticated: false, // when the user registers or login, this will change to TRUE
  loading: true,
  user: null, // this will change after  the request to user/auth
  audio: {
    isLoaded: false,
    isPaused: false,
    volume: 1,
    isMuted: false,
  },
  showTips: JSON.parse(localStorage.getItem('showTips')) || {
    show: true,
    showTip: {
      module: true,
      browser: true,
    },
  },
  lowBandwidthMode: false,
};

// eslint-disable-next-line func-names
export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    // Load the user
    case USER_LOADED:
      return {
        ...state,
        isAuthenticated: true,
        loading: false,
        user: payload,
      };

    // case SET_TOKEN:
    //   return {
    //     ...state,
    //   };

    // if register is good, we get the token back and the user should loggin immediately
    case REGISTER_SUCCESS:
      return {
        ...state,
        token: payload.jwt,
        isAuthenticated: true,
        loading: false,
        user: { data: payload.user },
      };

    case LOGIN_SUCCESS:
      // put the token that is returned inside localStorage
      sessionStorage.setItem('token', payload.jwt);
      sessionStorage.setItem('email', payload.user.email);
      sessionStorage.setItem('rememberMe', true);
      return {
        ...state,
        token: payload.jwt,
        isAuthenticated: true,
        loading: false,
        user: { data: payload.user },
      };

    case TEMP_LOGIN_SUCESS:
      // token will only be stored until user closes tab/window
      localStorage.setItem('token', payload.jwt);
      sessionStorage.setItem('rememberMe', false);
      return {
        ...state,
        token: payload.jwt,
        isAuthenticated: true,
        loading: false,
        user: { data: payload.user },
      };

    case PASSWORD_EMAIL:
      return {
        ...state,
        isAuthenticated: false,
        loading: false,
      };

    case AUTH_ERROR:
    case REGISTER_FAIL:
    case LOGIN_FAIL:
    case LOGOUT:
    case ACCOUNT_DELETED:
    case RESET_FAIL:
      // remove the token from localStorage
      localStorage.removeItem('token');
      sessionStorage.removeItem('token');
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        loading: false,
        user: null,
      };
    case SAVE_MODULE:
    case SAVE_ANSWER:
      return {
        ...state,
        user: payload,
      };
    case SET_USER_VOLUME:
      return {
        ...state,
        user: { ...state.user, userVolume: payload },
      };
    case SET_AUDIO:
      return {
        ...state,
        audio: payload,
      };
    case SET_TIPS:
      return {
        ...state,
        showTips: payload,
      };
    case SET_LOW_BANDWIDTH:
      return {
        ...state,
        user: { ...state.user, lowBandwidthMode: payload },
      };
    default:
      return state;
  }
}
