import React from 'react';
import PropTypes from 'prop-types';
import pause from './assets/pause.svg';
import play from './assets/play-circle.svg';
import restart from './assets/restart.svg';
import CitationToolTip from '../../shared/CitationTooltip';

const LearningModuleControls = ({
  module,
  isPlaying,
  handlePlay,
  textValues,
  handleNext,
  handlePrev,
  disableNext,
  disablePrev,
  forwards,
  backwards,
  cards,
  handleRestart,
}) => {
  const type = module.module_type[0]?.Type;

  if (
    type === 'quote' ||
    type === 'text' ||
    type === 'photo_gallery' ||
    module.citation ||
    type === 'audio'
  ) {
    return (
      <div className="button-container">
        {/* If module has audio, show play/pause btn */}
        {module.module_type[0].Type === 'quote' && (
          <>
            {module.module_type[0].quoteAudio?.url && (
              <button
                className="button-controls"
                type="button"
                onClick={handlePlay}
                aria-label="pause/play"
              >
                {isPlaying ? (
                  <span className="pause-btn">
                    <img src={pause} alt="pause button" />
                  </span>
                ) : (
                  <span className="play-btn">
                    <img src={play} alt="pause button" />
                  </span>
                )}
              </button>
            )}
            <button
              className="button-controls prev"
              type="button"
              onClick={handlePrev}
              disabled={disablePrev}
            >
              <span className="visibility-hidden">Previous Quote</span>
              <div className="arrow" />
            </button>
            <button
              className="button-controls"
              type="button"
              onClick={handleNext}
              disabled={disableNext}
            >
              <span className="visibility-hidden">Next Quote</span>
              <div className="arrow" />
            </button>
          </>
        )}
        {textValues.length > 1 && (
          <>
            <button
              className="button-controls prev"
              type="button"
              onClick={backwards}
              disabled={disablePrev}
            >
              <span className="visibility-hidden">Previous Quote</span>
              <div className="arrow" />
            </button>
            <button
              className="button-controls"
              type="button"
              onClick={forwards}
              disabled={disableNext}
            >
              <span className="visibility-hidden">Next Quote</span>
              <div className="arrow" />
            </button>
          </>
        )}
        {cards.length > 1 && (
          <>
            <button
              className="button-controls prev"
              type="button"
              onClick={backwards}
              disabled={disablePrev}
            >
              <span className="visibility-hidden">Previous Quote</span>
              <div className="arrow" />
            </button>
            <button
              className="button-controls"
              type="button"
              onClick={forwards}
              disabled={disableNext}
            >
              <span className="visibility-hidden">Next Quote</span>
              <div className="arrow" />
            </button>
          </>
        )}
        {module.module_type[0].Type === 'audio' && (
          <>
            <button
              className="button-controls"
              type="button"
              onClick={handlePlay}
              aria-label="pause/play"
            >
              {isPlaying ? (
                <span className="pause-btn">
                  <img src={pause} alt="pause button" />
                </span>
              ) : (
                <span className="play-btn">
                  <img src={play} alt="pause button" />
                </span>
              )}
            </button>
            <button
              className="button-controls"
              type="button"
              onClick={handleRestart}
              aria-label="replay"
            >
              <span className="play-btn">
                <img src={restart} alt="replay button" />
              </span>
            </button>
          </>
        )}
        {module.citation && <CitationToolTip citation={module.citation} />}
      </div>
    );
  }

  return null;
};

LearningModuleControls.defaultProps = {
  isPlaying: null,
  handlePlay: () => {},
  handleNext: () => {},
  handlePrev: () => {},
  disableNext: null,
  disablePrev: null,
  textValues: [],
  backwards: () => {},
  forwards: () => {},
  cards: [],
  handleRestart: () => {},
};

LearningModuleControls.propTypes = {
  module: PropTypes.objectOf(PropTypes.any).isRequired,
  isPlaying: PropTypes.bool,
  handlePlay: PropTypes.func,
  textValues: PropTypes.arrayOf(PropTypes.any),
  forwards: PropTypes.func,
  backwards: PropTypes.func,
  handleNext: PropTypes.func,
  handlePrev: PropTypes.func,
  disableNext: PropTypes.bool,
  disablePrev: PropTypes.bool,
  cards: PropTypes.arrayOf(PropTypes.any),
  handleRestart: PropTypes.func,
};

export default LearningModuleControls;
