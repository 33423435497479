export const parentSort = (parentTopics, childTopics, savedModules) => {
  const moduleObject = {};
  const childArray = [];

  parentTopics.map((parent) => {
    moduleObject[parent.UID] = [];
    return moduleObject;
  });

  childTopics.map((child) => {
    const modulesCompleted = [];
    const modulesInChild = [];
    child.learning_modules.map((module) => {
      return modulesInChild.push({
        moduleId: module.uid,
        moduleName: module.name,
        moduleType: module.module_type[0].Type,
        childName: child.name,
        childId: child.uid,
        parentId: child.parent_topic.UID,
      });
    });

    modulesInChild.map((module) => {
      if (savedModules.includes(module.moduleId)) {
        modulesCompleted.push(module);
      }
      return modulesCompleted;
    });

    childArray.push({ [child.uid]: [modulesCompleted], parent: child.parent_topic.UID });
    return childArray;
  });

  childArray.map((child) => {
    return moduleObject[child.parent].push(child);
  });

  return moduleObject;
};

export const childSort = (modules) => {
  const modulesList = [];

  if (modules) {
    modules.map((child) => {
      return child[Object.keys(child)[0]].map((moduleGroup) => {
        return moduleGroup.map((module) => {
          return modulesList.push({
            moduleId: module.moduleId,
            moduleName: module.moduleName,
            moduleType: module.moduleType,
            childName: module.childName,
            childId: module.childId,
            parentId: module.parentId,
          });
        });
      });
    });
  }

  return modulesList;
};
