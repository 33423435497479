import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

import SavedModulesItem from './SavedModulesItem';

import { childSort } from '../../../../utils/moduleSort';

const SavedModulesList = ({ modules, editMode, auth, update, setUpdate }) => {
  const [sortedModules, setSortedModules] = useState([]);

  useEffect(() => {
    // sort modules into useable info
    const sort = childSort(modules);
    setSortedModules(sort);
  }, [modules]);

  return (
    <>
      {sortedModules.length === 0 ? (
        <p className="no-modules">No saved modules</p>
      ) : (
        <TransitionGroup>
          {sortedModules.map((module) => {
            return (
              <CSSTransition key={module.id} timeout={500} classNames="item">
                <SavedModulesItem
                  module={module}
                  editMode={editMode}
                  auth={auth}
                  key={module.moduleId}
                  update={update}
                  setUpdate={setUpdate}
                />
              </CSSTransition>
            );
          })}
        </TransitionGroup>
      )}
    </>
  );
};

SavedModulesList.propTypes = {
  modules: PropTypes.arrayOf(PropTypes.any).isRequired,
  editMode: PropTypes.bool.isRequired,
  auth: PropTypes.objectOf(PropTypes.any).isRequired,
  update: PropTypes.bool.isRequired,
  setUpdate: PropTypes.func.isRequired,
};

export default SavedModulesList;
