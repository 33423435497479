import React, { useState } from 'react';
import TextModule from './components/content/learningModules/learning-modules/TextModule';
import VideoModule from './components/content/learningModules/learning-modules/VideoModule';
import ImageGallery from './components/content/learningModules/learning-modules/ImageGallery';
// import Quote from './components/content/learningModules/learning-modules/Quote';

/**
 * Page created just to render components and see how they look
 * REMEMBER TO DELETE BEFORE PROD
 * Import your components and test them out here :D
 */
const textArray = [`One`, `Two`, `Three`, `Four`, `Five`];

const imagesArr = [
  {
    img: '../assets/1.jpg',
    desc: 'Definitely a bit cliche but this is an analogy of a sunset for end of life. And, the sort of lack clips on the side that represents the disease and challenges that come along with it but sort of thinking of a perspective of a sunset as a caregiver, us the nursing staff and the whole team seeing the signs of the patient going into the next phase as opposed to reaching the end and again another cliche analogy but being on a journey and sort of walking through the valley.',
    id: 5,
    altText: 'altText',
  },
  {
    img: '../assets/2.jpg',
    desc: 'Calmly a bit cliche but this is an analogy of a sunset for end of life. And, the sort of lack clips on the side that represents the disease and challenges that come along with it but sort of thinking of a perspective of a sunset as a caregiver, us the nursing staff and the whole team seeing the signs of the patient going into the next phase as opposed to reaching the end and again another cliche analogy but being on a journey and sort of walking through the valley.',
    id: 4,
    altText: 'altText',
  },
  {
    img: '../assets/3.jpg',
    desc: 'Maybe a bit cliche but this is an analogy of a sunset for end of life. And, the sort of lack clips on the side that represents the disease and challenges that come along with it but sort of thinking of a perspective of a sunset as a caregiver, us the nursing staff and the whole team seeing the signs of the patient going into the next phase as opposed to reaching the end and again another cliche analogy but being on a journey and sort of walking through the valley.',
    id: 3,
    altText: 'altText',
  },
  {
    img: '../assets/4.jpg',
    desc: 'Structurally a bit cliche but this is an analogy of a sunset for end of life. And, the sort of lack clips on the side that represents the disease and challenges that come along with it but sort of thinking of a perspective of a sunset as a caregiver, us the nursing staff and the whole team seeing the signs of the patient going into the next phase as opposed to reaching the end and again another cliche analogy but being on a journey and sort of walking through the valley.',
    id: 2,
    altText: 'altText',
  },
  {
    img: '../assets/5.jpg',
    desc: 'Well it is a bit cliche but this is an analogy of a sunset for end of life. And, the sort of lack clips on the side that represents the disease and challenges that come along with it but sort of thinking of a perspective of a sunset as a caregiver, us the nursing staff and the whole team seeing the signs of the patient going into the next phase as opposed to reaching the end and again another cliche analogy but being on a journey and sort of walking through the valley.',
    id: 1,
    altText: 'altText',
  },
];

const Sandbox = () => {
  const quote = [
    {
      d: 'M0,50l20,-50l20,50m-30,-25l20,0M60,10v40m-10,-31h20M80,0M130,10a10,10 0 0 0 -20,0v40m-5,-25h15M135,10a1,1 0 1 1 0,0.5zm1,9.5v30M146,20v30m0.5,-25a13,15 0 0 1 20,0M201,27.5a12.5,7.5 0 1 0 -12.5,7.5a12.5,7.5 0 1 1 -12.5,7.5M221,10v40m-10,-31h20M241,0M266,10a1,1 0 1 1 0,0.5zm1,9.5v30M287,10v40m-10,-31h20M307,0M332,20l10,30l10,-20l10,20l10,-30M405,20v30m0,-5a13,15 0 1 1 0,-20M440,27.5a12.5,7.5 0 1 0 -12.5,7.5a12.5,7.5 0 1 1 -12.5,7.5M450,0M480,10a1,1 0 1 1 0,0.5zm1,9.5v40a5,5 0 0 1 -10,0M491,20v15a12.5,15 0 0 0 25,0v-15M551,27.5a12.5,7.5 0 1 0 -12.5,7.5a12.5,7.5 0 1 1 -12.5,7.5M571,10v40m-10,-31h20M591,0M626,10v40m-10,-31h20M646,0v50m0.5,-25a13,15 0 0 1 20,0v25M699,43a12.5,15 0 1 1 1,-12h-23M710,0M735,0v50M746,10a1,1 0 1 1 0,0.5zm1,9.5v30M767,10v40m-10,-31h20M797,10v40m-10,-31h20M817,0v50M851,43a12.5,15 0 1 1 1,-12h-23M862,0M897,10v40m-10,-31h20M917,0v50m0.5,-25a13,15 0 0 1 20,0v25M947,10a1,1 0 1 1 0,0.5zm1,9.5v30M958,20v30m0.5,-25a13,15 0 0 1 20,0v25M1011,20v40a13,15 0 0 1 -20,0m20,-15a13,15 0 1 1 0,-20M1046,27.5a12.5,7.5 0 1 0 -12.5,7.5a12.5,7.5 0 1 1 -12.5,7.5M1061,50a1,1 0 1 1 0,0.5M1081,50a1,1 0 1 1 0,0.5M1101,50a1,1 0 1 1 0,0.5',
      transform: 'translate(0,0)',
    },
    {
      d: 'M0,0v50M11,10a1,1 0 1 1 0,0.5zm1,9.5v30M22,0v50m22,-30l-21,15l21,15M75,43a12.5,15 0 1 1 1,-12h-23M86,0M136,10a10,10 0 0 0 -20,0v40m-5,-25h15M155,20a15,15 0 1 0 0.5,0zM180,20v30m0.5,-25a13,15 0 0 1 20,0M233,20v40a13,15 0 0 1 -20,0m20,-15a13,15 0 1 1 0,-20M266,43a12.5,15 0 1 1 1,-12h-23M287,10v40m-10,-31h20M317,10v40m-10,-31h20M337,10a1,1 0 1 1 0,0.5zm1,9.5v30M348,20v30m0.5,-25a13,15 0 0 1 20,0v25M401,20v40a13,15 0 0 1 -20,0m20,-15a13,15 0 1 1 0,-20M411,0M461,27.5a12.5,7.5 0 1 0 -12.5,7.5a12.5,7.5 0 1 1 -12.5,7.5M485,20a15,15 0 1 0 0.5,0zM510,20v30m0.5,-25a13,15 0 0 1 20,0v25m0.5,-25a13,15 0 0 1 20,0v25M584,43a12.5,15 0 1 1 1,-12h-23M609,20a15,15 0 1 0 0.5,0zM634,20v30m0.5,-25a13,15 0 0 1 20,0v25M687,43a12.5,15 0 1 1 1,-12h-23M703,5v10M743,27.5a12.5,7.5 0 1 0 -12.5,7.5a12.5,7.5 0 1 1 -12.5,7.5M753,0M778,20v30m0.5,-25a13,15 0 0 1 20,0v25M831,20v30m0,-5a13,15 0 1 1 0,-20M841,20v30m0.5,-25a13,15 0 0 1 20,0v25m0.5,-25a13,15 0 0 1 20,0v25M915,43a12.5,15 0 1 1 1,-12h-23M928,30h20',
      transform: 'translate(0,68)',
    },
    {
      d: 'M10,10v40m-10,-31h20M30,0v50m0.5,-25a13,15 0 0 1 20,0v25M83,43a12.5,15 0 1 1 1,-12h-23M94,20v30m0.5,-25a13,15 0 0 1 20,0v25M124,0M149,10a1,1 0 1 1 0,0.5zm1,9.5v30M170,10v40m-10,-31h20M190,0M215,20l10,30l10,-20l10,20l10,-30M288,20v30m0,-5a13,15 0 1 1 0,-20M323,27.5a12.5,7.5 0 1 0 -12.5,7.5a12.5,7.5 0 1 1 -12.5,7.5M333,0M358,20v30m0.5,-25a13,15 0 0 1 20,0v25m0.5,-25a13,15 0 0 1 20,0v25M409,20l10,30m15,-30l-23,50M444,0M483,20a15,15 0 1 0 0.5,0zM508,20l10,30l10,-20l10,20l10,-30M558,20v30m0.5,-25a13,15 0 0 1 20,0v25M588,0M613,20v30m0.5,-25a13,15 0 0 1 20,0v25M666,20v30m0,-5a13,15 0 1 1 0,-20M676,20v30m0.5,-25a13,15 0 0 1 20,0v25m0.5,-25a13,15 0 0 1 20,0v25M750,43a12.5,15 0 1 1 1,-12h-23',
      transform: 'translate(0,136)',
    },
  ];

  return (
    <>
      <h1>Welcome to the sandbox</h1>
      <ImageGallery imagesArr={imagesArr} />
    </>
  );
};

export default Sandbox;
