import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

// Components
import TopicBrowserTopNav from './parent-topic-browser/TopicBrowserTopNav';
import ScrollInstructions from './parent-topic-browser/ScrollInstructions';
import ShardLabel from './parent-topic-browser/ShardLabel';

import { loadAllParents } from '../../../actions/activeModule';

// Custom Threejs components
import ShardMenu from './parent-topic-browser/ShardMenu';

// Utils
import { sortParentTopics, clamp } from '../../utils/TopicBrowserUtils';

// We can use this as the base for the component where users select the parent topic.
const ParentTopicBrowser = ({ dispatchLoadParents, token, parentTopics, progress, isLoading }) => {
  ParentTopicBrowser.defaultProps = {
    token: '',
  };

  const history = useHistory();

  // State Variables
  const [perLoaded, setPerLoaded] = useState(0);
  const [isInstructionsVisible, setIsInstructionsVisible] = useState(false);
  const [scrollEnabled, setScrollEnabled] = useState('');
  const [labelState, setLabelState] = useState({
    relationships: ShardLabel.STATE_LABEL_HIDDEN,
    stigma: ShardLabel.STATE_LABEL_HIDDEN,
    identity: ShardLabel.STATE_LABEL_HIDDEN,
    culture: ShardLabel.STATE_LABEL_HIDDEN,
    possibilities: ShardLabel.STATE_LABEL_HIDDEN,
  });
  const [curIndex, setCurIndex] = useState(0);
  const [isUIVisible, setIsUIVisible] = useState(false);
  const [scrollAmount, setScrollAmount] = useState(0);
  const [sortedProgress, setSortedProgress] = useState(progress);

  // HTML ref
  const mThreeRef = useRef(null);
  const mLabels = useRef([]);
  const mMenu = useRef(null);

  // function to set scroll amount when navigating to a shard without scrolling. e.g via top menu, via keyboard
  const setScroll = (index) => {
    let per = 0;
    switch (index) {
      case ShardMenu.STATE_RELATIONSHIPS:
        per = 0;
        break;
      case ShardMenu.STATE_STIGMA:
        per = 0.03;
        break;
      case ShardMenu.STATE_IDENTITY:
        per = 0.33;
        break;
      case ShardMenu.STATE_CULTURE:
        per = 0.66;
        break;
      case ShardMenu.STATE_POSSIBILITIES:
        per = 1;
        break;
      default:
        break;
    }

    if (index >= ShardMenu.STATE_RELATIONSHIPS && index <= ShardMenu.STATE_POSSIBILITIES) {
      const scrollVal = per * 100;
      setScrollAmount(scrollVal);
    }
  };

  // callback handler for events occuring in the ShardMenu.js
  const delegateHandler = {
    handlePerLoaded: (per) => {
      setPerLoaded(per);
    },
    handleInstructionsShow: () => {
      setIsInstructionsVisible(true);
      setScrollEnabled('scrollenabled');
    },
    updateShardLabel: (obj) => {
      const newVal = {
        relationships: obj.relationships,
        stigma: obj.stigma,
        identity: obj.identity,
        culture: obj.culture,
        possibilities: obj.possibilities,
      };
      setLabelState(newVal);
    },
    handleUIVisible: () => {
      setIsUIVisible(true);
    },
    handleCurIndexChange: (index) => {
      setCurIndex(index);
    },
    handleShardSelect: (uid) => {
      const loc = `/content/${uid}`;

      // Dispose of everything
      mMenu.current.destroy();

      history.push(loc);
    },

    // special case handler for when the parent topic browser needs to animate in from a child topic
    handleResume: (index) => {
      setIsInstructionsVisible(false);
      setIsUIVisible(true);
      setScrollEnabled('scrollenabled');
      setCurIndex(index);
      setScroll(index);
    },
  };

  const onLabelClick = (e) => {
    if (isUIVisible) {
      if (curIndex >= ShardMenu.STATE_RELATIONSHIPS && curIndex <= ShardMenu.STATE_POSSIBILITIES) {
        let dis = 0;
        let uid = '';
        switch (curIndex) {
          case ShardMenu.STATE_RELATIONSHIPS:
            dis = 60;
            uid = parentTopics[0].UID;
            break;
          case ShardMenu.STATE_STIGMA:
            dis = 60;
            uid = parentTopics[1].UID;
            break;
          case ShardMenu.STATE_IDENTITY:
            dis = 60;
            uid = parentTopics[2].UID;
            break;
          case ShardMenu.STATE_CULTURE:
            dis = 45;
            uid = parentTopics[3].UID;
            break;
          case ShardMenu.STATE_POSSIBILITIES:
            dis = 55;
            uid = parentTopics[4].UID;
            break;
          default:
            dis = 0;
            uid = '';
        }

        mMenu.current.selectShard(dis, uid);

        const newVal = {
          relationships: ShardLabel.STATE_LABEL_HIDDEN,
          stigma: ShardLabel.STATE_LABEL_HIDDEN,
          identity: ShardLabel.STATE_LABEL_HIDDEN,
          culture: ShardLabel.STATE_LABEL_HIDDEN,
          possibilities: ShardLabel.STATE_LABEL_HIDDEN,
        };
        setLabelState(newVal);
        setIsUIVisible(false);
      }
    }
  };

  useEffect(async () => {
    dispatchLoadParents(token);
    // 3D world
    const menu = new ShardMenu(window);
    menu.build(mThreeRef.current);
    menu.setupLabels(mLabels);
    menu.delegate = delegateHandler;

    mMenu.current = menu;
  }, []);

  const handleOnWheel = (e) => {
    if (scrollEnabled === 'scrollenabled') {
      let increment = 10;
      if (isInstructionsVisible) {
        increment = 0;
      }

      if (!mMenu.current.camera.isAnimating) {
        let s = scrollAmount;

        if (e.deltaY > 0) {
          s += increment;
        } else {
          s -= increment;
        }

        s = clamp(s, 0, 100);

        const per = s / 100;
        let cur = -1;

        if (per >= 0.98) {
          cur = ShardMenu.STATE_POSSIBILITIES;
        } else if (per >= 0.66) {
          cur = ShardMenu.STATE_CULTURE;
        } else if (per >= 0.33) {
          cur = ShardMenu.STATE_IDENTITY;
        } else if (per >= 0.05) {
          cur = ShardMenu.STATE_STIGMA;
        } else {
          cur = ShardMenu.STATE_RELATIONSHIPS;

          // If the instructions are visible need to hide the instructions
          // implement a small timer to disable scrolling while instructions fades out so that the first shard isn't skipped due to inertia scrolling of macs
          if (isInstructionsVisible) {
            setScrollEnabled('');
            setIsInstructionsVisible(false);
            setTimeout(() => {
              setScrollEnabled('scrollenabled');
            }, 2000);
          }
        }

        mMenu.current.configShardState(cur);

        setScrollAmount(s);
      }
    } else {
      e.preventDefault();
    }
  };

  const handleOnTopNavClick = (index) => {
    mMenu.current.configShardState(index);
    setScroll(index);
  };

  useEffect(() => {
    mThreeRef.current.addEventListener('wheel', handleOnWheel);

    return () => {
      if (mThreeRef.current !== null) {
        mThreeRef.current.removeEventListener('wheel', handleOnWheel);
      }
    };
  });

  const handleKeyUp = (e) => {
    switch (e.keyCode) {
      case 38:
        if (isInstructionsVisible) {
          setIsInstructionsVisible(false);
          mMenu.current.nextShard();
        }

        if (isUIVisible) {
          mMenu.current.nextShard();
          setScroll(curIndex + 1);
        }
        break;
      case 40:
        if (isUIVisible) {
          mMenu.current.prevShard();
          setScroll(curIndex - 1);
        }
        break;
      case 9:
        if (e.target.className.indexOf('shard-label') >= 0) {
          const index = e.target.tabIndex + 2; // Add 2 because the first parent topic ShardMenu.STATE_RELATIONSHIPS = 3
          mMenu.current.configShardState(index);
          setIsInstructionsVisible(false);
        }
        break;
      case 13:
        if (isUIVisible) {
          onLabelClick();
        }
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    window.addEventListener('keyup', handleKeyUp);

    return () => {
      window.removeEventListener('keyup', handleKeyUp);
    };
  });

  useEffect(() => {
    const sorted = sortParentTopics(progress);
    setSortedProgress(sorted);
  }, [progress]);

  return (
    <div className="universe" ref={mThreeRef}>
      <TopicBrowserTopNav
        parentTopics={sortParentTopics(parentTopics)}
        currentActiveTopic={curIndex - 3}
        isVisible={isUIVisible}
        handleTopNavClick={handleOnTopNavClick}
      />
      <div
        className={[
          'loadingScreen',
          'parent-topic-loaded',
          perLoaded >= 100 ? 'completed' : '',
        ].join(' ')}
      >
        <i className="headphones" />
        <p>Use headphones for the best experience</p>
        <span className="loadingPercent">{`${perLoaded}%`}</span>
      </div>
      <ScrollInstructions isVisible={isInstructionsVisible} />
      <div className="labels">
        {sortParentTopics(parentTopics).map((parentTopic, i) => {
          let s = null;
          const max = parentTopic.child_topics.length;

          switch (i) {
            case 0:
              s = labelState.relationships;
              break;
            case 1:
              s = labelState.stigma;
              break;
            case 2:
              s = labelState.identity;
              break;
            case 3:
              s = labelState.culture;
              break;
            case 4:
              s = labelState.possibilities;
              break;
            default:
              s = ShardLabel.STATE_LABEL_HIDDEN;
              break;
          }

          let comp = 0;

          if (sortedProgress[i] != null) {
            for (let j = 0; j < sortedProgress[i].child_topics.length; j += 1) {
              if (sortedProgress[i].child_topics[j].completed) {
                comp += 1;
              }
            }
          }

          return (
            <ShardLabel
              key={parentTopic.UID}
              ref={(el) => {
                mLabels.current[i] = el;
              }}
              onLabelClick={onLabelClick}
              label={parentTopic.name}
              labelState={s}
              completed={comp}
              max={max}
              tabIndex={i + 1}
            />
          );
        })}
      </div>
    </div>
    //     )}
    //   </div>
    // </>
  );
};

ParentTopicBrowser.propTypes = {
  dispatchLoadParents: PropTypes.func.isRequired,
  token: PropTypes.string,
  parentTopics: PropTypes.arrayOf(PropTypes.any).isRequired,
  progress: PropTypes.arrayOf(PropTypes.any).isRequired,
  isLoading: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  token: state.auth.token,
  parentTopics: state.activeModule.parentTopics,
  progress: state.progressTracker.completedModules,
  isLoading: state.activeModule.loadingTopics,
  completedModules: state,
});

export default connect(mapStateToProps, { dispatchLoadParents: loadAllParents }, null, {
  forwardRef: true,
})(ParentTopicBrowser);
