import React from 'react';
import PropTypes from 'prop-types';

const BandwidthWarning = ({ setShowBandwidthWarning }) => {
  const closeModal = () => {
    setShowBandwidthWarning(false);
  };

  const handleClick = () => {
    localStorage.setItem('lowBandwidth', true);
    closeModal();
  };

  return (
    <div className="bandwidth-warning">
      <div className="speed-image" />
      <div>
        <p>Experiencing a slow connection?</p>
        <button type="button" className="activate" onClick={handleClick}>
          Activate low-bandwidth mode
        </button>
      </div>
      <div className="close-container">
        <button type="button" className="close" onClick={closeModal} aria-label="close" />
      </div>
    </div>
  );
};

BandwidthWarning.propTypes = {
  setShowBandwidthWarning: PropTypes.func.isRequired,
};

export default BandwidthWarning;
