import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const MenuParentPreview = ({ parentTopic, closeMenu }) => {
  return (
    <div id="parent-info" className="parent-info">
      <h3>{parentTopic.name}</h3>
      <p id="parent-description">{parentTopic.description}</p>
      <Link
        to={`/content/${parentTopic.UID}`}
        key={parentTopic.id}
        className="child-link"
        onClick={closeMenu}
        id="explore-btn"
      >
        <div className="explore-btn">
          Explore
          <span className="arrow">→</span>
        </div>
      </Link>
    </div>
  );
};

MenuParentPreview.propTypes = {
  parentTopic: PropTypes.objectOf(PropTypes.any).isRequired,
  closeMenu: PropTypes.func.isRequired,
};

export default MenuParentPreview;
