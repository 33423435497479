const userDemographics = [
  {
    value: 'Student',
    label: 'Student',
  },
  {
    value: 'Instructor',
    label: 'Instructor',
  },
  {
    value: 'Person Living with Dementia',
    label: 'Person Living with Dementia',
  },
  {
    value: 'Family Member of a Person Living with Dementia',
    label: 'Family Member of a Person Living with Dementia',
  },
  {
    value: 'Health Care Professional',
    label: 'Health Care Professional',
  },
  {
    value: 'Policy Maker',
    label: 'Policy Maker',
  },
  {
    value: 'General Public',
    label: 'General Public',
  },
  {
    value: 'Other',
    label: 'Other',
  },
];

export default userDemographics;
