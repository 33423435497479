import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import marked from 'marked';
import LearningModuleControls from '../LearningModuleControls';

// Import Animations
import SlowSong from '../../../../assets/animations/slow-song-basket.svg';
import KnowingHands from '../../../../assets/animations/knowing-hands.svg';
import StringOfPearls from '../../../../assets/animations/string-of-pearls.svg';
import Alive from '../../../../assets/animations/alive.svg';
import Toothpaste from '../../../../assets/animations/toothpaste.svg';

// NOTE: audio.volume will link the volume of animation audio to the global volumes
const AnimationModule = ({ module, inViewport, checkModuleCompletion, targetModule }) => {
  const animRef = useRef(null);
  const playRef = useRef(null);

  const [visibility, setVisibility] = useState(true);
  const [showPlayer, setShowPlayer] = useState(false);
  const [toggleBtn, setToggleBtn] = useState(false);
  const [componentKey, setComponentKey] = useState(1000);
  const [target, setTarget] = useState();

  useEffect(() => {
    if (targetModule !== null || (targetModule !== undefined && targetModule?.id !== 'intro'))
      setTarget(targetModule);
  }, [targetModule]);

  const notInitialRender = useRef(false);

  // Function to get SVG ID and fire click event
  const svgClickEvent = () => {
    // Get ID of SVG
    const animId = animRef.current;
    const objectDocument = animId.contentDocument;

    const event = new Event('click');
    objectDocument.firstChild.dispatchEvent(event);
  };

  // Function to reset Animation
  const resetAnimation = () => {
    setComponentKey(componentKey + 1);
    setVisibility(true);
    setShowPlayer(false);
    setToggleBtn(false);
  };

  const detectAnimationEnd = (selector) => {
    if (selector) {
      // create instance od `MutationObserver` to detect when SVG element has changed
      const observer = new MutationObserver(function () {
        // When last svg element is shown, reset animation
        if (selector.getAttributeNS(null, 'opacity') === '1') {
          resetAnimation();
          observer.disconnect();
        }
      });
      // call `observe()` on that MutationObserver instance,
      observer.observe(selector, { attributeFilter: ['opacity'] });
    }
  };

  // Function that gets selector of Animation node, loops through array if more than one anim is in the same page.
  const queryElements = (selector) => {
    const svgParent = document.querySelectorAll('.svgObject');
    for (let i = 0; i < svgParent.length; i += 1) {
      detectAnimationEnd(svgParent[i].contentDocument.querySelector('.lastSvgElement'));
    }
  };

  // Function to initialy start animation
  const startAnimation = () => {
    svgClickEvent();
    // Hide background and play button
    setVisibility(false);
    setShowPlayer(true);
    detectAnimationEnd();
    queryElements();
    checkModuleCompletion(null, target);
  };

  // Function to stop and play animation
  const pausePlayAnimation = () => {
    svgClickEvent();
    // Change Play/Pause button accordingly
    setToggleBtn(!toggleBtn);
  };

  // Function to Pause Animation if user scrolls down/up
  const pauseOnScroll = () => {
    if (!inViewport && toggleBtn === false && visibility === false) {
      svgClickEvent();
      setToggleBtn(true);
      notInitialRender.current = false;
    }
  };

  // Function that detects the end of the animation

  useEffect(() => {
    let mounted = true;

    // cleanup
    return () => {
      pauseOnScroll();
      mounted = false;
    };
  }, [inViewport]);

  // render animation depending on the Learning Module
  const renderAnimation = () => {
    switch (module.uid) {
      case 'slow-song':
        return (
          <>
            <div className={`animation-intro-container ${!visibility ? 'hide_intro' : ''}`}>
              {module.module_type[0].animation_intro_title && (
                <p className="animation-intro-title">
                  {module.module_type[0].animation_intro_title}
                </p>
              )}

              {module.module_type[0].animation_intro && (
                <div
                  className="animation-intro"
                  dangerouslySetInnerHTML={{
                    __html: marked(module.module_type[0].animation_intro),
                  }}
                />
              )}
            </div>
            <object
              className="svgObject"
              data={SlowSong}
              aria-label="An animation"
              ref={animRef}
              key={componentKey}
            />
          </>
        );
      case 'knowing-hands':
        return (
          <>
            <div className={`animation-intro-container ${!visibility ? 'hide_intro' : ''}`}>
              {module.module_type[0].animation_intro_title && (
                <p className="animation-intro-title">
                  {module.module_type[0].animation_intro_title}
                </p>
              )}

              {module.module_type[0].animation_intro && (
                <div
                  className="animation-intro"
                  dangerouslySetInnerHTML={{
                    __html: marked(module.module_type[0].animation_intro),
                  }}
                />
              )}
            </div>
            <object
              className="svgObject"
              data={KnowingHands}
              aria-label="An animation"
              ref={animRef}
              key={componentKey}
            />
          </>
        );
      case 'string-of-pearls':
        return (
          <>
            <div className={`animation-intro-container ${!visibility ? 'hide_intro' : ''}`}>
              {module.module_type[0].animation_intro_title && (
                <p className="animation-intro-title">
                  {module.module_type[0].animation_intro_title}
                </p>
              )}

              {module.module_type[0].animation_intro && (
                <div
                  className="animation-intro"
                  dangerouslySetInnerHTML={{
                    __html: marked(module.module_type[0].animation_intro),
                  }}
                />
              )}
            </div>
            <object
              className="svgObject"
              data={StringOfPearls}
              aria-label="An animation"
              ref={animRef}
              key={componentKey}
            />
          </>
        );
      case 'alive':
        return (
          <>
            <div className={`animation-intro-container ${!visibility ? 'hide_intro' : ''}`}>
              {module.module_type[0].animation_intro_title && (
                <p className="animation-intro-title">
                  {module.module_type[0].animation_intro_title}
                </p>
              )}

              {module.module_type[0].animation_intro && (
                <div
                  className="animation-intro"
                  dangerouslySetInnerHTML={{
                    __html: marked(module.module_type[0].animation_intro),
                  }}
                />
              )}
            </div>
            <object
              className="svgObject"
              data={Alive}
              aria-label="An animation"
              ref={animRef}
              key={componentKey}
            />
          </>
        );
      case 'toothpaste':
        return (
          <>
            <div className={`animation-intro-container ${!visibility ? 'hide_intro' : ''}`}>
              {module.module_type[0].animation_intro_title && (
                <p className="animation-intro-title">
                  {module.module_type[0].animation_intro_title}
                </p>
              )}

              {module.module_type[0].animation_intro && (
                <div
                  className="animation-intro"
                  dangerouslySetInnerHTML={{
                    __html: marked(module.module_type[0].animation_intro),
                  }}
                />
              )}
            </div>
            <object
              className="svgObject"
              data={Toothpaste}
              aria-label="An animation"
              ref={animRef}
              key={componentKey}
            />
          </>
        );
      default:
        return 'There is not animation for this Learning Module';
    }
  };

  return (
    <div className={`container_animation ${showPlayer ? 'position_citation_btn' : ''}`}>
      <div className="svg_animation">
        {renderAnimation()}
        <div className={`play_stop ${!visibility ? 'hide_bg' : ''}`}>
          <button
            className="play_anim"
            type="button"
            onClick={startAnimation}
            aria-label="play"
            ref={playRef}
          >
            Play
          </button>
        </div>
      </div>
      <div className={`player_container ${showPlayer ? 'show_player' : ''}`}>
        <button
          className={`pause_play ${!toggleBtn ? 'pause_btn' : 'play_btn'}`}
          type="button"
          onClick={pausePlayAnimation}
          aria-label="pause/play"
        >
          pause/play
        </button>
        <button className="reset_btn" type="button" onClick={resetAnimation} aria-label="reset">
          Reset
        </button>
      </div>
      <LearningModuleControls module={module} />
    </div>
  );
};

AnimationModule.propTypes = {
  module: PropTypes.objectOf(PropTypes.any).isRequired,
  // audio: PropTypes.objectOf(PropTypes.any).isRequired,
  inViewport: PropTypes.bool.isRequired,
  checkModuleCompletion: PropTypes.func.isRequired,
  targetModule: PropTypes.any,
};

AnimationModule.defaultProps = {
  targetModule: null,
};

const mapStateToProps = (state) => ({
  audio: state.auth.audio,
});

export default connect()(AnimationModule);
