import React, { useEffect, useState, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';
import { connect } from 'react-redux';
import { useInView } from 'react-intersection-observer';
import LearningModuleControls from '../LearningModuleControls';

import Vara from '../../../utils/vara';

// Debounce function for resize event
const debounce = (func, timeoutTime) => {
  const time = timeoutTime || 100;
  let timer;
  return function (event) {
    clearTimeout(timer);
    timer = setTimeout(func, time, event);
  };
};

const Quote = ({ module, inViewport, auth, checkModuleCompletion, targetModule }) => {
  const [vara, setVara] = useState();
  const [dimensions, setDimensions] = useState(window.innerWidth);
  const [audioPlayed, setAudioPlayed] = useState(false);
  const [showPlay, setShowPlay] = useState(false);
  const [replay, setReplay] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [quoteText, setQuoteText] = useState([]);
  const [nextIndex, setNextIndex] = useState('vara_0');
  const [currentIndex, setCurrentIndex] = useState();
  const [visibleId, setVisibleId] = useState('vara_0');
  const [prevIndex, setPrevIndex] = useState('vara_0');
  const [visibleQuote, setVisibleQuote] = useState();
  const [quoteArraySize, setQuoteArraySize] = useState();
  const [viewedLines, setViewedLines] = useState([]);
  const [disablePrev, setDisablePrev] = useState(true);
  const [disableNext, setDisableNext] = useState(true);
  const [audioIndex, setAudioIndex] = useState(0);
  const [count, setCount] = useState(1);
  const [target, setTarget] = useState();
  const [backupTrigger, setBackupTrigger] = useState(false);

  const { ref, inView, entry } = useInView({
    threshold: 0.5,
  });

  const quoteContainer = useRef();
  const audioElements = useRef([]);

  // Set target for completion function
  useEffect(() => {
    setTarget(document.getElementById(module.uid));
  }, []);

  // Start timer on first render -> if Vara has not started drawing after 1 second, try draw again
  useEffect(() => {
    setTimeout(() => {
      setBackupTrigger(true);
    }, 1000);
  }, []);

  // If window is resized -> Update dimensions state
  const handleResize = () => {
    setDimensions(window.innerWidth);
  };

  // Add / Cleanup event listener for window resize
  useEffect(() => {
    window.addEventListener('resize', debounce(handleResize));

    return () => {
      window.removeEventListener('resize', debounce(handleResize));
    };
  }, []);

  // Function for parsing quote text received as prop
  const parseQuoteText = () => {
    const idealFontSize = 40;
    const idealScreenSize = 1435;
    const maxFontSize = 50;
    const minFontSize = 18;

    const quoteTextTemp = [];
    setQuoteArraySize(module.module_type[0].QuoteLines.length);
    module.module_type[0].QuoteLines.forEach((line, i) => {
      let scrubbedText;
      if (line.lineText.length > 0) {
        scrubbedText = line.lineText
          // Replace Line Breaks
          .replace(/(\r\n|\n|\r)/gm, ' ')
          // Replace fancy quotes
          .replace(/[\u2018\u2019]/g, '\'')
          .replace(/[\u201C\u201D]/g, '"');
      } else {
        scrubbedText = '';
      }

      const quoteLength = scrubbedText.length;
      const fontSize = (window.innerWidth / idealScreenSize) * idealFontSize;
      const adjustedFontSize = Math.max(minFontSize, Math.min(maxFontSize, fontSize));
      const adjuster = 200 / quoteLength;

      let fontSizeAdjustment;

      if (quoteLength > 200) {
        fontSizeAdjustment = adjustedFontSize * adjuster;
      } else {
        fontSizeAdjustment = adjustedFontSize;
      }

      const lineObject = {
        text: scrubbedText,
        y: 0,
        fromCurrentPosition: { y: false },
        delay: 500,
        duration: 50 * quoteLength,
        id: `vara_${i}`,
        autoAnimation: false,
        fontSize: fontSizeAdjustment,
      };
      quoteTextTemp.push(lineObject);
    });
    setQuoteText(quoteTextTemp);
  };

  // On first render, if Vara does not exist -> parse quote text
  // If dimensions are updated -> destroy existing vara, reset state, reparse quote text with new dimensions
  useEffect(() => {
    if (!vara) {
      parseQuoteText();
    } else {
      vara.get(visibleId).container?.parentElement.remove();
      setQuoteText([]);
      setNextIndex('vara_0');
      setCurrentIndex(null);
      setVisibleId('vara_0');
      setPrevIndex('vara_0');
      setVisibleQuote(null);
      setViewedLines([]);
      setCount(1);
      setDisablePrev(true);
      setDisableNext(true);
      setVara(null);
      parseQuoteText();
    }
  }, [dimensions]);

  // Function for creating Vara instance
  const createVara = (text) => {
    const newVara = new Vara(`#${module.uid}-quote-container`, 'font.json', quoteText, {
      strokeWidth: 1,
      lineHeight: 35,
    });
    setVara(newVara);
  };

  // If vara does not exist, quote text has been parsed, and target has been set -> Create Vara
  useEffect(() => {
    if (!vara && quoteText.length > 0 && target) {
      createVara(quoteText);
    }
  }, [quoteText, target]);

  // If Vara has been created and target has been set -> Attach vara.ready() function
  useEffect(() => {
    if (vara && target) {
      vara.ready(function () {
        let item;
        vara.animationEnd(function (i, o) {
          let nextId;

          const nextIdNumber = `vara_${parseInt(i.split('_')[1], 10) + 1}`;

          if (nextIdNumber > quoteArraySize - 1) {
            nextId = 'vara_0';
          } else {
            nextId = `vara_${parseInt(i.split('_')[1], 10) + 1}`;
          }

          setNextIndex(nextId);

          if (!viewedLines.includes(o)) {
            setCurrentIndex(o);
          }

          if (parseInt(i.split('_')[1], 10) - 1 > 0) {
            setPrevIndex(`vara_${parseInt(i.split('_')[1], 10) - 1}`);
          } else {
            setPrevIndex(`vara_0`);
          }

          setVisibleQuote(o);

          item = o;

          const tempViewed = viewedLines;
          if (!tempViewed.includes(o)) {
            tempViewed.push(o);
          }

          setViewedLines(tempViewed);

          if (o.index > 0) {
            setDisablePrev(false);
          }

          if (o.index < quoteArraySize - 1) {
            setDisableNext(false);
          }
        });
        if (inView) {
          vara.draw(visibleId);
        }
      });
    }
  }, [vara, target]);

  // If Vara has been created, and module is inView -> begin drawing vara
  useEffect(() => {
    if (vara && inView) {
      vara.draw(visibleId);
    }
  }, [vara, inView, backupTrigger]);

  // const playAudio = () => {
  //   if (audioElements.current.length > 0) {
  //     audioElements.current.forEach((element) => {
  //       const el = element;
  //       el.volume = auth.audio.volume;
  //       el.addEventListener('ended', () => setIsPlaying(false));
  //     });
  //   }
  //   if (!isPlaying && audioElements.current.length > 0) {
  //     const promise = audioElements.current[0].play();
  //     promise
  //       .then(() => {
  //         setIsPlaying(true);
  //         setAudioPlayed(true);
  //         setReplay(true);
  //       })
  //       .catch((error) => {
  //         setShowPlay(true);
  //       });
  //   }
  // };

  const handlePlay = () => {
    if (audioElements.current.length > 0) {
      if (!visibleQuote) {
        if (!isPlaying) {
          if (
            audioElements.current.filter((element) => element.id === `${module.uid}-0-audio`)[0]
          ) {
            const promise = audioElements.current
              .filter((element) => element.id === `${module.uid}-0-audio`)[0]
              .play();
            promise
              .then(() => {
                if (auth.audio.isMuted) {
                  audioElements.current.filter(
                    (element) => element.id === `${module.uid}-0-audio`
                  )[0].muted = true;
                }
                setIsPlaying(true);
                setAudioPlayed(true);
                setReplay(true);
              })
              .catch((error) => {
                setShowPlay(true);
              });
          }
        } else {
          audioElements.current
            .filter((element) => element.id === `${module.uid}-0-audio`)[0]
            .pause();
        }
      } else {
        if (!isPlaying) {
          if (
            audioElements.current.filter(
              (element) => element.id === `${module.uid}-${visibleQuote.index}-audio`
            )[0]
          ) {
            const promise = audioElements.current
              .filter((element) => element.id === `${module.uid}-${visibleQuote.index}-audio`)[0]
              .play();
            promise
              .then(() => {
                setIsPlaying(true);
                setAudioPlayed(true);
                setReplay(true);
              })
              .catch((error) => {
                setShowPlay(true);
              });
          }
        } else {
          audioElements.current
            .filter((element) => element.id === `${module.uid}-${visibleQuote.index}-audio`)[0]
            .pause();
        }
        return 1;
      }
    }
  };

  // useEffect(() => {
  //   if (auth.audio.isMuted) {
  //     audioElements.current.forEach((element) => {
  //       const audioElement = element;
  //       audioElement.muted = true;
  //     });
  //   } else {
  //     audioElements.current.forEach((element) => {
  //       const audioElement = element;
  //       audioElement.muted = false;
  //     });
  //   }
  // }, [auth.audio]);

  // useEffect(() => {
  //   if (audioElements.current) {
  //     const handleEnd = () => {
  //       setIsPlaying(false);
  //     };
  //     const handlePause = () => {
  //       setIsPlaying(false);
  //     };
  //     audioElements.current.forEach((element) => element.addEventListener('ended', handleEnd));
  //     audioElements.current.forEach((element) => element.addEventListener('pause', handlePause));
  //   }
  // }, [audioElements]);

  // useEffect(() => {
  //   // if (vara && inView && nextIndex === 'vara_0') {
  //   //   // setTimeout(() => {
  //   //   //   vara.draw(nextIndex);
  //   //   // }, 500);
  //   //   // if (audioElements.current && audioPlayed === false) {
  //   //   //   setTimeout(() => {
  //   //   //     setAudioPlayed(true);
  //   //   //     playAudio();
  //   //   //   }, 2000);
  //   //   // }
  //   // }
  //   // if (vara && !inView) {
  //   //   setVara(null);
  //   // }
  // }, [inView]);

  // useEffect(() => {
  //   if (isPlaying && !inView) {
  //     if (audioElements.current.length > 0) {
  //       audioElements.current[0].pause();
  //       setIsPlaying(false);
  //     }
  //   } else if (!isPlaying && inView && audioPlayed) {
  //     if (audioElements.current.length > 0) {
  //       audioElements.current[0].play();
  //       setIsPlaying(true);
  //     }
  //   }
  // }, [inView]);

  const setNextVisibleId = (currentId) => {
    const newNum = parseInt(currentId.split('_')[1], 10) + 1;
    setVisibleId(`vara_${newNum}`);
  };

  const setPrevVisibleId = (currentId) => {
    const newNum = parseInt(currentId.split('_')[1], 10) - 1;
    setVisibleId(`vara_${newNum}`);
  };

  const handleNext = () => {
    if (vara) {
      const nextLineViewed = viewedLines.filter((line) => {
        return line.index === visibleQuote.index + 1;
      });

      setDisableNext(true);
      setDisablePrev(true);

      if (nextLineViewed.length !== 0) {
        setTimeout(() => {
          setDisablePrev(false);
        }, 2000);

        if (visibleQuote.index < quoteArraySize - 2) {
          setTimeout(() => {
            setDisableNext(false);
          }, 2000);
        }
      }

      const displayedQuotes = viewedLines.length;

      const current = currentIndex.index;

      // if (audioIndex + 2 === module.module_type[0].QuoteLines.length) {
      //   setTimeout(() => {
      //     playAudio();
      //   }, 2000);
      // }

      if (currentIndex.index === quoteArraySize - 2) {
        setTimeout(() => {
          checkModuleCompletion(null, target);
        }, 3000);
      }

      if (currentIndex === visibleQuote) {
        if (currentIndex) {
          currentIndex.container.style.transition = 'opacity 1s 1s';
          currentIndex.container.style.opacity = 0;
          vara.draw(nextIndex);
          setNextVisibleId(visibleId);
        }
      } else {
        visibleQuote.container.style.opacity = 0;
        viewedLines[visibleQuote.index + 1].container.style.opacity = 1;
        setVisibleQuote(viewedLines[visibleQuote.index + 1]);
        setNextVisibleId(visibleId);
      }
    }
    setAudioIndex(audioIndex + 1);
    const newPrev = parseInt(prevIndex.split('_')[1], 10) + 1;
    if (
      viewedLines.length === module.module_type[0].QuoteLines.length &&
      newPrev < module.module_type[0].QuoteLines.length
    ) {
      setPrevIndex(`vara_${newPrev}`);
    }

    if (count < module.module_type[0].QuoteLines.length) {
      setCount(count + 1);
    }
  };

  const handlePrev = () => {
    setDisableNext(true);
    setDisablePrev(true);

    if (visibleQuote.index > 1) {
      setTimeout(() => {
        setDisablePrev(false);
      }, 2000);
    }

    if (visibleQuote.index < quoteArraySize) {
      setTimeout(() => {
        setDisableNext(false);
      }, 2000);
    }

    const prevQuote = viewedLines.filter(
      (line) => line.index === parseInt(prevIndex.split('_')[1], 10)
    );

    const currentQuote = viewedLines.filter((line) => line.index === visibleQuote.index);

    if (prevQuote.length > 0) {
      if (currentQuote[0]?.container) {
        currentQuote[0].container.style.transition = 'opacity 1s 1s';
        currentQuote[0].container.style.opacity = 0;
      }
      if (prevQuote[0]?.container) {
        prevQuote[0].container.style.opacity = 1;
        setVisibleQuote(prevQuote[0]);
        if (audioElements.current.length !== 0 && prevQuote[0].index - 1 >= 0) {
          setPrevIndex(`vara_${prevQuote[0].index - 1}`);
        } else if (prevQuote[0].index - 1 >= 0) {
          setPrevIndex(`vara_${prevQuote[0].index - 1}`);
        }
      }
    } else if (prevQuote.length === 0) {
      currentQuote[0].container.style.transition = 'opacity 1s 1s';
      currentQuote[0].container.style.opacity = 0;
      vara.draw(prevIndex);
    }

    setPrevVisibleId(visibleId);
    setAudioIndex(audioIndex - 1);

    if (count > 1) {
      setCount(count - 1);
    }
  };

  return (
    <div className="module-wrapper">
      <CSSTransition in={inViewport} timeout={1000} classNames="learning-module-ani">
        <div className="quote-wrapper" ref={ref}>
          <div
            id={`${module.uid}-quote-container`}
            className="quote-container"
            role="img"
            aria-label="Quote"
            ref={quoteContainer}
          />
          <LearningModuleControls
            module={module}
            isPlaying={isPlaying}
            handlePlay={handlePlay}
            handleNext={handleNext}
            handlePrev={handlePrev}
            disableNext={disableNext}
            disablePrev={disablePrev}
          />
          {module.module_type[0].QuoteLines.map((line, index) => {
            return (
              line.lineAudio && (
                <audio
                  className="audio-element"
                  id={`${module.uid}-${index}-audio`}
                  ref={(audioRef) => {
                    if (audioRef && !audioElements.current.includes(audioRef)) {
                      audioElements.current.push(audioRef);
                    }
                  }}
                  key={`${module.uid}-${line.id}`}
                >
                  <source src={`${process.env.REACT_APP_API_URL}${line.lineAudio.url}`} />
                  <track default kind="captions" srcLang="en" src="#" />
                </audio>
              )
            );
          })}
          <div className="body-text">
            {module.module_type[0].QuoteLines.length > 1 && (
              <p className="count">
                {count}
                {' / '}
                {module.module_type[0].QuoteLines.length}
              </p>
            )}
            {module.module_type[0].QuoteLines.length > 1 && (
              <p className="instruction-text">Please use the arrows to go through the slides</p>
            )}
          </div>
        </div>
      </CSSTransition>
    </div>
  );
};

Quote.propTypes = {
  module: PropTypes.objectOf(PropTypes.any).isRequired,
  inViewport: PropTypes.bool.isRequired,
  auth: PropTypes.objectOf(PropTypes.any).isRequired,
  checkModuleCompletion: PropTypes.func.isRequired,
  targetModule: PropTypes.any,
};

Quote.defaultProps = {
  targetModule: null,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(Quote);
