import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// depends on production (Facebook) or development (Google)
// const backendUrl = 'http://localhost:1337' || 'https://11a9c6d4fafd.ngrok.io';

// Name of the different providers we will use to login
const providersNames = ['google', 'facebook'];

// Create a Login Button
const LoginButton = ({ providerName }) => {
  return (
    <a href={`${process.env.REACT_APP_API_URL}/connect/${providerName}`}>
      <button type="button" className={`btn btn-icon-lg btn-secondary ${providerName}`}>
        <i className={`icon-${providerName}`} />
        <span className="hide-sm">Sign up with&nbsp;</span>
        {providerName}
      </button>
    </a>
  );
};

const AuthProviders = ({ isAuthenticated }) => {
  // Define variable to display Provider buttons
  let authButtons;

  // If user is not logged-in display map through the ProvidersName array and display Providers Buttons.
  if (!isAuthenticated) {
    authButtons = (
      <ul style={{ listStyleType: 'none' }}>
        {providersNames.map((providerName, i) => (
          <li key={providerName}>
            <LoginButton providerName={providerName} />
          </li>
        ))}
      </ul>
    );
  }

  return <section className="auth-button-container">{authButtons}</section>;
};

// Props Validation for AuthProviders
AuthProviders.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
};
// Props Validation for LoginButton
LoginButton.propTypes = {
  providerName: PropTypes.string.isRequired,
};

// Get the property isAuthenticated (boolean) from the state
const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps)(AuthProviders);
