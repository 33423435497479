import React, { Fragment, useEffect, useState, useRef } from 'react';
import { BrowserRouter as Router, Route, Redirect, useHistory, Switch } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { Provider } from 'react-redux';

// Smooth scroll for Safari
import smoothscroll from 'smoothscroll-polyfill';

// redux
import store from './store';
import { loadUser, setDefaultModules } from './actions/auth';
import { loadModules } from './actions/progressTracker';
import { loadAllParents, loadAllChildren } from './actions/activeModule';

// utils

// topic routes
import Topics from './components/content/Topics';

// components
import Alert from './components/shared/Alert';
import AuthNavigation from './components/menu/AuthNavigation';
import ProviderSignIn from './components/auth/providers/ProviderSignIn';
// import CookiesModal from './components/cookies-modal/CookiesModal';

// pages
import Dashboard from './components/Dashboard';
import GlobalMenu from './components/menu/GlobalNavigation/GlobalMenu';
import Sandbox from './Sandbox'; // TEMP
import Styleguide from './Styleguide'; // TEMP
import StandardContent from './components/content/standardContent/StandardContent';
import ParentCompleted from './components/content/parentTopics/parentCompleted/ParentCompleted';
import CompletionCertificate from './components/shared/CompletionCertificate';
import Landing from './components/intro/Landing';
import NoContent from './components/content/NoContent';

import BandwidthTest from './components/utils/bandwidthTest';
import BandwidthWarning from './components/shared/BandwidthWarning';

// Global audio section

const App = () => {
  const [showBandwidthWarning, setShowBandwidthWarning] = useState(false);
  const [backgroundActive, setBackgroundActive] = useState(false);
  const [backgroundMusicPlaying, setBackgroundMusicPlaying] = useState(false);
  const [audio, setAudio] = useState();
  const [hideMenu, setHideMenu] = useState(false);

  smoothscroll.polyfill();

  const routes = [
    { path: '/account', name: 'account', Component: StandardContent },
    { path: '/saved', name: 'saved', Component: StandardContent },
    { path: '/settings', name: 'settings', Component: StandardContent },
    { path: '/about', name: 'about', Component: StandardContent },
    { path: '/resources', name: 'resources', Component: StandardContent },
    { path: '/privacy', name: 'privacy', Component: StandardContent },
    { path: '/terms-and-conditions', name: 'terms', Component: StandardContent },
    { path: '/instructions', name: 'instructions', Component: StandardContent },
    { path: '/styleguide', name: 'styleguide', Component: Styleguide },
    { path: '/sandbox', name: 'sandbox', Component: Sandbox },
    { path: '/dashboard', name: 'dashboard', Component: Dashboard },
    { path: '/content/:parentTopic?/:childTopic?', name: 'topics', Component: Topics },
    { path: '/content/:parentTopic?/complete', name: 'complete', Component: ParentCompleted },
    { path: '/experienceComplete', name: 'experienceComplete', Component: CompletionCertificate },
  ];

  const redirects = [{ path: '/content', to: '/dashboard' }];

  useEffect(async () => {
    const token = localStorage.getItem('token') || sessionStorage.getItem('token');
    if (token) {
      store.dispatch(loadUser(token));
    }

    store.dispatch(setDefaultModules());
    store.dispatch(loadModules(token));
    store.dispatch(loadAllParents());
    store.dispatch(loadAllChildren());

    // set up audio values
    if (!localStorage.getItem('audio')) {
      const audioInfo = {
        isLoaded: false,
        isPaused: false,
        volume: 1,
        isMuted: false,
      };

      localStorage.setItem('audio', JSON.stringify(audioInfo));
    }

    // set up saved modules storage
    if (!localStorage.getItem('savedModules')) {
      const savedModules = [];

      localStorage.setItem('savedModules', JSON.stringify(savedModules));
    }

    if (!localStorage.getItem('answerArray')) {
      const answerArray = [];
      localStorage.setItem('answerArray', JSON.stringify(answerArray));
    }
  }, []);

  useEffect(() => {
    if (!localStorage.getItem('showTips')) {
      if (store.getState().auth.user) {
        const toolTipObject = {
          show: store.getState().auth.user.data.showTips,
          showTip: {
            module: true,
            browser: true,
          },
        };
        localStorage.setItem('showTips', JSON.stringify(toolTipObject));
      } else {
        const toolTipObject = {
          show: true,
          showTip: {
            module: true,
            browser: true,
          },
        };
        localStorage.setItem('showTips', JSON.stringify(toolTipObject));
      }
    }

    // Initially set the localStorage variable to track where the parent browser animation should start
    localStorage.setItem('parentVisited', '-1');
  });

  return (
    <Provider store={store}>
      <Router>
        <main className="main-container">
          <a className="visually-hidden skip-to-nav-link" href="#navigation">
            Skip to navigation
          </a>
          <AuthNavigation />
          {/*  <CookiesModal /> Comment out cookies modal so it doesn't keep popping up */}
          <Alert />
          <Switch>
            <Route
              path="/auth/:providerName/callback"
              render={(props) => {
                return <ProviderSignIn props={props} />;
              }}
            />
            {routes.map(({ path, name, Component }) => (
              <Route key={path} exact path={path}>
                {({ match }) => (
                  <>
                    <CSSTransition in={match != null} timeout={300} classNames="page" unmountOnExit>
                      <div className={`page ${name}`}>
                        <Component
                          backgroundActive={backgroundActive}
                          setBackgroundActive={setBackgroundActive}
                          setHideMenu={setHideMenu}
                        />
                      </div>
                    </CSSTransition>
                  </>
                )}
              </Route>
            ))}
            <Landing setHideMenu={setHideMenu} />
            {redirects.map(({ path, to }) => (
              <Route key={path} exact path={path}>
                <Redirect to={to} />
              </Route>
            ))}
            <Route path="*">
              <NoContent />
            </Route>
            {/* {privateRoutes.map(({ path, name, Component }) => (
              <PrivateRoute key={path} exact path={path}>
                {({ match }) => (
                  <CSSTransition in={match != null} timeout={300} classNames="page" unmountOnExit>
                    <div className={`page ${name}`}>
                      <Component
                        backgroundActive={backgroundActive}
                        setBackgroundActive={setBackgroundActive}
                      />
                      <Particles />
                    </div>
                  </CSSTransition>
                )}
              </PrivateRoute>
            ))} */}
          </Switch>
          <GlobalMenu
            backgroundActive={backgroundActive}
            backgroundMusicPlaying={backgroundMusicPlaying}
            setBackgroundMusicPlaying={setBackgroundMusicPlaying}
            audio={audio}
            setAudio={setAudio}
            hideMenu={hideMenu}
          />
          <BandwidthTest setShowBandwidthWarning={setShowBandwidthWarning} />
          <CSSTransition
            in={showBandwidthWarning}
            timeout={1000}
            classNames="bandwidth-warning"
            unmountOnExit
          >
            <BandwidthWarning setShowBandwidthWarning={setShowBandwidthWarning} />
          </CSSTransition>
        </main>
      </Router>
    </Provider>
  );
};

export default App;
