import React from 'react';
import PropTypes from 'prop-types';

const MobileChildTopicLabel = ({ label, completed, onLabelClick, side, tabIndex }) => {
  return (
    <div
      role="button"
      tabIndex={tabIndex}
      className={[
        'mobile-child-topic-label',
        'sub-topic-label',
        side === 0 ? 'left' : 'right',
      ].join(' ')}
      onClick={onLabelClick}
      onKeyDown={() => {}}
    >
      <div className="topic-container">
        <p>{label}</p>
        <div className="sub-topic-label-status">
          <span className="sub-topic-status-label">topic explored</span>
          <div className={['progress', completed ? 'completed' : ''].join(' ')} />
        </div>
      </div>
      <span className="arrow-bg">
        <figure className="img-arrow" />
      </span>
    </div>
  );
};

MobileChildTopicLabel.propTypes = {
  label: PropTypes.string.isRequired,
  completed: PropTypes.bool.isRequired,
  onLabelClick: PropTypes.func.isRequired,
  side: PropTypes.number.isRequired,
  tabIndex: PropTypes.number.isRequired,
};

export default MobileChildTopicLabel;
