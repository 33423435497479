import React, { useEffect, useState, useRef } from 'react';
import { Link, Redirect, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { HashLink } from 'react-router-hash-link';
import { connect } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import _ from 'lodash';
import ToolTip from '../../tool-tips/ToolTip';

import LearningModuleContainer from '../learningModules/LearningModuleContainer';
import SaveButton from '../../shared/SaveButton';
import NextButton from '../../shared/NextButton';
import { saveModule, removeModule } from '../../../actions/auth';
import { completeLearningModule, loadModules } from '../../../actions/progressTracker';

const delayedScrollValue = _.debounce((pos, currentPage) => {
  localStorage.setItem('scrollPos', pos);
  localStorage.setItem('currentPage', currentPage);
}, 100);

const ChildTopic = ({
  childTopic,
  auth,
  setSavedModule,
  dispatchRemoveModule,
  completeModule,
  completedModules,
  dispatchLoadModules,
  backgroundActive,
  setBackgroundActive,
}) => {
  // active state for save button
  const [saveActive, setSaveActive] = useState(false);
  const [hideSave, setHideSave] = useState(true);
  const [targetModule, setTargetModule] = useState();
  const [nextElement, setNextElement] = useState(false);
  const [showTips, setShowTips] = useState(false);
  const [completeModules, setCompleteModules] = useState();
  const [parentIndex, setParentIndex] = useState();
  const [parentCompletion, setParentCompletion] = useState();
  const [childCompletion, setChildCompletion] = useState();
  const [numComplete, setNumComplete] = useState(0);
  const [numTotal, setNumTotal] = useState(0);
  const [experienceComplete, setExperienceComplete] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [moduleComplete, setModuleComplete] = useState(false);

  const location = useLocation();

  const { pathname } = window.location;
  const parent = pathname.split('/')[2].replace(/-/g, ' ');
  const parentSlug = pathname.split('/')[2];

  const top = useRef();
  const introClick = useRef();
  const sideClicks = useRef([]);

  useEffect(() => {
    dispatchLoadModules();
  }, []);

  // reorder our modules based on the order field, remove any without a module type
  const orderedChildTopic = childTopic;

  orderedChildTopic.learning_modules = orderedChildTopic.learning_modules.filter(
    (module) => module.module_type[0]?.Type
  );
  orderedChildTopic.order = orderedChildTopic.learning_modules.sort((a, b) => a.order - b.order);

  // Collects the refs from the learning modules rendered on the page
  const moduleRefs = [];
  // Holds the module currently centered on the screen
  let targetModuleTemp;

  // Finds module in-view
  const findTarget = () => {
    if (moduleRefs.length !== 0) {
      targetModuleTemp = moduleRefs.filter((module) => {
        let target;
        if (module !== null && module.classList.contains('in-view')) {
          target = module;
        }
        return target;
      });
      let timer;
      if (targetModuleTemp.length === 0) {
        timer = setTimeout(() => {
          findTarget();
        }, 100);
      } else {
        setTargetModule(targetModuleTemp[0]);
        setIsLoaded(true);
        clearTimeout(timer);
      }
    } else {
      let timer;
      if (moduleRefs.length === 0) {
        timer = setTimeout(() => {
          findTarget();
        }, 100);
      } else {
        clearTimeout(timer);
      }
    }
  };

  // Sets the active state to true or false based on whether the current module in view is completed
  const markAsSaved = () => {
    if (targetModuleTemp) {
      if (targetModuleTemp.length === 0 || targetModuleTemp[0].id === 'intro') {
        setHideSave(true);
      } else {
        setHideSave(false);
        if (auth.user) {
          if (auth.user.data.savedModules.includes(targetModuleTemp[0].id)) {
            setSaveActive(true);
          } else {
            setSaveActive(false);
          }
        } else {
          const localSaved = JSON.parse(localStorage.getItem('savedModules'));
          if (localSaved.includes(targetModuleTemp[0].id)) {
            setSaveActive(true);
          } else {
            setSaveActive(false);
          }
        }
      }
    }
  };

  // Gets next section in learning module container to navigate users on mobile
  const findNextElement = () => {
    const firstElement = document.querySelector('.learning-modules-container section');
    const currentItem = document.querySelector('.in-view');
    const nextItem = currentItem?.nextSibling;

    if (nextItem) {
      setNextElement(nextItem);
    } else {
      setNextElement(firstElement);
    }
  };

  useEffect(() => {
    findNextElement();
  }, [findNextElement]);

  const handleClick = () => {
    // Get current targeted module
    findTarget();
    // If state already includes the module, remove it from state and remove the active class
    if (auth.user) {
      if (auth.user.data.savedModules.includes(targetModuleTemp[0].id)) {
        dispatchRemoveModule(targetModuleTemp, auth);
        markAsSaved();
        // If state does not contain module, save the module and add the active class
      } else {
        setSavedModule(targetModuleTemp, auth);
        markAsSaved();
      }
    } else {
      const localSaved = JSON.parse(localStorage.getItem('savedModules'));
      if (localSaved.includes(targetModuleTemp[0].id)) {
        dispatchRemoveModule(targetModuleTemp, auth);
        markAsSaved();
      } else {
        setSavedModule(targetModuleTemp);
        markAsSaved();
      }
    }
  };

  const markComplete = (target) => {
    // check if target module is complete
    let isCompleted;
    const tempChildCompletion = childCompletion;

    if (target) {
      isCompleted = tempChildCompletion.completedLearningModules.includes(target.id);
      if (!isCompleted) {
        const tempParentCompletion = parentCompletion;
        const childIndex = parentCompletion.child_topics.findIndex(
          (child) => child.id === tempChildCompletion.id
        );

        tempChildCompletion.completedLearningModules.push(target.id);

        if (
          tempChildCompletion.completedLearningModules.length ===
          tempChildCompletion.numTotalModules
        ) {
          tempChildCompletion.completed = true;
        }
        tempParentCompletion.child_topics[childIndex] = tempChildCompletion;

        const checkParentCompletion = tempParentCompletion.child_topics.filter(
          (module) => module.completed === true
        );
        if (checkParentCompletion.length === tempParentCompletion.child_topics.length) {
          tempParentCompletion.completed = true;
        }

        const checkExperienceCompletion = completedModules.filter(
          (module) => module.completed === true
        );

        if (checkExperienceCompletion.length === completedModules.length) {
          setExperienceComplete(true);
        }
        setNumComplete(tempParentCompletion.child_topics.filter((child) => child.completed).length);

        setParentCompletion(tempParentCompletion);
        completeModule(auth, tempParentCompletion, parentIndex);
      }
    }
  };

  // When the learning module container is scolled, calculate the percentage seen for each module, and add/remove the active class as needed
  const handleScroll = (e) => {
    findTarget();
    findNextElement();
    markAsSaved();
    if (targetModuleTemp !== targetModule) {
      setTargetModule(targetModuleTemp[0]);
    }
    delayedScrollValue(e.target.scrollTop, location.pathname.split('/')[3]);
  };

  useEffect(() => {
    const showTipsTemp = JSON.parse(localStorage.getItem('showTips'));
    if (localStorage.getItem('showTips')) {
      if (!showTipsTemp.show) {
        setShowTips(false);
      } else {
        setShowTips(auth.showTips.showTip.module);
      }
    }
  }, [auth.showTips]);

  useEffect(() => {
    setCompleteModules(completedModules);
  }, [completedModules]);

  useEffect(() => {
    if (completeModules) {
      setParentIndex(
        completeModules.findIndex((module) => module.id === orderedChildTopic.parent_topic.id)
      );
      setParentCompletion(
        completeModules.filter((module) => module.id === orderedChildTopic.parent_topic.id)[0]
      );
    }
  }, [completeModules]);

  useEffect(() => {
    if (parentCompletion) {
      setNumTotal(parentCompletion.child_topics.length);
      setNumComplete(parentCompletion.child_topics.filter((child) => child.completed).length);
      setChildCompletion(
        parentCompletion.child_topics.filter((module) => module.id === orderedChildTopic.id)[0]
      );
    }
  }, [parentCompletion]);

  useEffect(() => {
    findTarget();
    setCompleteModules(completedModules);
  });

  useEffect(() => {
    if (
      localStorage.getItem('scrollPos') &&
      localStorage.getItem('currentPage') === location.pathname.split('/')[3]
    ) {
      const scrollPos = parseInt(localStorage.getItem('scrollPos'), 10);
      top.current.scrollTo({ top: scrollPos, behavior: 'smooth' });
    }
    localStorage.setItem('currentPage', location.pathname.split('/')[3]);
  }, []);

  const calcOffset = (array) => {
    const num = array.length + 1;
    const r = num % 2;

    return `${parseInt(num / 2, 10)}${r > 0 ? '-half' : ''}`;
  };

  useEffect(() => {
    if (childCompletion) {
      if (childCompletion.completedLearningModules) {
        if (
          childCompletion.completedLearningModules.length === childTopic.learning_modules.length
        ) {
          setModuleComplete(true);
        }
      }
    }
  });

  return (
    <div
      className={`childTopic ${childTopic.parent_topic.UID} ${
        backgroundActive ? 'backgroundActive' : 'backgroundDisabled'
      }`}
      key="child-topic"
    >
      {experienceComplete && <Redirect to="/experienceComplete" />}
      <CSSTransition in={isLoaded} timeout={1000} classNames="topNav" unmountOnExit>
        <>
          <div className="learning-modules-top-nav">
            <div className="opacity-mask" />
            <div className={`close-link ${backgroundActive ? 'white' : 'black'}`}>
              <Link to={`/content/${parentSlug}`}>
                <span className="close-link-icon" />
                <span className="close-link-text">back to topic</span>
              </Link>
            </div>
            <CSSTransition in={!backgroundActive} timeout={1000} classNames="topNav" unmountOnExit>
              <div className={`breadcrumbs-button ${childTopic.parent_topic.UID}`}>
                <h1>{parent}</h1>
                <h2>{orderedChildTopic.name}</h2>
              </div>
            </CSSTransition>
          </div>
          <CSSTransition in={!backgroundActive} timeout={1000} classNames="topNav" unmountOnExit>
            <div className="module-side-section">
              {/* May have to change position of tooltip, depending on the side nav */}
              {showTips ? (
                <ToolTip
                  toolType="module-onboarding"
                  offset={calcOffset(orderedChildTopic.learning_modules)}
                />
              ) : null}
              <nav className="module-nav">
                <ul className="learning-module-navigation">
                  {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
                  <li
                    className={`nav-item ${targetModule?.id === 'intro' ? 'current' : ''} ${
                      moduleComplete === true ? 'completed' : ''
                    }`}
                    onClick={() => {
                      introClick.current.click();
                    }}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        introClick.current.click();
                      }
                    }}
                  >
                    <HashLink
                      smooth
                      to="#intro"
                      className={`nav-item ${targetModule?.id === 'intro' ? 'current' : ''}`}
                      ref={introClick}
                    >
                      Intro
                    </HashLink>
                  </li>
                  {orderedChildTopic.learning_modules.map((module, i) => {
                    let isCompleted = false;

                    if (childCompletion) {
                      if (childCompletion.completedLearningModules) {
                        if (childCompletion.completedLearningModules.includes(module.uid)) {
                          isCompleted = true;
                        }
                      }
                    }

                    return (
                      /* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */
                      <li
                        key={module.uid}
                        className={`${targetModule?.id === module.uid ? 'current' : ''} ${
                          isCompleted === true ? 'completed' : ''
                        }`}
                        onClick={() => {
                          sideClicks.current[i].click();
                        }}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            sideClicks.current[i].click();
                          }
                        }}
                      >
                        <HashLink
                          smooth
                          to={`#${module.uid}`}
                          className={`nav-item ${targetModule?.id === module.uid ? 'current' : ''}`}
                          ref={(ref) => sideClicks.current.push(ref)}
                        >
                          {module.name}
                        </HashLink>
                      </li>
                    );
                  })}
                  <SaveButton handleClick={handleClick} active={saveActive} hide={hideSave} />
                </ul>
                {nextElement && (
                  <NextButton
                    targetModule={nextElement}
                    handleClick={handleClick}
                    saveActive={saveActive}
                    hideSave={hideSave}
                    childTopic={childTopic}
                    completedModules={completedModules}
                  />
                )}
              </nav>
            </div>
          </CSSTransition>
        </>
      </CSSTransition>
      {/* Area where Learning Modules are displayed */}
      <LearningModuleContainer
        childTopic={childTopic}
        moduleRefs={moduleRefs}
        handleScroll={handleScroll}
        targetModule={targetModule}
        parentCompletion={parentCompletion}
        numTotal={numTotal}
        numComplete={numComplete}
        markComplete={markComplete}
        top={top}
        isLoaded={isLoaded}
        backgroundActive={backgroundActive}
        setBackgroundActive={setBackgroundActive}
      />
    </div>
  );
};

ChildTopic.propTypes = {
  childTopic: PropTypes.objectOf(PropTypes.any).isRequired,
  setSavedModule: PropTypes.func.isRequired,
  dispatchRemoveModule: PropTypes.func.isRequired,
  auth: PropTypes.objectOf(PropTypes.any).isRequired,
  completeModule: PropTypes.func.isRequired,
  completedModules: PropTypes.arrayOf(PropTypes.any).isRequired,
  dispatchLoadModules: PropTypes.func.isRequired,
  backgroundActive: PropTypes.bool.isRequired,
  setBackgroundActive: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  completedModules: state.progressTracker.completedModules,
  isLoading: state.activeModule.childLoading,
});

export default connect(mapStateToProps, {
  dispatchLoadModules: loadModules,
  setSavedModule: saveModule,
  dispatchRemoveModule: removeModule,
  completeModule: completeLearningModule,
})(ChildTopic);
