import React, { useRef, useEffect, useState } from 'react';
import { HashLink } from 'react-router-hash-link';
import PropTypes from 'prop-types';

import SaveButton from './SaveButton';

const NextButton = ({
  targetModule,
  handleClick,
  saveActive,
  hideSave,
  childTopic,
  completedModules,
}) => {
  const [totalModules, setTotalModules] = useState(childTopic.learning_modules.length);
  const [completedLearningModules, setCompletedLearningModules] = useState(
    completedModules
      .filter((parent) => parent.id === childTopic.parent_topic.id)[0]
      .child_topics.filter((child) => child.id === childTopic.id)[0].completedLearningModules.length
  );
  const [circumference, setCircumference] = useState();

  const circle = useRef(null);
  const nextRef = useRef(null);

  const calcProgress = (percent) => {
    if (circumference) {
      const offset = circumference - (percent / 100) * circumference;
      circle.current.style.strokeDashoffset = offset;
    }
  };

  useEffect(() => {
    const parentTopicId = childTopic.parent_topic.id;
    const childTopicId = childTopic.id;
    const completedNumber = completedModules
      .filter((parent) => parent.id === parentTopicId)[0]
      .child_topics.filter((child) => child.id === childTopicId)[0].completedLearningModules.length;

    setTotalModules(childTopic.learning_modules.length);
    setCompletedLearningModules(completedNumber);
  });

  useEffect(() => {
    if (circle.current) {
      const radius = circle.current.r.baseVal.value;
      setCircumference(radius * 2 * Math.PI);
    }
  }, [circle]);

  useEffect(() => {
    circle.current.style.strokeDasharray = `${circumference} ${circumference}`;
    circle.current.style.strokeDashoffset = circumference;
    const percent = (completedLearningModules / totalModules) * 100;
    calcProgress(percent);
  }, [circumference]);

  useEffect(() => {
    const percent = (completedLearningModules / totalModules) * 100;
    calcProgress(percent);
  });

  return (
    <div className="mobile-controls">
      {/* eslint-disable */}
      <span
        className="next-button"
        onClick={() => {
          nextRef.current.click();
        }}
      >
        {/* eslint-enable */}
        <svg className="progress-ring" width="37" height="37">
          <circle
            className="progress-ring__empty-circle"
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            fill="transparent"
            r="17"
            cx="18"
            cy="18"
          />
          <circle
            className="progress-ring__circle"
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            fill="transparent"
            r="17"
            cx="18"
            cy="18"
            ref={circle}
          />
        </svg>
        <HashLink smooth to={`#${targetModule.id}`} ref={nextRef}>
          <i className="next-icon" />
          <span>{`Scroll to ${targetModule.id}`}</span>
        </HashLink>
      </span>
      <SaveButton handleClick={handleClick} active={saveActive} hide={hideSave} />
    </div>
  );
};

NextButton.propTypes = {
  targetModule: PropTypes.objectOf(PropTypes.any).isRequired,
  handleClick: PropTypes.func.isRequired,
  saveActive: PropTypes.bool.isRequired,
  hideSave: PropTypes.bool.isRequired,
  childTopic: PropTypes.objectOf(PropTypes.any).isRequired,
  completedModules: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default NextButton;
