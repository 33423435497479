import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useInView } from 'react-intersection-observer';
import { CSSTransition } from 'react-transition-group';
import marked from 'marked';

const TextModule = ({ desc, moduleRefs, isLoaded }) => {
  const itemCount = desc.length;

  const { ref, inView, entry } = useInView({
    threshold: 0.25,
  });

  if (entry && !moduleRefs.includes(entry.target)) {
    moduleRefs.push(entry.target);
  }

  useEffect(() => {
    const introContainer = document.querySelector('.intro-module-ani');
    const introLinks = introContainer.querySelectorAll('a');
    introLinks.forEach((link) => {
      const targetLink = link;
      if (link.hostname !== window.location.hostname) {
        targetLink.target = '_blank';
        targetLink.rel = 'noreferrer noopener';
      }
    });
  }, []);

  return (
    <section className={`module text-module ${inView ? 'in-view' : ''}`} ref={ref} id="intro">
      <CSSTransition in={isLoaded} timeout={1500} classNames="intro-module-ani">
        <div className="module-wrapper intro-module-ani">
          <div className="text-wrap" data-count={`${itemCount}`}>
            <div className="item-wrap current">
              <p className="text" dangerouslySetInnerHTML={{ __html: marked(desc) }} />
            </div>
          </div>
        </div>
      </CSSTransition>
    </section>
  );
};

TextModule.propTypes = {
  desc: PropTypes.string.isRequired,
  moduleRefs: PropTypes.arrayOf(PropTypes.any).isRequired,
  isLoaded: PropTypes.bool.isRequired,
};

export default TextModule;
