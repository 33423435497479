import React from 'react';
import PropTypes from 'prop-types';

import MobileChildTopicCarouselItem from './MobileChildTopicCarouselItem';

import { sortChildTopics } from '../../../utils/TopicBrowserUtils';

const MobileChildTopicCarousel = ({ isVisible, parentTopic, topics, progress, selectedTopic }) => {
  const calcIndex = (index) => {
    let i = index + 1;

    if (i > 8) {
      i %= 8;
    }

    return i;
  };

  const calcCompleted = (p, id) => {
    const obj = p.child_topics.find((o) => o.id === id);
    return obj.completed;
  };

  return (
    <div
      className={['mobile-child-topic-carousel', parentTopic, isVisible ? 'visible' : ''].join(' ')}
    >
      <div
        className={['mobile-child-topic-carousel-container', `index-${selectedTopic}`].join(' ')}
      >
        {sortChildTopics(topics).map((t, i) => {
          let selected = false;
          if (i === selectedTopic) {
            selected = true;
          }

          return (
            <MobileChildTopicCarouselItem
              key={t.uid}
              parentTopicUid={parentTopic}
              topic={t}
              isCompleted={calcCompleted(progress, t.id)}
              index={calcIndex(i)}
              isSelected={selected}
              labelSide={i % 2}
              tabIndex={i + 1}
            />
          );
        })}
      </div>
    </div>
  );
};

MobileChildTopicCarousel.propTypes = {
  parentTopic: PropTypes.string.isRequired,
  topics: PropTypes.arrayOf(PropTypes.any).isRequired,
  progress: PropTypes.objectOf(PropTypes.any).isRequired,
  isVisible: PropTypes.bool.isRequired,
  selectedTopic: PropTypes.number.isRequired,
};

export default MobileChildTopicCarousel;
