// root reducer

import { combineReducers } from 'redux';
import auth from './auth';
import alert from './alert';
import activeModule from './activeModule';
import progressTracker from './progressTracker';

export default combineReducers({
  alert,
  auth,
  activeModule,
  progressTracker,
});
