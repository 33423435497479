import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useInView } from 'react-intersection-observer';
import { CSSTransition } from 'react-transition-group';

import { saveAnswer } from '../../../../actions/auth';

import MultipleChoice from './MultipleChoice';
import ShortAnswer from './ShortAnswer';

const ReflectionQuestion = ({
  reflectionQuestion,
  moduleName,
  dispatchSaveAnswer,
  auth,
  index,
  checkModuleCompletion,
}) => {
  const [selectedAnswer, setSelectedAnswer] = useState('');
  const [selectedAnswerId, setSelectedAnswerId] = useState('');
  const [typedAnswer, setTypedAnswer] = useState('');
  const [selectedNode, setSelectedNode] = useState();
  const [disabled, setDisabled] = useState(true);
  const [toggleWritten, setToggleWritten] = useState(false);
  const [showWritten, setShowWritten] = useState(false);
  const [showAnswers, setShowAnswers] = useState(true);
  const [currentAnswer, setCurrentAnswer] = useState();

  // Set ref for fieldset on multiple choice reflection questions
  const multipleChoiceField = useRef();
  const inputRefs = useRef([]);

  // Detect if question is in viewport
  const { ref, inView, entry } = useInView({
    threshold: 0.75,
  });

  // Set state if module has an answer saved.
  useEffect(() => {
    // Check if user is logged in
    if (auth.user) {
      // Check if target module has an answer saved
      const isComplete = auth.user.data.savedAnswers.filter(
        (answer) => answer.uid === `${moduleName}-question-${index}`
      )[0];

      if (isComplete) {
        // Adjust state with current answer
        setCurrentAnswer(isComplete);
      }
      // Is user is not authenticated, get temporary completed answers from local storage
    } else if (localStorage.getItem('answerArray')) {
      // Check if target module has been completed
      const isComplete = JSON.parse(localStorage.getItem('answerArray')).filter(
        (answer) => answer.uid === `${moduleName}-question-${index}`
      )[0];
      if (isComplete) {
        // Adjust state with current answer
        setCurrentAnswer(isComplete);
      }
    }
  }, [auth.user]);

  // Update module with saved data
  useEffect(() => {
    // Check if there is an saved answer in state
    if (currentAnswer) {
      setTypedAnswer(currentAnswer.typedAnswer);
      setDisabled(false);
    }
    // Multiple Choice Component State Management
    if (currentAnswer && multipleChoiceField.current) {
      // Check if reflection question type if multiple choice
      if (reflectionQuestion.type === 'multipleChoice') {
        // Set the radio button ID from the saved answer
        setSelectedAnswerId(currentAnswer.selectedAnswerId);
        // Check if there is a typed response:
        if (currentAnswer.typedAnswer.length !== 0) {
          // Copy the selected input node for the text entry state renders
          const copiedNode = inputRefs.current
            .filter((el) => el !== null)
            .filter((input) => input.querySelector(`#${currentAnswer.selectedAnswerId}`))[0];
          // Mark radio button as selected
          if (copiedNode) {
            copiedNode.querySelector('input').checked = true;
          }
          // Manage state changes on componenet
          setSelectedNode(copiedNode);
          setShowWritten(false);
          setShowAnswers(false);
          setDisabled(false);
          // If there is no typed response:
        } else {
          // Get the currently saved radio button selection
          const currentNode = inputRefs.current
            .filter((el) => el !== null)
            .filter((input) => input.querySelector(`#${currentAnswer.selectedAnswerId}`))[0];
          // Set up the cloned node for the text entry state render
          let newNode;
          if (currentNode) {
            // mark saved node as checked
            currentNode.querySelector('input').checked = true;
            newNode = currentNode.cloneNode(true);
          }
          // Manage state changes on component
          setSelectedNode(newNode);
          setShowWritten(true);
          setShowAnswers(true);
        }
      }
    }
  }, [currentAnswer]);

  const handleSelection = (e) => {
    // display option to type written answer
    setShowWritten(true);

    // save selected answer to state
    setSelectedAnswer(e.target.value);
    setSelectedAnswerId(e.target.id);
    // create node to display when typing written answer
    const newNode = e.target.parentNode.cloneNode(true);
    setSelectedNode(newNode);

    // remove selected class from other answers, but keep it on clicked answer
    Array.from(e.target.parentNode.parentNode.children)
      .filter((elem) => elem !== e.target.parentNode)
      .map((elem) => elem.classList.remove('selected'));
    e.target.parentNode.classList.add('selected');

    // allow continue to be clicked
    if (disabled) {
      setDisabled(false);
    }
  };

  // Handle text entry
  const handleType = (e) => {
    setTypedAnswer(e.target.value);
    if (reflectionQuestion.type === 'shortAnswer') {
      setShowWritten(true);
      setDisabled(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Set up the answer object
    const answer = {};
    answer.selectedAnswer = selectedAnswer;
    answer.selectedAnswerId = selectedAnswerId;
    answer.typedAnswer = typedAnswer;
    answer.uid = `${moduleName}-question-${index}`;

    // Set up scrolling to next module on submit
    if (reflectionQuestion.type === 'multipleChoice') {
      if (e.target.parentNode.parentNode.parentNode.parentNode.nextElementSibling) {
        e.target.parentNode.parentNode.parentNode.parentNode.nextElementSibling.scrollIntoView({
          behavior: 'smooth',
        });
      } else if (
        e.target.parentNode.parentNode.parentNode.parentNode.parentNode.nextElementSibling
          .tagName !== 'SECTION'
      ) {
        e.target.parentNode.parentNode.parentNode.parentNode.parentNode.nextElementSibling.nextElementSibling.scrollIntoView(
          {
            behavior: 'smooth',
          }
        );
      } else if (
        e.target.parentNode.parentNode.parentNode.parentNode.parentNode.nextElementSibling
      ) {
        e.target.parentNode.parentNode.parentNode.parentNode.parentNode.nextElementSibling.scrollIntoView(
          {
            behavior: 'smooth',
          }
        );
      }
    }
    if (reflectionQuestion.type === 'shortAnswer') {
      if (e.target.parentNode.parentNode.parentNode.parentNode.nextElementSibling) {
        e.target.parentNode.parentNode.parentNode.parentNode.nextElementSibling.scrollIntoView({
          behavior: 'smooth',
        });
      } else if (
        e.target.parentNode.parentNode.parentNode.parentNode.parentNode.nextElementSibling
          .tagName !== 'SECTION'
      ) {
        e.target.parentNode.parentNode.parentNode.parentNode.parentNode.nextElementSibling.nextElementSibling.scrollIntoView(
          {
            behavior: 'smooth',
          }
        );
      } else if (
        e.target.parentNode.parentNode.parentNode.parentNode.parentNode.nextElementSibling
      ) {
        e.target.parentNode.parentNode.parentNode.parentNode.parentNode.nextElementSibling.scrollIntoView(
          {
            behavior: 'smooth',
          }
        );
      }
    }

    // Check if user is authenticated
    if (auth.user) {
      // Dispatch saved answer to redux and db
      dispatchSaveAnswer(auth.user, answer, auth.token);
    } else {
      // If user is not authenticated, set up temporary array for saved answers
      let answerArray;
      // Check if answers are already saved in localstorage
      if (localStorage.getItem('answerArray') !== null) {
        // If saved in local storage pull answers down
        answerArray = JSON.parse(localStorage.getItem('answerArray'));
        // find targeted module saved answer
        const answerTarget = answerArray.filter((answerItem) => answerItem.uid === answer.uid);
        // if answer does not already exist, push into array then save to local storage
        if (answerTarget.length === 0) {
          answerArray.push(answer);
          localStorage.setItem('answerArray', JSON.stringify(answerArray));
        } else {
          // If answer already exists, replace then save to local storage
          const replaceIndex = answerArray.findIndex((answerItem) => answerItem.uid === answer.uid);
          answerArray[replaceIndex] = answer;
          localStorage.setItem('answerArray', JSON.stringify(answerArray));
        }
      } else {
        // If no answers are saved in local storage, set up temporary array
        answerArray = [];
        // Get targeted answer
        const answerTarget = answerArray.filter((answerItem) => answerItem.uid === answer.uid);
        // Make sure answer doesn not exist in temp array to prevent accidental duplication, then push into array
        if (answerTarget.length === 0) {
          answerArray.push(answer);
          localStorage.setItem('answerArray', JSON.stringify(answerArray));
        } else {
          // If an entry does already exist in temporary array, replace then save to local storage
          const replaceIndex = answerArray.findIndex((answerItem) => answerItem.uid === answer.uid);
          answerArray[replaceIndex] = answer;
          localStorage.setItem('answerArray', JSON.stringify(answerArray));
        }
      }
    }
    checkModuleCompletion(answer.uid);
  };

  // Hanlde clear button click
  const handleClear = (e) => {
    e.preventDefault();
    // Manage state
    setTypedAnswer('');
    setSelectedAnswer('');
    setToggleWritten(false);
    setShowWritten(false);
    if (reflectionQuestion.type === 'shortAnswer') {
      setDisabled(true);
    }

    // Remove selection from radio buttons
    Array.from(e.target.parentNode.parentNode.parentNode.querySelectorAll('.input-button')).forEach(
      (elem) => {
        elem.classList.remove('selected');
        const input = elem.querySelector('input');
        input.checked = false;
      }
    );
  };

  // Toggle the text entry by clicking button
  const handleToggle = (e) => {
    e.preventDefault();
    setShowAnswers(false);
    setShowWritten(false);
  };

  return (
    <div className="reflection-question" id={`${moduleName}-question-${index}`} ref={ref}>
      <CSSTransition in={inView} timeout={1500} classNames="learning-module-ani" unmountOnExit>
        <div className="module-wrapper">
          {reflectionQuestion.type === 'multipleChoice' && (
            <MultipleChoice
              handleSubmit={handleSubmit}
              handleClear={handleClear}
              handleSelection={handleSelection}
              handleType={handleType}
              disabled={disabled}
              reflectionQuestion={reflectionQuestion}
              selectedNode={selectedNode}
              toggleWritten={toggleWritten}
              handleToggle={handleToggle}
              showWritten={showWritten}
              moduleName={moduleName}
              showAnswers={showAnswers}
              setToggleWritten={setToggleWritten}
              setShowAnswers={setShowAnswers}
              typedAnswer={typedAnswer}
              currentAnswer={currentAnswer}
              multipleChoiceField={multipleChoiceField}
              inputRefs={inputRefs}
            />
          )}
          {reflectionQuestion.type === 'shortAnswer' && (
            <ShortAnswer
              handleSubmit={handleSubmit}
              handleClear={handleClear}
              handleType={handleType}
              disabled={disabled}
              reflectionQuestion={reflectionQuestion}
              moduleName={moduleName}
              showWritten={showWritten}
              typedAnswer={typedAnswer}
            />
          )}
        </div>
      </CSSTransition>
    </div>
  );
};

ReflectionQuestion.propTypes = {
  reflectionQuestion: PropTypes.objectOf(PropTypes.any).isRequired,
  moduleName: PropTypes.string.isRequired,
  dispatchSaveAnswer: PropTypes.func.isRequired,
  auth: PropTypes.objectOf(PropTypes.any).isRequired,
  index: PropTypes.number.isRequired,
  checkModuleCompletion: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { dispatchSaveAnswer: saveAnswer })(ReflectionQuestion);
