import React, { useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';
import PropTypes from 'prop-types';

import AnimationContainer from './animationContainer';

const IntroAnimation = ({
  handleClick,
  proceed,
  introText,
  position,
  handleGoTo,
  goToInstructions,
  setGoToInstructions,
  showNext,
  showPrev,
  goToOptions,
  setGoToOptions,
  instructionVideo,
  hideText,
  hideButtons,
  setInstructionClasses,
}) => {
  // If the skip/continue button is pressed move to intro signin screen, otherwise display intro text and controls
  return (
    <>
      <AnimationContainer
        introText={introText}
        position={position}
        goToInstructions={goToInstructions}
        setGoToInstructions={setGoToInstructions}
        goToOptions={goToOptions}
        setGoToOptions={setGoToOptions}
        instructionVideo={instructionVideo}
        hideText={hideText}
        setInstructionClasses={setInstructionClasses}
      />
      <div
        className={`intro-controls ${
          !goToInstructions && !goToOptions && !hideText ? 'display' : 'hide'
        } ${hideButtons ? 'remove' : ''}`}
      >
        <button
          type="button"
          id="previous"
          className={`btn btn-secondary no-border previous ${!showPrev && 'disabled'}`}
          onClick={handleClick}
        >
          Back
        </button>
        <button
          className={`btn btn-secondary no-border ${
            position === introText.length - 1 ? 'hide' : ''
          }`}
          type="button"
          id="continue"
          onClick={handleGoTo}
        >
          Skip intro
        </button>
        {showNext ? (
          <button
            type="button"
            id="next"
            className="btn btn-icon-md btn-secondary next"
            onClick={handleClick}
            disabled={!showNext}
          >
            Next&nbsp;
          </button>
        ) : (
          <button
            type="button"
            id="next"
            className="btn btn-icon-md btn-secondary continue"
            onClick={handleGoTo}
          >
            Continue&nbsp;
          </button>
        )}
      </div>
    </>
  );
};

IntroAnimation.propTypes = {
  handleClick: PropTypes.func.isRequired,
  handleGoTo: PropTypes.func.isRequired,
  introText: PropTypes.arrayOf(PropTypes.any),
  proceed: PropTypes.bool.isRequired,
  position: PropTypes.number.isRequired,
  goToInstructions: PropTypes.bool.isRequired,
  setGoToInstructions: PropTypes.func.isRequired,
  showNext: PropTypes.bool.isRequired,
  showPrev: PropTypes.bool.isRequired,
  goToOptions: PropTypes.bool.isRequired,
  setGoToOptions: PropTypes.func.isRequired,
  instructionVideo: PropTypes.any.isRequired,
  hideText: PropTypes.bool.isRequired,
  hideButtons: PropTypes.bool.isRequired,
  setInstructionClasses: PropTypes.func.isRequired,
};

IntroAnimation.defaultProps = {
  introText: null,
};

export default IntroAnimation;
