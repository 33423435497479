import * as THREE from 'three';
import { MathUtils } from 'three';

import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';

import {
  getRandomIntInclusive,
  easeInCirc,
  topicShardVertShader,
  topicShardFragShader,
} from '../../../utils/TopicBrowserUtils';

class TopicShard extends THREE.Group {
  static STATE_INIT = 0;

  static STATE_MAIN_MENU = 1;

  constructor(objUrl, inactiveTexture, activeTexture, alphaMap, initPos, lerpRate) {
    super();

    this.delegate = null;

    this.objUrl = objUrl;
    this.inactiveTexture = inactiveTexture;
    this.activeTexture = activeTexture;
    this.alphaMap = alphaMap;

    this.materialController = {};
    this.mix = 2.0;

    this.state = TopicShard.STATE_INIT;

    this.initPos = initPos;
    this.menuPos = new THREE.Vector3();

    this.destinationPos = initPos;

    this.lerpRateValue = lerpRate;
    this.lerpRate = 0.1;

    this.labelAnchor = new THREE.Vector2();

    this.clock = new THREE.Clock();

    this.init();
  }

  init = () => {
    // Create the shader uniforms
    this.uniforms = THREE.UniformsUtils.merge([
      THREE.UniformsLib.lights,
      {
        material: {
          value: {
            ambient: new THREE.Vector3(1, 1, 1),
            diffuse: new THREE.Vector3(1, 1, 1),
            specular: new THREE.Vector3(1, 1, 1),
            shininess: 32.0,
          },
        },
        texActive: {
          value: this.activeTexture,
        },
        texInactive: {
          value: this.inactiveTexture,
        },
        alphaMap: {
          value: this.alphaMap,
        },
        mixValue: {
          value: this.mix,
        },
      },
    ]);

    const shardMaterial = new THREE.ShaderMaterial({
      uniforms: this.uniforms,
      vertexShader: topicShardVertShader(),
      fragmentShader: topicShardFragShader(),
      transparent: true,
      lights: true,
    });

    this.materialController.mat = shardMaterial;

    shardMaterial.uniforms.texActive.value = this.activeTexture;
    shardMaterial.uniforms.texInactive.value = this.inactiveTexture;
    shardMaterial.uniforms.alphaMap.value = this.alphaMap;

    const loader = new GLTFLoader();
    loader.load(
      this.objUrl,
      (gltf) => {
        const f = 0.5;
        gltf.scene.scale.set(f, f, f);

        gltf.scene.traverse(function (child) {
          if (child instanceof THREE.Mesh) {
            const c = child;
            c.material = shardMaterial;
          }
        });

        this.add(gltf.scene);

        this.matChanger();

        if (this.delegate != null) {
          this.delegate.onLoadComplete();
        }
      },
      undefined,
      function (error) {
        // console.error( error );
      }
    );
  };

  matChanger = () => {
    this.materialController.mat.uniforms.mixValue.value = this.mix;
  };

  setMenuDestPos(pos) {
    this.menuPos = pos;
  }

  setInitPos(pos) {
    this.initPos = pos;
  }

  distanceFromCam(dis) {
    const min = 10;
    const thresh = 164;
    // 150 = selected = mix 1
    // 300 = not selected = mix 0
    const l = MathUtils.clamp(dis - min, 0, thresh);
    const per = l / thresh;
    const result = easeInCirc(per);

    this.mix = 2.0 * result;

    this.matChanger();
  }

  setState(state) {
    switch (state) {
      case TopicShard.STATE_INIT:
        this.destinationPos = this.initPos;
        this.lerpRate = 5;
        break;
      case TopicShard.STATE_MAIN_MENU:
        this.destinationPos = this.menuPos;
        this.lerpRate = getRandomIntInclusive(this.lerpRateValue - 5, this.lerpRateValue + 5) / 100;
        break;
      default:
        break;
    }
  }

  destroy() {
    this.delegate = null;

    this.objUrl = null;

    this.inactiveTexture.dispose();
    this.inactiveTexture = null;
    this.activeTexture.dispose();
    this.activeTexture = null;
    this.alphaMap.dispose();
    this.alphaMap = null;

    this.uniforms = null;
    this.materialController.mat.dispose();
    this.materialController = null;

    this.initPos = null;
    this.menuPos = null;
    this.destinationPos = null;
    this.labelAnchor = null;
    this.clock = null;

    this.traverse((object) => {
      if (!object.isMesh) return;

      object.geometry.dispose();
    });
  }

  animate() {
    const delta = this.clock.getDelta();
    const newPos = this.position.lerp(this.destinationPos, this.lerpRate * delta);
    this.position.set(newPos.x, newPos.y, newPos.z);
  }
}

export default TopicShard;
