import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { passwordEmail } from '../../../actions/auth';
import { setAlert } from '../../../actions/alert';
import { emailValidation } from '../../utils/validationRules';

const ForgotPassword = ({ dispatchPasswordEmail, dispatchSetAlert }) => {
  const [formData, setFormData] = useState({
    email: '',
  });
  const [submitted, setSubmitted] = useState(false);

  const { email } = formData;

  const handleChange = (e) =>
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });

  const handleSubmit = (e) => {
    e.preventDefault();

    const isValidEmail = emailValidation(email);

    if (!isValidEmail) {
      dispatchSetAlert('Please enter a valid email address', 'danger');
    } else {
      dispatchPasswordEmail(email);
      setSubmitted(true);
    }
  };

  return (
    <>
      <div className="link-back">
        <Link to="/" className="back-link-icon">
          <span className="hide-xs">back</span>
        </Link>
      </div>
      <div className="container container-auth text-center">
        {!submitted ? (
          <>
            <h1>Forgot your Password?</h1>
            <p>
              Enter email below. If it matches one in our records you will receive an email to reset
              your password&nbsp;shortly!
            </p>
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="email">Email</label>
                <input
                  id="email"
                  className="form-input"
                  type="text"
                  name="email"
                  value={email}
                  onChange={(e) => handleChange(e)}
                />
              </div>
              <input type="submit" className="btn btn-lg btn-ternary" value="Submit" />
            </form>
          </>
        ) : (
          <>
            <h2>Thank you!</h2>
            <p>You will be receiving an email with a link to reset your password shortly.</p>
          </>
        )}
      </div>
    </>
  );
};

ForgotPassword.propTypes = {
  dispatchPasswordEmail: PropTypes.func.isRequired,
  dispatchSetAlert: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated, // state.auth would give us every property
});

export default connect(mapStateToProps, {
  dispatchPasswordEmail: passwordEmail,
  dispatchSetAlert: setAlert,
})(ForgotPassword);
