import React, { useRef, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ScrollInstructions from '../../parentTopics/parent-topic-browser/ScrollInstructions';

const ChildTopicCompletion = ({ parentName, childTopic, progressTracker }) => {
  const [totalChildren, setTotalChildren] = useState(0);
  const [completedChildren, setCompletedChildren] = useState(0);
  const [completedModules, setCompletedModules] = useState([]);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    setCompletedModules(
      progressTracker.completedModules.filter(
        (module) => module.id === childTopic.parent_topic.id
      )[0]
    );
  }, [progressTracker]);

  const completeModules = progressTracker.completedModules.filter(
    (module) => module.id === childTopic.parent_topic.id
  )[0];

  const circle = useRef(null);

  let circumference;

  useEffect(() => {
    if (circle.current) {
      const radius = circle.current.r.baseVal.value;
      circumference = radius * 2 * Math.PI;
      circle.current.style.strokeDasharray = `${circumference} ${circumference}`;
      circle.current.style.strokeDashoffset = circumference;
    }
  });

  const calcProgress = (percent) => {
    const offset = circumference - (percent / 100) * circumference;
    circle.current.style.strokeDashoffset = offset;
  };

  useEffect(() => {
    setTotalChildren(completeModules.child_topics.length);
    setCompletedChildren(
      completeModules.child_topics.filter((module) => module.completed === true).length
    );
  });

  useEffect(() => {
    const percent = (completedChildren / totalChildren) * 100;
    calcProgress(percent);
  });

  const checkMobile = () => {
    return 'hi';
  };

  const handleClick = (e) => {
    e.currentTarget.parentNode.nextElementSibling.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  };

  return (
    <div className="completion-container">
      <h2 className="parent-name">{parentName}</h2>
      <div className="completion-wheel">
        <div className="completion-numbers">
          <p className="divisor">{`${completedChildren}/${totalChildren}`}</p>
          <p className="description">topics explored</p>
        </div>
        <svg className="progress-ring" width="284" height="284">
          <circle
            className="progress-ring__empty-circle"
            stroke="black"
            strokeWidth="12"
            strokeLinecap="round"
            fill="transparent"
            r="116"
            cx="142"
            cy="142"
          />
          <circle
            className="progress-ring__circle"
            stroke="white"
            strokeWidth="18"
            strokeLinecap="round"
            fill="transparent"
            r="116"
            cx="142"
            cy="142"
            ref={circle}
          />
        </svg>
      </div>
      <div
        className={`child-scroll-instructions ${checkMobile()}`}
        onClick={handleClick}
        onKeyDown={handleClick}
        role="button"
        tabIndex={-1}
      >
        <figure className="scroll-image" />
        <p>Scroll to continue</p>
      </div>
      <div className={`child-scroll-instructions child-scroll-mobile ${checkMobile()}`}>
        <figure className="scroll-image" />
        <p>Swipe to continue</p>
      </div>
    </div>
  );
};

ChildTopicCompletion.propTypes = {
  parentName: PropTypes.string.isRequired,
  childTopic: PropTypes.objectOf(PropTypes.any).isRequired,
  progressTracker: PropTypes.objectOf(PropTypes.any).isRequired,
};

const mapStateToProps = (state) => ({
  progressTracker: state.progressTracker,
});

export default connect(mapStateToProps)(ChildTopicCompletion);
