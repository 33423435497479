import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { CHILD_LOADING } from '../../../../actions/types';

const NextSteps = ({ childName, nextChild, top, parentLink, dispatch }) => {
  return (
    <section className="next-steps">
      <div className="next-steps-container">
        <p>Restart the previous topic if you’ve missed something, or explore the next topic.</p>
        <div className="next-steps-actions-container">
          <div className="action restart">
            <h2>{childName}</h2>
            <button
              type="button"
              onClick={() => top.current.scrollTo({ top: 0, behavior: 'smooth' })}
            >
              Restart Topic
            </button>
          </div>
          <div className="divider" />
          <div className="action next-topic">
            <h2>{nextChild.name}</h2>
            <Link
              to={`/content/${parentLink}/${nextChild.uid}`}
              onClick={() => {
                dispatch({ type: CHILD_LOADING });
              }}
            >
              Next Topic
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

NextSteps.propTypes = {
  childName: PropTypes.string.isRequired,
  nextChild: PropTypes.objectOf(PropTypes.any).isRequired,
  top: PropTypes.objectOf(PropTypes.any).isRequired,
  parentLink: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default connect()(NextSteps);
