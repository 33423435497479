import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import PropTypes from 'prop-types';
import { loadModules } from '../../../actions/progressTracker';
import MenuChildTopics from './MenuChildTopics';
import MenuParentPreview from './MenuParentPreview';
import overlayRelationships from './img/menu-tile-relationships.png';
import overlayStigma from './img/menu-tile-stigma.png';
import overlayIdentity from './img/menu-tile-identity.png';
import overlayCulture from './img/menu-tile-culture.png';
import overlayPossibilities from './img/menu-tile-possibilities.png';
import MenuFooter from './MenuFooterNavigation';

// We can use this as the base for the component where users select the parent topic.
const GlobalMenuItem = ({
  dispatchLoadTracker,
  completedModules,
  parentMenu,
  childMenu,
  isLoading,
  token,
  loadingTracker,
  closeModal,
  hideControls,
  showGlobalMenu,
}) => {
  // Set Default Props for Token if User is not logged in when opening menu
  GlobalMenuItem.defaultProps = {
    token: undefined,
  };

  const [activeNav, setActiveNav] = useState(0);
  const [activeParentModule, setActiveParentModule] = useState();
  const [trackerParentModule, setTrackerParentModule] = useState();
  const [parentBg, setParentBg] = useState();

  const parentOverlayImg = () => {
    switch (parentBg) {
      case 'relationships':
        return overlayRelationships;
      case 'stigma':
        return overlayStigma;
      case 'identity':
        return overlayIdentity;
      case 'current-culture-of-dementia-care':
        return overlayCulture;
      case 'possibilities':
        return overlayPossibilities;
      default:
        return overlayRelationships;
    }
  };

  useEffect(async () => {
    dispatchLoadTracker(token);
  }, []);

  const getTracker = (index) => {
    return completedModules.filter((obj) => obj.id === parentMenu[index].id)[0];
  };

  // set active nav to current parent or 1st if outside of topics
  useEffect(async () => {
    const { pathname } = window.location;
    const parent = pathname.split('/')[2];

    let index = parentMenu.findIndex((parentItem) => parentItem.UID === parent);
    index = index === -1 ? 0 : index;

    setActiveParentModule(parentMenu[index]);
    setTrackerParentModule(getTracker(index));
    setActiveNav(index);
    setParentBg(parentMenu[index].UID);
  }, [parentMenu, completedModules]);

  // Sets the active parent module and tracker info
  const selectParentTopic = (index) => {
    setActiveParentModule(parentMenu[index]);
    setTrackerParentModule(getTracker(index));
    setActiveNav(index);
    setParentBg(parentMenu[index].UID);
  };

  // Allow for drag scroll on desktop
  const scroll = useRef(null);
  useEffect(() => {
    let isDown = false;
    let startX;
    let scrollLeft;

    if (scroll.current) {
      scroll.current.addEventListener('mousedown', (e) => {
        isDown = true;
        scroll.current.classList.add('scroll-active');
        startX = e.pageX - scroll.current.offsetLeft;
        scrollLeft = scroll.current.scrollLeft;
      });
      scroll.current.addEventListener('mouseleave', () => {
        isDown = false;
        scroll.current.classList.remove('scroll-active');
      });
      scroll.current.addEventListener('mouseup', () => {
        isDown = false;
        scroll.current.classList.remove('scroll-active');
      });
      scroll.current.addEventListener('mousemove', (e) => {
        if (!isDown) return;
        e.preventDefault();
        const x = e.pageX - scroll.current.offsetLeft;
        const walk = x - startX;
        scroll.current.scrollLeft = scrollLeft - walk;
      });
    }
  });

  const handleKeyboardNavigation = (e) => {
    const topMenu = scroll.current;
    const subMenu = document.getElementById('child-topics-viewer');
    const button = document.getElementById('explore-btn');

    // Keep keyboard navigation on large screens
    if (window.innerWidth > 1075) {
      // Parent Menu Navigation
      if (e.target.classList.contains('parent-item')) {
        // Enable Up/Down arrow key navigation for Parent Menu
        if (
          (e.keyCode === 38 || e.key === 'ArrowUp') &&
          e.target.parentElement !== topMenu.firstChild
        ) {
          e.target.parentElement.previousElementSibling.firstChild.focus();
        } else if (
          (e.keyCode === 40 || e.key === 'ArrowDown') &&
          e.target.parentElement !== topMenu.lastChild
        ) {
          e.target.parentElement.nextElementSibling.firstChild.focus();
        }

        // If right arrow is pressed when focusing a parent item  -> move focus to top of child menu
        if (e.keyCode === 39 || e.key === 'ArrowRight') {
          subMenu.firstChild.firstChild.focus();
        }
      }

      // Submenu navigation
      if (e.target.parentElement.parentElement.classList.contains('child-topics-viewer')) {
        // Enable Up/Down arrow key navigation in subMenu
        if (
          (e.keyCode === 38 || e.key === 'ArrowUp') &&
          e.target !== subMenu.firstChild.firstChild
        ) {
          e.target.previousElementSibling.focus();
        }

        if (
          (e.keyCode === 40 || e.key === 'ArrowDown') &&
          e.target !== subMenu.firstChild.lastChild
        ) {
          e.target.nextElementSibling.focus();
        }

        // If escape or left arrow is pressed while navigationg child menu -> Move focus back to parent menu
        if (e.key === 'Escape' || e.key === 'Esc' || e.key === 'ArrowLeft' || e.keyCode === 37) {
          e.preventDefault();
          topMenu.querySelector('.active').focus();
        }

        // If right arrow key is pressed while navigating child menu -> Move focus to explore button
        if (e.key === 'ArrowRight' || e.keyCode === 39) {
          button.focus();
        }
      }

      // If left arrow key is press while focused on explore button -> Move focus back to child menu
      if (e.target.id === 'explore-btn') {
        if (e.key === 'ArrowLeft' || e.keyCode === 37) {
          subMenu.firstChild.firstChild.focus();
        }
      }
    }
    if (window.innerWidth <= 1075) {
      // Parent Menu Navigation
      if (e.target.classList.contains('parent-item')) {
        // Enable Right/Left arrow key navigation for Parent Menu
        if (
          (e.keyCode === 37 || e.key === 'ArrowLeft') &&
          e.target.parentElement !== topMenu.firstChild
        ) {
          e.target.parentElement.previousElementSibling.firstChild.focus();
        } else if (
          (e.keyCode === 39 || e.key === 'ArrowRight') &&
          e.target.parentElement !== topMenu.lastChild
        ) {
          e.target.parentElement.nextElementSibling.firstChild.focus();
        }

        // If down arrow is pressed when focusing a parent item  -> move focus to top of child menu
        if (e.keyCode === 40 || e.key === 'ArrowDown') {
          subMenu.firstChild.firstChild.focus();
        }

        // If up arrow key is pressed while navigating parent menu -> Move focus to explore button
        if (e.key === 'ArrowUp' || e.keyCode === 38) {
          window.scrollTop = 0;
          button.focus();
        }
      }

      // Submenu navigation
      if (e.target.parentElement.parentElement.classList.contains('child-topics-viewer')) {
        // Enable Up/Down arrow key navigation in subMenu
        if (
          (e.keyCode === 38 || e.key === 'ArrowUp') &&
          e.target !== subMenu.firstChild.firstChild
        ) {
          e.target.previousElementSibling.focus();
        }

        if (
          (e.keyCode === 40 || e.key === 'ArrowDown') &&
          e.target !== subMenu.firstChild.lastChild
        ) {
          e.target.nextElementSibling.focus();
        }

        // If escape is pressed or the up arrow is pressed on the top child menu item  -> Move focus back to parent menu
        if (
          e.key === 'Escape' ||
          e.key === 'Esc' ||
          ((e.key === 'ArrowUp' || e.keyCode === 38) && e.target === subMenu.firstChild.firstChild)
        ) {
          e.preventDefault();
          topMenu.querySelector('.active').focus();
        }
      }

      // If explore button is focused
      if (e.target === button) {
        if (e.key === 'ArrowDown' || e.keyCode === 40) {
          e.preventDefault();
          topMenu.querySelector('.active').focus();
        }
      }
    }
  };

  useEffect(() => {
    document.addEventListener('keyup', handleKeyboardNavigation);

    return () => {
      document.removeEventListener('keyup', handleKeyboardNavigation);
    };
  }, []);

  useEffect(() => {
    scroll.current.addEventListener('focus', () => {
      scroll.current.firstChild.firstChild.focus();
    });
  }, [scroll]);

  useEffect(() => {
    const childLinks = document.querySelectorAll('.child-link');
    childLinks.forEach((link) => {
      const target = link;
      target.tabIndex = -1;
    });
  });

  return (
    <div className="global-menu-modal">
      <div className="glow" />
      <button className="no-style-btn" type="button" onClick={closeModal}>
        <div className="esc-btn" />
        esc
      </button>
      <div className={`global-menu ${!loadingTracker && 'loading'}`}>
        <CSSTransition
          in={loadingTracker}
          timeout={1000}
          classNames="intro-container-ani"
          unmountOnExit
        >
          <nav
            className="parent-nav"
            ref={scroll}
            tabIndex="0"
            role="tabpanel"
            aria-label="Menu Navigation, use the arrow keys to navigate the menu and enter to select topics"
          >
            {completedModules &&
              parentMenu.map((parentTopic, index) => {
                const meterColor = (place) => {
                  switch (place) {
                    case 0:
                      return 'periwinkle';
                    case 1:
                      return 'watermelon';
                    case 2:
                      return 'yellow';
                    case 3:
                      return 'orange';
                    default:
                      return 'blue';
                  }
                };
                const trackerParentTopic = completedModules.filter(
                  (obj) => obj.id === parentTopic.id
                )[0];
                const numChildCompleted = trackerParentTopic?.child_topics.filter(
                  (obj) => obj.completed === true
                ).length;
                const numChildTotal = trackerParentTopic?.child_topics.length;
                return (
                  <div className="parent-item-block" key={parentTopic.id}>
                    <button
                      className={`${index === activeNav ? 'active' : 'not-active'} parent-item`}
                      type="button"
                      href={`#${index}`}
                      onClick={() => selectParentTopic(index)}
                      tabIndex="-1"
                      aria-label={`${parentTopic.name}, ${parentTopic.description}`}
                      role="menuitem"
                    >
                      <div>
                        <p>
                          {parentTopic.name === 'Current Culture of Dementia Care' ||
                          parentTopic.name === 'Current Culture of Dementia'
                            ? 'Current Culture'
                            : parentTopic.name}
                        </p>
                        <progress
                          className="custom-meter"
                          value={numChildCompleted}
                          max={numChildTotal}
                          data-color={meterColor(index)}
                        />
                        <span className="progress-nums">
                          {numChildCompleted}
                          /
                          {numChildTotal}
                        </span>
                      </div>
                    </button>
                  </div>
                );
              })}
          </nav>
        </CSSTransition>
        <div id="child-topics-viewer" className="child-topics-viewer">
          {trackerParentModule && (
            <MenuChildTopics
              parentURL={activeParentModule.UID}
              parentID={activeParentModule.id}
              trackerChildTopics={trackerParentModule.child_topics}
              childMenu={childMenu}
              closeMenu={closeModal}
            />
          )}
        </div>
        {activeParentModule && (
          <div className={`parent-preview topic-${activeParentModule.UID}`}>
            <>
              <img className="parent-background" src={parentOverlayImg()} alt="background" />
              <MenuParentPreview parentTopic={activeParentModule} closeMenu={closeModal} />
            </>
          </div>
        )}
      </div>
      <MenuFooter
        closeModal={closeModal}
        hideControls={hideControls}
        showGlobalMenu={showGlobalMenu}
      />
    </div>
  );
};

GlobalMenuItem.propTypes = {
  dispatchLoadTracker: PropTypes.func.isRequired,
  token: PropTypes.string,
  completedModules: PropTypes.arrayOf(PropTypes.any).isRequired,
  parentMenu: PropTypes.arrayOf(PropTypes.any).isRequired,
  childMenu: PropTypes.arrayOf(PropTypes.any).isRequired,
  isLoading: PropTypes.bool.isRequired,
  loadingTracker: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  hideControls: PropTypes.bool.isRequired,
  showGlobalMenu: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  token: state.auth.token,
  completedModules: state.progressTracker.completedModules,
  parentMenu: state.activeModule.parentMenu,
  childMenu: state.activeModule.childMenu,
  isLoading: state.activeModule.loadingTopics,
  loadingTracker: state.progressTracker.modulesLoaded,
});

export default connect(mapStateToProps, {
  dispatchLoadTracker: loadModules,
})(GlobalMenuItem);
