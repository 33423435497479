import React, { Fragment, useCallback, useState, useEffect } from 'react';
import { Link, Redirect, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// Google and Facebook Providers
import AuthProviders from '../providers/AuthProviders';
import Particles from '../../shared/Particles';

import { login } from '../../../actions/auth';
import { setAlert } from '../../../actions/alert';
import { emailValidation, passwordValidation } from '../../utils/validationRules';
import LoadingSpinner from '../LoadingSpinner';

const Login = ({ isAuthenticated, dispatchLogin, dispatchSetAlert, alert }) => {
  // We use useState hook to set up our state
  // formData is a property of our state, like state = { formData: { } }
  // setFormData is to update our state, like this.setState({ formData: })
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });
  const [tempLogin, setTempLogin] = useState(true);
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [isSigningIn, setSigningIn] = useState(false);

  useState(() => {
    if (sessionStorage.getItem('email')) {
      setFormData({ ...formData, email: sessionStorage.getItem('email') });
    }
    if (sessionStorage.getItem('rememberMe')) {
      setTempLogin(!sessionStorage.getItem('rememberMe'));
    }
  }, []);

  // Destructure
  const { email, password } = formData;

  const onChange = (e) => {
    // we update our state
    // ...formData makes a copy of the current state
    // then we grave the name porperty of our input (could it be name, email, etc)
    // and we update it with the value we input
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setEmailError(false);
    setPasswordError(false);
    setSigningIn(false);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setSigningIn(true);

    const isValidEmail = emailValidation(email);
    const isValidPassword = passwordValidation(password);

    if (!isValidEmail) {
      setEmailError(true);
      setSigningIn(false);
    } else if (!isValidPassword) {
      setPasswordError(true);
      setSigningIn(false);
    } else {
      setEmailError(false);
      setPasswordError(false);
      setSigningIn(true);
      dispatchLogin(email, password, tempLogin);
    }
  };

  // Show and hide password
  const passwordVisibilityHandler = (e) => {
    const passwordInput = e.target.parentNode.classList.contains('password-visibility')
      ? e.target.parentNode.previousElementSibling
      : e.target.previousElementSibling;
    // const passwordInput = e.target.parentNode.previousElementSibling.querySelector('input');
    const button = e.target.parentNode.classList.contains('password-visibility')
      ? e.target.parentNode
      : e.target;
    const isAriaPressed = button.getAttribute('aria-pressed') === 'true';
    button.setAttribute('aria-pressed', isAriaPressed ? 'false' : 'true');

    const correctKey = e.keyCode === 13 || e.keyCode === 32;

    if (e.keyCode) {
      if (passwordInput.type === 'password' && correctKey === true) {
        passwordInput.type = 'text';
      } else if (passwordInput.type !== 'password' && correctKey === true) {
        passwordInput.type = 'password';
      }
    }

    if (e.keyCode === undefined) {
      if (passwordInput.type === 'password') {
        passwordInput.type = 'text';
      } else if (passwordInput.type !== 'password') {
        passwordInput.type = 'password';
      }
    }
  };

  const isShowingAlert = alert?.length > 0;

  // Redirect if Logged in
  if (isAuthenticated) {
    return <Redirect to="/dashboard" />;
  }

  return (
    <Fragment key="login">
      <div className="link-back">
        <Link to="/" className="back-link-icon">
          <span className="hide-xs">back</span>
        </Link>
      </div>
      <div className="container container-auth text-center login-container">
        <h1>Welcome Back</h1>
        <form onSubmit={(e) => onSubmit(e)}>
          <div className="form-group">
            <label htmlFor="email">Email address</label>
            <input
              id="email"
              type="email"
              name="email"
              value={email}
              className={emailError ? 'error-state' : ''}
              onChange={(e) => onChange(e)}
            />
            {emailError && <p className="error-message">Please enter a valid email address</p>}
          </div>
          <div className="form-group password-group">
            <label htmlFor="password">Password</label>
            <input
              id="password"
              type="password"
              name="password"
              minLength="6"
              value={password}
              className={passwordError ? 'error-state' : ''}
              onChange={(e) => onChange(e)}
            />
            {passwordError && (
              <p className="error-message">
                Please ensure your password is longer than 6 characters
              </p>
            )}
            <div
              role="button"
              tabIndex="0"
              aria-pressed="false"
              className="password-visibility visibility"
              onClick={passwordVisibilityHandler}
              onKeyDown={passwordVisibilityHandler}
            >
              <span className="password-show">show</span>
              <span className="password-hide">hide</span>
            </div>
          </div>
          <div className="input-inline">
            <div>
              <input
                type="checkbox"
                id="stay-logged-in"
                name="stay-logged-in"
                checked={!tempLogin}
                onChange={() => {
                  setTempLogin(!tempLogin);
                }}
              />
              <label htmlFor="stay-logged-in">Remember me</label>
            </div>
            <Link to="/forgot-password" className="forgot-password-link">
              Forgot password?
            </Link>
          </div>
          <button type="submit" className="btn btn-lg btn-ternary login-btn" name="login">
            {isSigningIn && !isShowingAlert ? <LoadingSpinner /> : 'Continue'}
          </button>
        </form>
        <p className="divider-text">
          <span>or</span>
        </p>

        <AuthProviders />

        <div className="auth-link">
          <Link to="/dashboard">Continue as guest</Link>
        </div>
      </div>
    </Fragment>
  );
};

Login.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  dispatchLogin: PropTypes.func.isRequired,
  dispatchSetAlert: PropTypes.func.isRequired,
  alert: PropTypes.arrayOf(
    PropTypes.shape({
      msg: PropTypes.string.isRequired,
      alertType: PropTypes.string.isRequired,
      timeout: PropTypes.number,
    })
  ).isRequired,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated, // state.auth would give us every property
  alert: state.alert,
});

export default connect(mapStateToProps, {
  dispatchLogin: login,
  dispatchSetAlert: setAlert,
})(Login);
