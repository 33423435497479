import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import marked from 'marked';

const Citation = ({ citation }) => {
  // External links open in new window
  useEffect(() => {
    const links = document.querySelectorAll('.quote-citation a');
    links.forEach((link) => {
      const targetLink = link;
      if (link.hostname !== window.location.hostname) {
        targetLink.target = '_blank';
        targetLink.rel = 'noreferrer noopener';
      }
    });
  }, []);

  return (
    citation && (
      <div className="quote-citation" dangerouslySetInnerHTML={{ __html: marked(citation) }} />
    )
  );
};

Citation.propTypes = {
  citation: PropTypes.string.isRequired,
};

export default Citation;
