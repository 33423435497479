import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const Loading = ({ isLoading, setIsLoaded }) => {
  const [counter, setCounter] = useState(0);
  const [timerComplete, setTimerComplete] = useState(false);

  let timer;

  useEffect(() => {
    timer = setTimeout(() => {
      if (counter === 100) {
        clearTimeout(timer);
        setTimerComplete(true);
      }
      if (counter < 100) {
        setCounter(counter + 1);
      }
    }, 20);
  });

  useEffect(() => {
    if (!isLoading && counter === 100) {
      setIsLoaded(true);
    }
  }, [isLoading, timerComplete]);

  return (
    <div className="loadingScreen">
      <i className="headphones" />
      <p>Use headphones for the best experience</p>
      <span className="loadingPercent">{`${counter}%`}</span>
    </div>
  );
};

Loading.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  setIsLoaded: PropTypes.func.isRequired,
};

export default Loading;
