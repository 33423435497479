export const emailValidation = (email) => {
  // Looks for 'anystring'@'anystring'.'anystring'
  const regExp = /\S+@\S+\.\S+/;

  return regExp.test(email);
};

export const passwordValidation = (password) => {
  // Looks for a password of greater than 6 characters
  const regExp = /^(?=.{6,})/;

  return regExp.test(password);
};
