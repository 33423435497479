import * as THREE from 'three';
import { MathUtils } from 'three';

// Post processing
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass';
import { BokehPass } from 'three/examples/jsm/postprocessing/BokehPass';

// Custom Threejs components
import MainCamera from './MainCamera';
import ParticleGroup from './ParticleGroup';
import TopicShard from './TopicShard';
import ShardLabel from './ShardLabel';

// Assets
import bgTexture from '../../../../assets/textures/bg-texture.jpg';
// Model Assets
import relationshipsModelUrl from '../../../../assets/models/shard-relationships.glb';
import stigmaModelUrl from '../../../../assets/models/shard-stigma.glb';
import identityModelUrl from '../../../../assets/models/shard-identity.glb';
import cultureModelUrl from '../../../../assets/models/shard-culture.glb';
import possibilitiesModelUrl from '../../../../assets/models/shard-possibilities.glb';

import {
  degreesToRadians,
  getRandomIntInclusive,
  getTextureUrls,
  clamp,
} from '../../../utils/TopicBrowserUtils';

class ShardMenu {
  static STATE_INIT = 0;

  static STATE_MENU_OPEN = 1;

  static STATE_UI_VISIBLE = 2;

  static STATE_RELATIONSHIPS = 3;

  static STATE_STIGMA = 4;

  static STATE_IDENTITY = 5;

  static STATE_CULTURE = 6;

  static STATE_POSSIBILITIES = 7;

  static STATE_SHARD_SELECTED = 8;

  constructor(win) {
    this.window = win;

    this.shardLabels = [];

    this.textureUrls = getTextureUrls();
    this.texturesLoaded = 0;
    this.totalAssetsLoaded = 0;
    this.totalAssets = this.textureUrls.length + 5; // +5 models

    this.selectedTopic = '';

    this.width = win.innerWidth;
    this.height = win.innerHeight;

    this.postprocessing = {};

    this.blurLerp = 1.0;

    this.delegate = null;

    // Former state variables
    this.state = {
      curIndex: 0,
      isInteractive: false,
      isUIVisible: false,
      labelState: {
        relationships: ShardLabel.STATE_LABEL_HIDDEN,
        stigma: ShardLabel.STATE_LABEL_HIDDEN,
        identity: ShardLabel.STATE_LABEL_HIDDEN,
        culture: ShardLabel.STATE_LABEL_HIDDEN,
        possibilities: ShardLabel.STATE_LABEL_HIDDEN,
      },
      topicStatus: {
        relationships: {
          completed: 0,
          max: 8,
        },
        stigma: {
          completed: 1,
          max: 4,
        },
        identity: {
          completed: 2,
          max: 6,
        },
        culture: {
          completed: 3,
          max: 5,
        },
        possibilities: {
          completed: 4,
          max: 5,
        },
      },
      loadCompletion: 0,
      hasLoaded: false,
    };

    // Retrieve the local storage variable to detect if a child topic has been visited
    const parentVisited = parseInt(localStorage.getItem('parentVisited'), 10);

    // If the value is -1, display the parent topic browser in it's initial state
    // If the value is anything else, start the parent browser at the specific parent topic
    if (parentVisited > -1) {
      this.state.curIndex = parentVisited + 2;
    }

    // Make sure to set the value back to -1 since we're back on the parent browser
    localStorage.setItem('parentVisited', -1);

    this.loadTextures();
    this.initCameraPos();
    this.init();
    this.initLights();
    this.drawParticles();
    this.animate();

    if (parentVisited > -1) {
      this.configShardState(this.state.curIndex);
    }

    window.addEventListener('resize', this.onHandleWindowResize);
  }

  build(ref) {
    this.htmlRef = ref;
    this.htmlRef.appendChild(this.renderer.domElement);
  }

  setupLabels(l) {
    this.shardLabels = l;
  }

  selectShard(distance, uid) {
    this.camera.selectShard(distance);
    this.selectedTopic = uid;
  }

  loadTextures = () => {
    this.textures = [];

    this.loadTex(this.texturesLoaded);
  };

  loadTex = (index) => {
    const loader = new THREE.TextureLoader();

    loader.load(this.textureUrls[index], (texture) => {
      const tex = texture;
      tex.anisotropy = this.renderer.capabilities.getMaxAnisotropy();
      tex.minFilter = THREE.LinearFilter;
      tex.flipY = false;

      this.textures[index] = tex;

      this.texturesLoaded += 1;
      this.totalAssetsLoaded += 1;

      const per = Math.round((this.totalAssetsLoaded / this.totalAssets) * 100);

      if (this.delegate != null) {
        this.delegate.handlePerLoaded(per);
      }

      if (this.texturesLoaded >= this.textureUrls.length) {
        this.createGeometry();
      } else {
        this.loadTex(this.texturesLoaded);
      }
    });
  };

  init = () => {
    const cPos = this.cameraPos[this.state.curIndex];
    const cRot = this.cameraRotation[this.state.curIndex];

    this.camera = new MainCamera(70, this.width / this.height, 1, 3000);
    this.camera.delegate = this;
    this.camera.position.set(cPos.x, cPos.y, cPos.z);
    this.camera.rotation.set(cRot.x, cRot.y, cRot.z);

    this.camera.setDestinationPosition(cPos);
    this.camera.setDestinationRotation(this.camera.rotation);

    this.scene = new THREE.Scene();

    const bgTex = new THREE.TextureLoader().load(bgTexture);
    this.scene.background = bgTex;
    this.scene.fog = new THREE.FogExp2(0x090e14, 0.0017);

    this.renderer = new THREE.WebGLRenderer();
    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.renderer.setSize(this.width, this.height);
    this.renderer.autoClear = false;
    this.renderer.shadowMap.enabled = true;

    this.initPostprocessing();

    this.effectController = {
      focus: 2000.0,
      aperture: 4,
      maxblur: 0.02,
    };

    this.matChanger();
  };

  initLights = () => {
    const ambientLight = new THREE.AmbientLight();
    this.scene.add(ambientLight);

    const light = new THREE.DirectionalLight(0xffffff, 1);
    light.position.set(200, 800, 200);
    light.castShadow = true;
    light.shadow.camera.left = 200;
    light.shadow.camera.right = -200;
    light.shadow.camera.top = 300;
    light.shadow.camera.bottom = -400;
    this.scene.add(light);

    const lightB = new THREE.DirectionalLight(0xff0000, 1);
    lightB.position.set(-200, -800, -200);
    lightB.castShadow = true;
    lightB.shadow.camera.left = 100;
    lightB.shadow.camera.right = -100;
    lightB.shadow.camera.top = -100;
    lightB.shadow.camera.bottom = 100;
    this.scene.add(lightB);
  };

  createGeometry = () => {
    const loaderUrl = [
      possibilitiesModelUrl,
      cultureModelUrl,
      identityModelUrl,
      stigmaModelUrl,
      relationshipsModelUrl,
    ];

    const modelPos = [];
    const modelInitPos = [];
    const modelRot = [];
    const labelAnchor = [];

    // Offset from the final shard menu position to set them offscreen
    const iy = 500;

    // Destination shard postion once the menu is opened
    modelPos.push(new THREE.Vector3(10, -20, -120));
    modelPos.push(new THREE.Vector3(80, -50, 0));
    modelPos.push(new THREE.Vector3(170, -45, 80));
    modelPos.push(new THREE.Vector3(200, -40, 180));
    modelPos.push(new THREE.Vector3(15, -30, 210));

    // Shard position when the menu is closed
    modelInitPos.push(new THREE.Vector3(modelPos[0].x, modelPos[0].y - iy, modelPos[0].z));
    modelInitPos.push(new THREE.Vector3(modelPos[1].x, modelPos[1].y - iy, modelPos[1].z));
    modelInitPos.push(new THREE.Vector3(modelPos[2].x, modelPos[2].y - iy, modelPos[2].z));
    modelInitPos.push(new THREE.Vector3(modelPos[3].x, modelPos[3].y - iy, modelPos[3].z));
    modelInitPos.push(new THREE.Vector3(modelPos[4].x, modelPos[4].y - iy, modelPos[4].z));

    // Initial rotation of the shard model
    modelRot.push(
      new THREE.Vector3(degreesToRadians(-20), degreesToRadians(-20), degreesToRadians(0))
    );
    modelRot.push(
      new THREE.Vector3(degreesToRadians(-40), degreesToRadians(30), degreesToRadians(15))
    );
    modelRot.push(
      new THREE.Vector3(degreesToRadians(-20), degreesToRadians(-30), degreesToRadians(-10))
    );
    modelRot.push(
      new THREE.Vector3(degreesToRadians(-30), degreesToRadians(-200), degreesToRadians(25))
    );
    modelRot.push(
      new THREE.Vector3(degreesToRadians(-15), degreesToRadians(-30), degreesToRadians(-20))
    );

    // Sets the anchor label offset from the center of the shard in world space
    labelAnchor.push(new THREE.Vector2(-0.05, -0.05));
    labelAnchor.push(new THREE.Vector2(-0.05, 0));
    labelAnchor.push(new THREE.Vector2(0.05, -0.1));
    labelAnchor.push(new THREE.Vector2(-0.15, -0.05));
    labelAnchor.push(new THREE.Vector2(0.1, -0.1));

    // The rate at which each shard animates up from menu closed to open position
    const lerp = [60, 60, 70, 120, 150];

    this.shardModel = [];

    let tIndex = 0;

    for (let i = 0; i < loaderUrl.length; i += 1) {
      const inactiveTex = this.textures[tIndex];
      const activeTex = this.textures[tIndex + 1];
      const alphaMap = this.textures[this.textures.length - 1];

      const shard = new TopicShard(
        loaderUrl[i],
        inactiveTex,
        activeTex,
        alphaMap,
        this.state.curIndex > 0 ? modelPos[i] : modelInitPos[i],
        lerp[i]
      );
      shard.labelAnchor = labelAnchor[i];
      shard.delegate = this;
      this.shardModel.push(shard);
      shard.name = loaderUrl[i];

      shard.position.set(modelInitPos[i].x, modelInitPos[i].y, modelInitPos[i].z);
      shard.rotation.set(modelRot[i].x, modelRot[i].y, modelRot[i].z);

      shard.setInitPos(modelInitPos[i]);
      shard.setMenuDestPos(modelPos[i]);

      if (this.state.curIndex > 0) {
        shard.position.set(modelPos[i].x, modelPos[i].y, modelPos[i].z);
        shard.setInitPos(modelPos[i]);
      }

      this.scene.add(shard);

      tIndex += 2;
    }

    this.state.hasLoaded = true;

    // Open the menu after a certain amount of time
    if (this.state.curIndex <= 0) {
      this.window.setTimeout(this.onOpenMenu, 500);
    } else {
      this.configShardState(this.state.curIndex);
      this.delegate.handleResume(this.state.curIndex);
    }
  };

  matChanger = () => {
    this.postprocessing.bokeh.uniforms.focus.value = this.effectController.focus;
    this.postprocessing.bokeh.uniforms.aperture.value = this.effectController.aperture * 0.00001;
    this.postprocessing.bokeh.uniforms.maxblur.value = this.effectController.maxblur;
  };

  drawParticles = () => {
    // Containers to hold sets of particles
    this.particleGroup = [];

    const c = [0xe0a10b, 0xc73636, 0xef89f5, 0x2586ea];

    const numParticles = [200, 200, 200, 200];

    const minRad = [300, 300, 300, 300];

    const maxRad = [600, 600, 600, 600];

    const minY = [-500, -350, -500, -500];

    const maxY = [-150, -150, -200, -200];

    this.particleRotRate = [];

    for (let j = 0; j < c.length; j += 1) {
      const g = new ParticleGroup(c[j], numParticles[j], minRad[j], maxRad[j], minY[j], maxY[j]);
      this.particleGroup.push(g);
      this.scene.add(g);

      this.particleRotRate.push(getRandomIntInclusive(-5, 5) / 10000);
    }
  };

  initPostprocessing = () => {
    const renderPass = new RenderPass(this.scene, this.camera);

    const bokehPass = new BokehPass(this.scene, this.camera, {
      focus: 1.0,
      aperture: 0.025,
      maxblur: 0.01,

      width: this.width,
      height: this.height,
    });

    const composer = new EffectComposer(this.renderer);

    composer.addPass(renderPass);
    composer.addPass(bokehPass);

    this.postprocessing.composer = composer;
    this.postprocessing.bokeh = bokehPass;
  };

  configureMenuState = (val) => {
    this.state.curIndex = val;
    if (this.delegate != null) {
      this.delegate.handleCurIndexChange(val);
    }

    // Configure the camera position
    this.camera.setDestinationPosition(this.cameraPos[val]);

    // Configure the camera lookat
    this.camera.setDestinationRotation(this.cameraRotation[val]);
  };

  positionLabels = (index) => {
    if (this.shardLabels.current.length > 0) {
      const posVector = new THREE.Vector3();

      this.camera.updateMatrix();
      this.camera.updateMatrixWorld();
      this.camera.matrixWorldInverse.copy(this.camera.matrixWorld).invert();

      this.shardModel[index].updateMatrix();
      this.shardModel[index].updateMatrixWorld();

      // Get the position of the center of the model
      this.shardModel[index].updateWorldMatrix(true, false);
      this.shardModel[index].getWorldPosition(posVector);

      // Get the normalized screen coordinates
      // x and y will be in the -1 to 1 range
      posVector.project(this.camera);

      // Convert the normalized positions to CSS coordinates
      const x = (posVector.x * 0.5 + 0.5 + this.shardModel[index].labelAnchor.x) * this.width;
      const y = (posVector.y * -0.5 + 0.5 + this.shardModel[index].labelAnchor.y) * this.height;

      if (this.shardLabels.current[this.shardLabels.current.length - 1 - index] != null) {
        this.shardLabels.current[this.shardLabels.current.length - 1 - index].transform(x, y);
      }
    }
  };

  // Events
  onHandleWindowResize = () => {
    this.width = window.innerWidth;
    this.height = window.innerHeight;

    if (this.renderer) {
      this.renderer.setSize(this.width, this.height);
    }

    if (this.camera) {
      this.camera.aspect = this.width / this.height;
      this.camera.updateProjectionMatrix();
    }
  };

  onOpenMenu = (e) => {
    this.state.curIndex = ShardMenu.STATE_MENU_OPEN;

    for (let i = 0; i < this.shardModel.length; i += 1) {
      this.shardModel[i].setState(TopicShard.STATE_MAIN_MENU);
    }

    window.setTimeout(this.onShowUI, 3000);
  };

  onShowUI = () => {
    this.state.curIndex = ShardMenu.STATE_UI_VISIBLE;
    this.state.isInteractive = true;

    if (this.delegate != null) {
      this.delegate.handleInstructionsShow();
    }
  };

  // Called in Topic shard once the model has finished loading
  onLoadComplete = () => {
    this.totalAssetsLoaded += 1;

    const per = Math.round((this.totalAssetsLoaded / this.totalAssets) * 100);

    if (this.delegate != null) {
      this.delegate.handlePerLoaded(per);
    }
  };

  shardSelectCompleted = () => {
    if (this.delegate != null) {
      this.delegate.handleShardSelect(this.selectedTopic);
    }
  };

  animate = () => {
    if (this.scene != null) {
      requestAnimationFrame(this.animate, this.renderer.domElement);

      this.renderScene();
    }
  };

  renderScene = () => {
    if (this.state.hasLoaded) {
      for (let i = 0; i < this.shardModel.length; i += 1) {
        this.shardModel[i].animate();

        // Calculate distance from camera and pass it to each shard
        const dis = this.shardModel[i].position.distanceTo(this.camera.position);

        this.shardModel[i].distanceFromCam(dis);

        this.positionLabels(i);
      }
    }

    // Bg particles movement
    if (this.particleGroup != null) {
      for (let i = 0; i < this.particleGroup.length; i += 1) {
        this.particleGroup[i].rotation.y += this.particleRotRate[i];
      }
    }

    // Blur animation
    const blRate = 0.02;

    if (
      this.state.curIndex <= ShardMenu.STATE_UI_VISIBLE ||
      this.state.curIndex === ShardMenu.STATE_SHARD_SELECTED
    ) {
      this.blurLerp += blRate;

      if (this.blurLerp >= 1.0) {
        this.blurLerp = 1.0;
      }
    } else {
      this.blurLerp -= blRate;

      if (this.blurLerp < 0) {
        this.blurLerp = 0;
      }
    }

    this.effectController.focus = MathUtils.lerp(10, 2000, this.blurLerp);

    this.matChanger();

    // Camera animation
    this.camera.animate();

    if (this.postprocessing != null) {
      this.postprocessing.composer.render(0.1);
    }
  };

  // Setup the selecting of the shard, labels, camera, etc
  configShardState(index) {
    // Only animate to the specified topic if the camera is not already animating to one
    if (!this.camera.isAnimating) {
      // let labelsVisible = false;
      let labelRel = ShardLabel.STATE_LABEL_HIDDEN;
      let labelStig = ShardLabel.STATE_LABEL_INACTIVE;
      let labelIdent = ShardLabel.STATE_LABEL_INACTIVE;
      let labelCul = ShardLabel.STATE_LABEL_INACTIVE;
      let labelPoss = ShardLabel.STATE_LABEL_INACTIVE;

      // Check the labels to animate in
      if (this.state.isUIVisible === false) {
        this.state.isUIVisible = true;

        if (this.delegate != null) {
          this.delegate.handleUIVisible();
        }
      }

      switch (index) {
        case ShardMenu.STATE_POSSIBILITIES:
          labelPoss = ShardLabel.STATE_LABEL_VISIBLE;

          // Special case for Possibilities to hide the Relationships and Stigma labels due to overlap/depth issues
          labelRel = ShardLabel.STATE_LABEL_HIDDEN;
          labelStig = ShardLabel.STATE_LABEL_HIDDEN;
          labelIdent = ShardLabel.STATE_LABEL_HIDDEN;
          labelCul = ShardLabel.STATE_LABEL_HIDDEN;
          break;
        case ShardMenu.STATE_CULTURE:
          labelCul = ShardLabel.STATE_LABEL_VISIBLE;
          break;
        case ShardMenu.STATE_IDENTITY:
          labelIdent = ShardLabel.STATE_LABEL_VISIBLE;
          break;
        case ShardMenu.STATE_STIGMA:
          labelStig = ShardLabel.STATE_LABEL_VISIBLE;
          break;
        case ShardMenu.STATE_RELATIONSHIPS:
          labelRel = ShardLabel.STATE_LABEL_VISIBLE;

          // Special case for Relationships to hide the Possiblities and Culture labels due to overlap/depth issues
          labelPoss = ShardLabel.STATE_LABEL_HIDDEN;
          labelCul = ShardLabel.STATE_LABEL_HIDDEN;
          break;
        default:
          labelRel = ShardLabel.STATE_LABEL_HIDDEN;
          labelStig = ShardLabel.STATE_LABEL_INACTIVE;
          labelIdent = ShardLabel.STATE_LABEL_INACTIVE;
          labelCul = ShardLabel.STATE_LABEL_INACTIVE;
          labelPoss = ShardLabel.STATE_LABEL_INACTIVE;
          break;
      }

      if (index >= ShardMenu.STATE_RELATIONSHIPS && index <= ShardMenu.STATE_POSSIBILITIES) {
        this.state.labelState.relationships = labelRel;
        this.state.labelState.stigma = labelStig;
        this.state.labelState.identity = labelIdent;
        this.state.labelState.culture = labelCul;
        this.state.labelState.possibilities = labelPoss;

        if (this.delegate != null) {
          this.delegate.updateShardLabel(this.state.labelState);
        }

        this.isAnimating = true;
        this.configureMenuState(index);
      }
    }
  }

  nextShard() {
    const cur = clamp(
      this.state.curIndex + 1,
      ShardMenu.STATE_RELATIONSHIPS,
      ShardMenu.STATE_POSSIBILITIES
    );

    this.configShardState(cur);
  }

  prevShard() {
    const cur = clamp(
      this.state.curIndex - 1,
      ShardMenu.STATE_RELATIONSHIPS,
      ShardMenu.STATE_POSSIBILITIES
    );

    this.configShardState(cur);
  }

  destroy() {
    this.htmlRef = null;

    this.camera.delegate = null;
    this.camera = null;
    this.scene = null;
    this.renderer = null;

    this.window = null;
    this.shardLabels = null;
    this.textureUrls = null;
    this.postprocessing = null;
    this.delegate = null;

    for (let i = 0; i < this.textures.length; i += 1) {
      this.textures[i].dispose();
    }
    this.textures = null;

    for (let i = 0; i < this.shardModel.length; i += 1) {
      this.shardModel[i].destroy();
      this.shardModel[i] = null;
    }
    this.shardModel = null;

    window.removeEventListener('resize', this.onHandleWindowResize);
  }

  // Value Initializations
  initCameraPos = () => {
    this.cameraPos = [
      new THREE.Vector3(100, 100, 300), // Menu Init
      new THREE.Vector3(100, 100, 300), // Menu visible
      new THREE.Vector3(100, 100, 300), // UI visible
      new THREE.Vector3(15, 20, 290), // Relationship shard
      new THREE.Vector3(180, 20, 240), // Stigma shard
      new THREE.Vector3(140, 5, 150), // Identity shard
      new THREE.Vector3(80, -15, 75), // Culture shard
      new THREE.Vector3(10, -20, -20), // Possibilities shard
    ];

    this.cameraRotation = [
      new THREE.Vector3(degreesToRadians(-17), 0, 0), // Menu Init
      new THREE.Vector3(degreesToRadians(-17), 0, 0), // Menu visible
      new THREE.Vector3(degreesToRadians(-17), 0, 0), // UI visible
      new THREE.Vector3(degreesToRadians(-30), 0, 0), // Relationship shard
      new THREE.Vector3(degreesToRadians(-40), degreesToRadians(-15), 0), // Stigma shard
      new THREE.Vector3(degreesToRadians(-30), degreesToRadians(-20), 0), // Identity shard
      new THREE.Vector3(degreesToRadians(-23), degreesToRadians(0), 0), // Culture shard
      new THREE.Vector3(degreesToRadians(0), degreesToRadians(0), degreesToRadians(0)), // Possibilities shard
    ];
  };
}

export default ShardMenu;
