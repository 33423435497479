import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';
import { useLocation, useHistory, Route, Switch } from 'react-router-dom';

import Particles from '../shared/Particles';
import Login from '../auth/login/Login';
import Register from '../auth/register/Register';
import ForgotPassword from '../auth/passwordReset/ForgotPassword';
import ResetPassword from '../auth/passwordReset/ResetPassword';
import IntroContainer from './introContainer';
import NoContent from '../content/NoContent';

const Landing = ({ setHideMenu }) => {
  const [showLogin, setShowLogin] = useState(false);
  const [showRegister, setShowRegister] = useState(false);
  const [showForgot, setShowForgot] = useState(false);
  const [showReset, setShowReset] = useState(false);
  const [showIntro, setShowIntro] = useState(false);
  const [authParam, setAuthParam] = useState();
  const [showNoContent, setShowNoContent] = useState(false);
  const [showParticles, setShowParticles] = useState(true);

  const location = useLocation();

  useEffect(() => {
    const urlParam = location.pathname;
    setAuthParam(urlParam);
  });

  useEffect(() => {
    if (authParam) {
      if (authParam === '/login') {
        setShowLogin(true);
        setShowRegister(false);
        setShowForgot(false);
        setShowReset(false);
        setShowIntro(false);
        setShowNoContent(false);
        if (showParticles === false) setShowParticles(true);
      } else if (authParam === '/register') {
        setShowLogin(false);
        setShowRegister(true);
        setShowForgot(false);
        setShowReset(false);
        setShowIntro(false);
        setShowNoContent(false);
        if (showParticles === false) setShowParticles(true);
      } else if (authParam === '/forgot-password') {
        setShowLogin(false);
        setShowRegister(false);
        setShowForgot(true);
        setShowReset(false);
        setShowIntro(false);
        setShowNoContent(false);
        if (showParticles === false) setShowParticles(true);
      } else if (authParam === '/') {
        setShowLogin(false);
        setShowRegister(false);
        setShowForgot(false);
        setShowReset(false);
        setShowIntro(true);
        setShowNoContent(false);
        if (showParticles === false) setShowParticles(true);
      } else if (authParam === '/reset-password') {
        setShowLogin(false);
        setShowRegister(false);
        setShowForgot(false);
        setShowReset(true);
        setShowIntro(false);
        setShowNoContent(false);
        if (showParticles === false) setShowParticles(true);
      } else {
        // Any other URL hide everything. If not the content will appear below the main page causing scrolling/overflow issues
        setShowLogin(false);
        setShowRegister(false);
        setShowForgot(false);
        setShowReset(false);
        setShowIntro(false);
        setShowParticles(false);
        setShowNoContent(true);
      }
    }
  }, [authParam]);

  return (
    <>
      <CSSTransition in={showLogin} timeout={300} classNames="intro-component-ani" unmountOnExit>
        <Login />
      </CSSTransition>
      <CSSTransition in={showRegister} timeout={300} classNames="intro-component-ani" unmountOnExit>
        <Register />
      </CSSTransition>
      <CSSTransition in={showForgot} timeout={300} classNames="intro-component-ani" unmountOnExit>
        <ForgotPassword />
      </CSSTransition>
      <CSSTransition in={showIntro} timeout={300} classNames="intro-component-ani" unmountOnExit>
        <IntroContainer />
      </CSSTransition>
      <CSSTransition in={showReset} timeout={300} classNames="intro-component-ani" unmountOnExit>
        <ResetPassword />
      </CSSTransition>
      <CSSTransition
        in={showNoContent}
        timeout={300}
        classNames="intro-component-ani"
        unmountOnExit
      >
        <NoContent setHideMenu={setHideMenu} />
      </CSSTransition>
      {showParticles && <Particles />}
    </>
  );
};

Landing.propTypes = {
  setHideMenu: PropTypes.func.isRequired,
};

export default Landing;
