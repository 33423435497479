import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';

import LearningModuleControls from '../LearningModuleControls';

const AudioModule = ({ module, inViewport, checkModuleCompletion, auth, targetModule }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [showInsructions, setShowInstructions] = useState(false);
  const [target, setTarget] = useState();
  const [hasPlayed, setHasPlayed] = useState(false);

  useEffect(() => {
    if (targetModule !== null && targetModule !== undefined) {
      if (targetModule.id === module.uid) {
        setTarget(targetModule);
      }
    }
  }, [targetModule]);

  const audioRef = useRef();

  useEffect(() => {
    if (audioRef.current && inViewport) {
      const promise = audioRef.current.play();
      promise
        .then(() => {
          if (auth.audio.isMuted) {
            audioRef.current.muted = true;
          } else {
            audioRef.current.muted = false;
          }
          setIsPlaying(true);
          setHasPlayed(true);
          checkModuleCompletion(null, target);
        })
        .catch(() => {
          setShowInstructions(true);
        });
    } else if (audioRef.current && !inViewport) {
      audioRef.current.pause();
      setIsPlaying(false);
    }
    if (hasPlayed) {
      checkModuleCompletion(null, target);
    }
  }, [inViewport]);

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.addEventListener('ended', () => setIsPlaying(false));
    }
  }, [audioRef]);

  const handlePlay = () => {
    if (isPlaying) {
      audioRef.current.pause();
      setIsPlaying(false);
      checkModuleCompletion(null, target);
    } else {
      if (auth.audio.isMuted) {
        audioRef.current.muted = true;
      } else {
        audioRef.current.muted = false;
      }
      audioRef.current.play();
      setIsPlaying(true);
      checkModuleCompletion(null, target);
    }
  };

  const handleRestart = () => {
    if (isPlaying) {
      audioRef.current.pause();
      setIsPlaying(false);
      audioRef.current.currentTime = 0;
      audioRef.current.play();
      setIsPlaying(true);
    } else {
      if (auth.audio.isMuted) {
        audioRef.current.muted = true;
      } else {
        audioRef.current.muted = false;
      }
      audioRef.current.currentTime = 0;
      audioRef.current.play();
      setIsPlaying(true);
    }
  };

  useEffect(() => {
    if (auth.audio.isMuted) {
      audioRef.current.muted = true;
    } else {
      audioRef.current.muted = false;
    }
  }, [auth.audio]);

  return (
    <div className="module-wrapper">
      <CSSTransition in={inViewport} timeout={1000} classNames="learning-module-ani">
        <div className="audio-wrapper">
          <h2 className="audio-title">{module.module_type[0].title}</h2>
          {/* <CSSTransition
            in={showInsructions}
            timeout={1000}
            classNames="learning-module-ani"
            unmountOnExit
          >
            <p className='audio-instructions'>
              Autoplay has been prevented by your browser, please press the play button to listen to the audio
            </p>
          </CSSTransition> */}
          <audio className="audio-element" id={`${module.uid}}-audio`} ref={audioRef}>
            <source src={`${process.env.REACT_APP_API_URL}${module.module_type[0].audio.url}`} />
            <track default kind="captions" srcLang="en" src="#" />
          </audio>
          <LearningModuleControls
            module={module}
            isPlaying={isPlaying}
            handlePlay={handlePlay}
            handleRestart={handleRestart}
          />
        </div>
      </CSSTransition>
    </div>
  );
};

AudioModule.propTypes = {
  module: PropTypes.objectOf(PropTypes.any).isRequired,
  inViewport: PropTypes.bool.isRequired,
  checkModuleCompletion: PropTypes.func.isRequired,
  auth: PropTypes.objectOf(PropTypes.any).isRequired,
  targetModule: PropTypes.any,
};

AudioModule.defaultProps = {
  targetModule: null,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(AudioModule);
