import React from 'react';
import { Link } from 'react-router-dom';
import Particles from '../../shared/Particles';

const LoginOptions = () => {
  return (
    <>
      <div className="login-options">
        {/* <div className="segment-container">
          <h1>welcome</h1>
        </div> */}
        <div className="segment-containers content">
          <div className="guest">
            <h2>Continue as Guest</h2>
            <p>
              Account features will be disabled including progress tracking and settings.
              You&apos;ll have the option to create an account at any time.
            </p>
            <Link to="/dashboard" className="btn-links">
              <button className="btn btn-lg btn-secondary continue-btn" type="button">
                Continue
              </button>
            </Link>
          </div>
          <div className="between">
            <p>or</p>
          </div>
          <div className="account">
            <h2>Create an Account</h2>
            <p>
              Create an account to save and continue your progress at a later date. Don&apos;t
              worry, your data isn&apos;t shared with anyone.
            </p>
            <Link to="/register" className="btn-links">
              <button className="btn btn-lg btn-secondary btn-signup" type="button">
                Sign up
              </button>
            </Link>
          </div>
        </div>
        {/* <Particles section="intro" /> */}
      </div>
    </>
  );
};

export default LoginOptions;
