import React, { Component } from 'react';
import * as THREE from 'three';
import { connect } from 'react-redux';
import { flowRight as compose } from 'lodash';
import { withRouter } from 'react-router-dom';

import PropTypes from 'prop-types';

// Post processing
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass';
import { BokehPass } from 'three/examples/jsm/postprocessing/BokehPass';

// Custom Objects
import SubMenuTopNav from './SubMenuTopNav';
import SubMenuLabel from './SubMenuLabel';
import SubMenuIntro from './SubMenuIntro';
import SubMenuBGModel from './SubMenuBGModel';
import SubMenuFGModel from './SubMenuFGModel';
import SubMenuTopicModel from './SubMenuTopicModel';
import ParentCompleted from '../../parentTopics/parentCompleted/ParentCompleted';

import {
  getRandomIntInclusive,
  isSmallViewport,
  clamp,
  sortChildTopics,
} from '../../../utils/TopicBrowserUtils';

import { loadAllParents } from '../../../../actions/activeModule';

// Assets
import relationshipsBgTexture from '../../../../assets/textures/bg-relationships-texture.jpg';
import stigmaBgTexture from '../../../../assets/textures/bg-stigma-texture.jpg';
import identityBgTexture from '../../../../assets/textures/bg-identity-texture.jpg';
import cultureBgTexture from '../../../../assets/textures/bg-culture-texture.jpg';
import possibilitiesBgTexture from '../../../../assets/textures/bg-possibilities-texture.jpg';

class ChildMenu extends Component {
  static TOPIC_RELATIONSHIPS = 0;

  static TOPIC_STIGMA = 1;

  static TOPIC_IDENTITY = 2;

  static TOPIC_CULTURE = 3;

  static TOPIC_POSSIBILITIES = 4;

  static STATE_INIT = 0;

  static STATE_LOAD_COMPLETE = 1;

  static STATE_MENU_OPEN = 2;

  static STATE_UI_VISIBLE = 3;

  static TOPIC_Z_DEPTH = 70;

  constructor(props) {
    super(props);

    this.subTopicLabels = [];

    this.fgModels = [];
    this.totalFgModelsLoaded = 0;
    this.totalFgModels = 10;

    this.bgModels = [];
    this.totalBgModelsLoaded = 0;
    this.totalBgModels = 10;

    this.topicModels = [];
    this.totalTopicModelsLoaded = 0;
    this.totalTopicModels = 0; // This is dependent on the number of modules in strapi

    this.texturesLoaded = 0;
    this.totalAssetsLoaded = 0;
    this.totalAssets = this.totalBgModels + this.totalFgModels + this.totalTopicModels;

    this.resizeTimer = null;
    this.shardAnimateInTimer = [];

    this.state = {
      curIndex: 0,
      loadCompletion: 0,
      hasLoaded: false,
      isNavVisible: false,
      isLabelsVisible: false,
      labelSideFlags: [],
      isCompleted: false,
      currentModule: {},
      nextModule: {},
    };
  }

  componentDidMount() {
    const { topic } = this.props;
    const { progressTracker } = this.props;
    const { activeModule } = this.props;

    const currentIndex = activeModule.parentTopics.findIndex((parent) => parent.id === topic.id);

    let nextIndex;
    if (currentIndex + 1 < activeModule.parentTopics.length) {
      nextIndex = currentIndex + 1;
    } else {
      nextIndex = 0;
    }

    const current = progressTracker.completedModules.filter((module) => module.id === topic.id);
    this.setState((prevState) => ({
      ...prevState,
      isCompleted: current.completed,
      currentModule: topic,
      nextModule: activeModule.parentTopics[nextIndex],
    }));

    this.totalTopicModels = topic.child_topics.length;
    this.totalAssets = this.totalBgModels + this.totalFgModels + this.totalTopicModels;

    // Set the parent visited local storage variable to the current parent topic
    localStorage.setItem('parentVisited', topic.id);

    this.width = window.innerWidth;
    this.height = window.innerHeight;

    this.postprocessing = {};

    this.init();
    this.initLights();
    this.createBGModels();
    this.animate();

    window.addEventListener('resize', this.onHandleWindowResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onHandleWindowResize);

    // Dispose of everything
    this.postprocessing = null;

    this.camera = null;
    this.scene.background = new THREE.Color(0xffffff);
    this.scene = null;
    this.renderer = null;

    for (let i = 0; i < this.bgModels.length; i += 1) {
      this.bgModels[i].destroy();
      this.bgModels[i] = null;
    }
    this.bgModels = null;

    for (let i = 0; i < this.fgModels.length; i += 1) {
      this.fgModels[i].destroy();
      this.fgModels[i] = null;
    }
    this.fgModels = null;

    for (let i = 0; i < this.topicModels.length; i += 1) {
      this.topicModels[i].destroy();
      this.topicModels[i] = null;
    }
    this.fgModels = null;

    if (this.resizeTimer != null) {
      clearTimeout(this.resizeTimer);
    }

    if (this.shardAnimateInTimer.length > 0) {
      for (let i = 0; i < this.shardAnimateInTimer.length; i += 1) {
        clearTimeout(this.shardAnimateInTimer[i]);
      }
    }
  }

  init = () => {
    const { topic } = this.props;

    this.camera = new THREE.PerspectiveCamera(70, this.width / this.height, 1, 3000);

    this.camera.position.set(0, 0, 200);
    this.camera.lookAt(new THREE.Vector3());

    this.scene = new THREE.Scene();

    let bg = null;

    switch (topic.UID) {
      case 'relationships':
        bg = relationshipsBgTexture;
        break;
      case 'stigma':
        bg = stigmaBgTexture;
        break;
      case 'identity':
        bg = identityBgTexture;
        break;
      case 'current-culture-of-dementia-care':
        bg = cultureBgTexture;
        break;
      case 'possibilities':
        bg = possibilitiesBgTexture;
        break;
      default:
        bg = relationshipsBgTexture;
        break;
    }

    const bgTex = new THREE.TextureLoader().load(bg);
    this.scene.background = bgTex;
    this.scene.fog = new THREE.FogExp2(0x090e14, 0.0017);

    this.renderer = new THREE.WebGLRenderer({ alpha: true });
    this.renderer.setClearColor(0xff0000, 0);
    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.renderer.setSize(this.width, this.height);
    this.renderer.shadowMap.enabled = true;

    this.el.appendChild(this.renderer.domElement);

    this.initPostprocessing();

    this.effectController = {
      focus: 67.0,
      aperture: 4.0,
      maxblur: 0.01,
    };

    this.matChanger();
  };

  matChanger = () => {
    this.postprocessing.bokeh.uniforms.focus.value = this.effectController.focus;
    this.postprocessing.bokeh.uniforms.aperture.value = this.effectController.aperture * 0.00001;
    this.postprocessing.bokeh.uniforms.maxblur.value = this.effectController.maxblur;
  };

  initLights = () => {
    const ambientLight = new THREE.AmbientLight();
    this.scene.add(ambientLight);

    const light = new THREE.DirectionalLight(0xffffff, 1);
    light.position.set(200, 800, 200);
    light.castShadow = true;
    light.shadow.camera.left = 200;
    light.shadow.camera.right = -200;
    light.shadow.camera.top = 300;
    light.shadow.camera.bottom = -400;
    this.scene.add(light);
  };

  createBGModels = () => {
    this.bgGroup = new THREE.Group();

    const xbounds = 250;
    const ybounds = 200;

    for (let i = 0; i < this.totalBgModels; i += 1) {
      const xpos = getRandomIntInclusive(-xbounds, xbounds);
      const ypos = getRandomIntInclusive(-ybounds, ybounds);
      const zpos = getRandomIntInclusive(-100, -300);
      const pos = new THREE.Vector3(xpos, ypos, zpos);

      const bgModel = new SubMenuBGModel();
      bgModel.position.set(xpos, ypos, zpos);
      bgModel.delegate = this;
      this.bgGroup.add(bgModel);

      this.bgModels.push(bgModel);
    }

    this.scene.add(this.bgGroup);
  };

  createFGModels = () => {
    const xbounds = 250;
    const ybounds = 200;

    const { topic } = this.props;

    for (let i = 0; i < this.totalBgModels; i += 1) {
      const xpos = getRandomIntInclusive(-xbounds, xbounds);
      const ypos = getRandomIntInclusive(-ybounds, ybounds);
      const zpos = getRandomIntInclusive(-100, -300);
      const pos = new THREE.Vector3(xpos - 800, ypos, zpos);
      const dpos = new THREE.Vector3(xpos, ypos, zpos);

      const fgModel = new SubMenuFGModel(topic.UID);
      fgModel.position.set(pos.x, pos.y, pos.z);
      fgModel.delegate = this;
      this.scene.add(fgModel);

      fgModel.setInitPos(pos);
      fgModel.setMenuDestPos(dpos);

      this.fgModels.push(fgModel);
    }
  };

  createTopicModels = () => {
    const { topic } = this.props;
    const len = this.totalTopicModels;

    const w = this.visibleWidthAtZDepth(ChildMenu.TOPIC_Z_DEPTH, this.camera) * 0.7;
    const minX = (w / 2) * -1;

    const minY = -80;
    const maxY = 90;
    const h = maxY - minY;

    const startX = minX;
    const startY = maxY;

    let maxItemsInRow = len;

    let xSpacing = this.calcTopicShardSpacing(w, maxItemsInRow);

    const spacingThreshold = 43;

    let iterations = 0;

    if (xSpacing < spacingThreshold) {
      while (xSpacing < spacingThreshold && iterations <= 3) {
        maxItemsInRow = clamp(Math.round(maxItemsInRow * 0.7), 2, 10);
        xSpacing = this.calcTopicShardSpacing(w, maxItemsInRow);
        iterations += 1;
      }
    }

    if (xSpacing < 25) {
      xSpacing = 25;
    }

    // Find out how many rows we need
    let rows = Math.floor(len / maxItemsInRow);
    const remainder = len % maxItemsInRow;

    if (remainder > 0) {
      rows += 1;
    }

    const ySpacing = h / (rows + 1);

    const ybounds = 10;

    let colCount = 0;
    let rowCount = 0;
    let spacing = xSpacing;
    let anchorMod = 1;

    const sideFlags = [];

    for (let i = 0; i < len; i += 1) {
      const xVal = startX + spacing * (maxItemsInRow < 3 ? colCount + 1 : colCount);
      const yVal = startY - ySpacing * (rowCount + 1);

      colCount += 1;

      if (colCount >= maxItemsInRow) {
        const remainingItems = len - colCount;
        if (remainingItems < maxItemsInRow) {
          maxItemsInRow = remainingItems;
          spacing = this.calcTopicShardSpacing(w, remainingItems);
        }
        colCount = 0;
        rowCount += 1;
        sideFlags.push(i);
      }

      const xpos = xVal;
      const ypos = yVal; // getRandomIntInclusive(yVal - ybounds, yVal + ybounds);
      const zpos = ChildMenu.TOPIC_Z_DEPTH;

      const pos = new THREE.Vector3(xpos, -200, zpos);
      const dpos = new THREE.Vector3(xpos, ypos, zpos);

      const anchor = new THREE.Vector3(0, 0.05 * anchorMod, 0);

      anchorMod *= -1;

      const topicModel = new SubMenuTopicModel(i, topic.UID);
      topicModel.labelAnchor = anchor;
      topicModel.position.set(pos.x, pos.y, pos.z);
      topicModel.delegate = this;
      this.scene.add(topicModel);

      topicModel.setInitPos(pos);
      topicModel.setMenuDestPos(dpos);

      this.topicModels.push(topicModel);
    }

    this.setState({
      labelSideFlags: sideFlags,
    });
  };

  calcTopicShardSpacing = (width, numShardsInRow) => {
    if (numShardsInRow < 3) {
      return width / (numShardsInRow + 1);
    }
    return width / (numShardsInRow - 1);
  };

  initPostprocessing = () => {
    const renderPass = new RenderPass(this.scene, this.camera);

    const bokehPass = new BokehPass(this.scene, this.camera, {
      focus: 200.0,
      aperture: 0.025,
      maxblur: 0.01,

      width: this.width,
      height: this.height,
    });

    const composer = new EffectComposer(this.renderer);

    composer.addPass(renderPass);
    composer.addPass(bokehPass);

    this.postprocessing.composer = composer;
    this.postprocessing.bokeh = bokehPass;
  };

  positionLabels = (index) => {
    const posVector = new THREE.Vector3();

    this.camera.updateMatrix();
    this.camera.updateMatrixWorld();
    this.camera.matrixWorldInverse.copy(this.camera.matrixWorld).invert();

    this.topicModels[index].updateMatrix();
    this.topicModels[index].updateMatrixWorld();

    // Get the position of the center of the model
    this.topicModels[index].updateWorldMatrix(true, false);
    this.topicModels[index].getWorldPosition(posVector);

    // Get the normalized screen coordinates
    // x and y will be in the -1 to 1 range
    posVector.project(this.camera);

    // Convert the normalized positions to CSS coordinates
    const x = (posVector.x * 0.5 + 0.5 + this.topicModels[index].labelAnchor.x) * this.width;
    const y = (posVector.y * -0.5 + 0.5 + this.topicModels[index].labelAnchor.y) * this.height;

    if (this.subTopicLabels[index] != null) {
      this.subTopicLabels[index].transform(x, y);
    }
  };

  calcShardPositions = () => {
    if (!isSmallViewport()) {
      const sideFlags = [];

      const len = this.totalTopicModels;

      const w = this.visibleWidthAtZDepth(ChildMenu.TOPIC_Z_DEPTH, this.camera) * 0.7;
      const minX = (w / 2) * -1;

      const minY = -80;
      const maxY = 90;
      const h = maxY - minY;

      const startX = minX;
      const startY = maxY;

      let maxItemsInRow = len;

      let xSpacing = this.calcTopicShardSpacing(w, maxItemsInRow);

      const spacingThreshold = 43;

      let iterations = 0;

      if (xSpacing < spacingThreshold) {
        while (xSpacing < spacingThreshold && iterations <= 3) {
          maxItemsInRow = clamp(Math.round(maxItemsInRow * 0.7), 2, 10);
          xSpacing = this.calcTopicShardSpacing(w, maxItemsInRow);
          iterations += 1;
        }
      }

      if (xSpacing < 25) {
        xSpacing = 25;
      }

      // Find out how many rows we need
      let rows = Math.floor(len / maxItemsInRow);
      const remainder = len % maxItemsInRow;

      if (remainder > 0) {
        rows += 1;
      }

      const ySpacing = h / (rows + 1);

      const ybounds = 10;

      let colCount = 0;
      let rowCount = 0;
      let spacing = xSpacing;

      for (let i = 0; i < len; i += 1) {
        const xVal = startX + spacing * (maxItemsInRow < 3 ? colCount + 1 : colCount);
        const yVal = startY - ySpacing * (rowCount + 1);

        colCount += 1;

        if (colCount >= maxItemsInRow) {
          const remainingItems = len - colCount;
          if (remainingItems < maxItemsInRow) {
            maxItemsInRow = remainingItems;
            spacing = this.calcTopicShardSpacing(w, remainingItems);
          }
          colCount = 0;
          rowCount += 1;
          sideFlags.push(i);
        }

        const xpos = xVal;
        const ypos = yVal;
        const zpos = ChildMenu.TOPIC_Z_DEPTH;

        const pos = new THREE.Vector3(xpos, -200, zpos);
        const dpos = new THREE.Vector3(xpos, ypos, zpos);

        if (this.topicModels[i] != null) {
          this.topicModels[i].setMenuDestPos(dpos);
        }
      }

      this.setState({
        labelSideFlags: sideFlags,
      });
    }
  };

  visibleHeightAtZDepth = (depth, camera) => {
    let d = depth;
    // compensate for cameras not positioned at z=0
    const cameraOffset = camera.position.z;
    if (d < cameraOffset) d -= cameraOffset;
    else d += cameraOffset;

    // vertical fov in radians
    const vFOV = (camera.fov * Math.PI) / 180;

    // Math.abs to ensure the result is always positive
    return 2 * Math.tan(vFOV / 2) * Math.abs(d);
  };

  visibleWidthAtZDepth = (depth, camera) => {
    const height = this.visibleHeightAtZDepth(depth, camera);
    return height * camera.aspect;
  };

  // Utility function to stagger the sub topic shard animation
  flagTopicShardAnimate = (index) => {
    if (this.topicModels[index]) {
      this.topicModels[index].animateIn();
    }
  };

  flagNavIn = () => {
    this.setState({
      isNavVisible: true,
    });

    window.setTimeout(this.flagLabelsIn, 1000);
  };

  flagLabelsIn = () => {
    this.setState({
      isLabelsVisible: true,
    });
  };

  animate = () => {
    if (this.scene != null) {
      requestAnimationFrame(this.animate, this.renderer.domElement);

      this.renderScene();
    }
  };

  renderScene = () => {
    if (this.postprocessing != null) {
      this.postprocessing.composer.render(0.1);
    }

    if (this.bgModels != null) {
      if (this.totalBgModelsLoaded >= this.totalBgModels) {
        for (let i = 0; i < this.totalBgModels; i += 1) {
          this.bgModels[i].animate();
        }
      }
    }

    if (this.fgModels != null) {
      if (this.totalFgModelsLoaded >= this.totalFgModels) {
        for (let i = 0; i < this.totalFgModels; i += 1) {
          this.fgModels[i].animate();
        }
      }
    }

    if (this.topicModels != null) {
      if (this.totalTopicModelsLoaded >= this.totalTopicModels) {
        for (let i = 0; i < this.totalTopicModels; i += 1) {
          this.topicModels[i].animate();

          this.positionLabels(i);
        }
      }
    }
  };

  /** *******************
   * Event handlers START
   ********************* */
  onHandleWindowResize = () => {
    this.width = window.innerWidth;
    this.height = window.innerHeight;

    this.renderer.setSize(this.width, this.height);
    this.camera.aspect = this.width / this.height;
    this.camera.updateProjectionMatrix();

    if (this.resizeTimer != null) {
      clearTimeout(this.resizeTimer);
    }

    this.resizeTimer = setTimeout(this.calcShardPositions, 500);
  };

  onBGModelLoadComplete = () => {
    this.totalBgModelsLoaded += 1;
    this.totalAssetsLoaded += 1;

    const per = Math.round((this.totalAssetsLoaded / this.totalAssets) * 100);
    this.setState({
      loadCompletion: per,
    });

    if (this.totalBgModelsLoaded >= this.totalBgModels) {
      this.createFGModels();
    }
  };

  onFGModelLoadComplete = () => {
    this.totalFgModelsLoaded += 1;
    this.totalAssetsLoaded += 1;

    const per = Math.round((this.totalAssetsLoaded / this.totalAssets) * 100);
    this.setState({
      loadCompletion: per,
    });

    if (this.totalFgModelsLoaded >= this.totalFgModels) {
      // TODO animate the fg models from left to right
      this.createTopicModels();
    }
  };

  onTopicModelLoadComplete = () => {
    this.totalTopicModelsLoaded += 1;
    this.totalAssetsLoaded += 1;

    const per = Math.round((this.totalAssetsLoaded / this.totalAssets) * 100);
    this.setState({
      loadCompletion: per,
    });

    if (this.totalTopicModelsLoaded >= this.totalTopicModels) {
      this.setState({
        hasLoaded: true,
      });
    }
  };

  handleSubMenuContinueClick = () => {
    this.setState({
      curIndex: ChildMenu.STATE_MENU_OPEN,
    });

    this.shardAnimateInTimer = [];

    for (let i = 0; i < this.totalTopicModels; i += 1) {
      this.shardAnimateInTimer[i] = window.setTimeout(this.flagTopicShardAnimate, 200 * i, i);

      if (i >= this.totalTopicModels - 1) {
        this.shardAnimateInTimer[i] = window.setTimeout(this.flagNavIn, 200 * (i + 1));
      }
    }
  };

  handleLabelClick = (e) => {
    const uid = e.currentTarget.getAttribute('uid');
    const { topic } = this.props;

    // eslint-disable-next-line react/prop-types
    this.props.history.push({
      pathname: `/content/${topic.UID}/${uid}`,
    });
  };

  /** *******************
   * Event Handlers END
   ********************* */

  render() {
    const {
      curIndex,
      loadCompletion,
      hasLoaded,
      isNavVisible,
      isLabelsVisible,
      labelSideFlags,
      nextModule,
    } = this.state;
    const { topic, progress } = this.props;
    const { activeModule } = this.props;
    const { isCompleted } = this.state;
    let isIntroVisible = true;

    if (curIndex === ChildMenu.STATE_MENU_OPEN) {
      isIntroVisible = false;
    }

    // Get number of completed modules
    let completedModules = 0;
    const topicID = topic.id;
    let activeProgress = null;
    progress.forEach((element) => {
      const { id } = element;
      const elementId = id;

      if (elementId === topicID) {
        activeProgress = element;
      }
    });

    if (activeProgress == null) {
      return;
    }
    activeProgress.child_topics.forEach((element) => {
      const { completed } = element;

      if (completed) {
        completedModules += 1;
      }
    });

    const labels = sortChildTopics(topic.child_topics).map((l, i) => {
      let comp = false;
      let s = SubMenuLabel.STATE_LABEL_HIDDEN;
      if (isLabelsVisible) {
        s = SubMenuLabel.STATE_LABEL_VISIBLE;
      }

      // check if the module is completed
      activeProgress.child_topics.forEach((element) => {
        const { id, completed } = element;
        if (id === l.id && completed) {
          comp = true;
        }
      });

      let side = 0;

      labelSideFlags.forEach((element) => {
        if (i === element) {
          side = 1;
        }
      });

      return (
        <SubMenuLabel
          key={l.name}
          ref={(SubTopicLabel) => {
            this.subTopicLabels[i] = SubTopicLabel;
          }}
          labelSide={side}
          label={l.name}
          labelState={s}
          isCompleted={comp}
          onLabelClick={this.handleLabelClick}
          uid={l.uid}
          tabIndex={i + 1}
        />
      );
    });

    return (
      <div
        className="sub-universe"
        ref={(ref) => {
          this.el = ref;
        }}
      >
        <div className={['child-menu-overlay', isNavVisible ? 'hide' : ''].join(' ')} />
        {isCompleted ? (
          <ParentCompleted topic={topic} nextModule={nextModule} />
        ) : (
          <>
            <SubMenuTopNav
              topicName={topic.name}
              topicUid={topic.UID}
              completed={completedModules}
              max={topic.child_topics.length}
              isVisible={isNavVisible}
            />
            <SubMenuIntro
              percentLoaded={loadCompletion}
              hasLoaded={hasLoaded}
              topic={topic}
              isVisible={isIntroVisible}
              onSubMenuContinueClick={this.handleSubMenuContinueClick}
            />
            <div className="sub-topic-labels">{labels}</div>
          </>
        )}
      </div>
    );
  }
}

ChildMenu.propTypes = {
  topic: PropTypes.objectOf(PropTypes.any).isRequired,
  progress: PropTypes.arrayOf(PropTypes.any).isRequired,
  progressTracker: PropTypes.objectOf(PropTypes.any).isRequired,
  activeModule: PropTypes.objectOf(PropTypes.any).isRequired,
};

const mapStateToProps = (state) => ({
  completedModules: state,
  progress: state.progressTracker.completedModules,
  progressTracker: state.progressTracker,
  activeModule: state.activeModule,
});

export default compose(
  withRouter,
  connect(mapStateToProps, { dispatchLoadParents: loadAllParents }, null, {})
)(ChildMenu);
