import React from 'react';
import PropTypes from 'prop-types';
import MobileTopicCarouselItem from './MobileTopicCarouselItem';

const MobileTopicCarousel = ({ isVisible, topics, progress, selectedTopic }) => {
  return (
    <div className={['mobile-topic-carousel', isVisible ? 'visible' : ''].join(' ')}>
      <div className={['mobile-topic-carousel-container', `index-${selectedTopic}`].join(' ')}>
        {topics.map((t, i) => {
          let selected = false;
          if (i === selectedTopic) {
            selected = true;
          }

          const p = progress.find((o) => o.id === t.id);

          return (
            <MobileTopicCarouselItem
              key={t.UID}
              topic={t}
              progress={p}
              isSelected={selected}
              tabIndex={i + 1}
            />
          );
        })}
      </div>
    </div>
  );
};

MobileTopicCarousel.propTypes = {
  topics: PropTypes.arrayOf(PropTypes.any).isRequired,
  progress: PropTypes.arrayOf(PropTypes.any).isRequired,
  isVisible: PropTypes.bool.isRequired,
  selectedTopic: PropTypes.number.isRequired,
};

export default MobileTopicCarousel;
