import React from 'react';
import PropTypes from 'prop-types';

const ScrollInstructions = ({ isVisible }) => {
  let visible = '';
  if (isVisible) {
    visible = 'visible';
  }

  return (
    <div className={['scroll-instructions', visible].join(' ')}>
      <figure className="scroll-image" />
      <p>Scroll to explore</p>
    </div>
  );
};

ScrollInstructions.propTypes = {
  isVisible: PropTypes.bool.isRequired,
};

export default ScrollInstructions;
