import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

const BandwidthTest = ({ setShowBandwidthWarning }) => {
  const [bandwidth, setBandwidth] = useState();

  const getBandwidth = async () => {
    const startTime = new Date().getTime();
    let endTime;
    let imageAddress;
    let imageSize;

    try {
      const speedtestImageData = await axios.get(
        `${process.env.REACT_APP_API_URL}/speedtest-image`
      );
      imageAddress = `${process.env.REACT_APP_API_URL}${speedtestImageData.data.speedImage.url}`;
      imageSize = speedtestImageData.data.speedImage.size * 1000;
    } catch (err) {
      return;
    }

    const imageSrc = new Image();

    const displaySpeed = (start, end, size) => {
      const duration = (end - start) / 1000;
      const loadedBits = size * 8;

      /* Converts a number into string
         using toFixed(2) rounding to 2 */
      const bps = (loadedBits / duration).toFixed(2);
      const speedInKbps = (bps / 1024).toFixed(2);
      const speedInMbps = (speedInKbps / 1024).toFixed(2);

      return speedInMbps;
    };

    imageSrc.onload = async () => {
      endTime = new Date().getTime();
      const speed = await displaySpeed(startTime, endTime, imageSize);
      setBandwidth(speed);
    };

    imageSrc.src = imageAddress;
  };

  useEffect(() => {
    const speedTestInterval = setInterval(() => {
      if (localStorage.getItem('lowBandwidth') !== 'true') {
        getBandwidth();
      }
    }, 60000);

    return () => clearInterval(speedTestInterval);
  }, []);

  useEffect(() => {
    if (bandwidth < 5) {
      if (localStorage.getItem('lowBandwidth') !== 'true') setShowBandwidthWarning(true);
    } else {
      setShowBandwidthWarning(false);
    }
  }, [bandwidth]);

  return <></>;
};

BandwidthTest.propTypes = {
  setShowBandwidthWarning: PropTypes.func.isRequired,
};

export default BandwidthTest;
