import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import marked from 'marked';
import Player from '@vimeo/player';
import YouTube from 'react-youtube';
import defaultPlaceholderImage from '../../../../assets/images/video-placeholder.png';
import LearningModuleControls from '../LearningModuleControls';

/**
 * Not sure what video transcript path is for but is here ready to go
 *
 * For both youtube and vimeo, must use the embed sharing URL otherwise it won't work
 * ie. www.youtube.com/embed/adLGHcj_fmA or player.vimeo.com/video/521922398
 */
const VideoModule = ({ inViewport, module, auth, checkModuleCompletion, targetModule }) => {
  const [play, setPlay] = useState(false);
  const [hide, setHide] = useState(false);
  const [video, setVideo] = useState(null);
  const [target, setTarget] = useState();

  useEffect(() => {
    if (targetModule !== null || (targetModule !== undefined && targetModule?.id !== 'intro'))
      setTarget(targetModule);
  }, [targetModule]);

  const videoRef = useRef();
  const playVideo = () => {
    setPlay(true);
    setHide(true);
  };

  const isYoutube = module.module_type[0].URL.includes('youtube');
  const isVimeo = module.module_type[0].URL.includes('vimeo');

  useEffect(() => {
    if (videoRef.current && isVimeo) {
      setVideo(new Player(videoRef.current));
      if (video) {
        video.setVolume(auth.audio.volume);
        video.setMuted(auth.audio.isMuted);
      }
    }
  });

  useEffect(() => {
    if (video && isVimeo) {
      video.setVolume(auth.audio.volume);
      video.setMuted(auth.audio.isMuted);
    }

    if (video && isYoutube) {
      if (auth.audio.isMuted) {
        video.mute();
      } else {
        video.unMute();
      }
    }
  }, [auth.audio]);

  useEffect(() => {
    if (video && inViewport === false) {
      if (isYoutube) {
        video.pauseVideo();
      } else {
        video.pause();
      }
    }
  }, [inViewport]);

  useEffect(() => {
    if (video) {
      if (isYoutube) {
        video.setVolume(auth.audio.volume);
        if (auth.audio.isMuted) {
          video.mute();
        } else {
          video.unMute();
        }
      }
      if (isVimeo) {
        video.setVolume(auth.audio.volume);
        video.setMuted(auth.audio.isMuted);
      }
    }
  }, [video]);

  const APIURL = process.env.REACT_APP_API_URL;

  let placeholderImage;

  if (module.module_type.length !== 0 && module.module_type[0].placeholderImage) {
    placeholderImage = module.module_type[0].placeholderImage.url;
  }

  let videoLink;

  /* eslint-disable prefer-destructuring */
  if (isYoutube) {
    if (module.module_type[0].URL.includes('embed')) {
      videoLink = module.module_type[0].URL.split('/')[-1];
    } else {
      videoLink = module.module_type[0].URL.split('=')[1];
    }
  } else {
    videoLink = module.module_type[0].URL;
  }
  /* eslint-enable prefer-destructuring */

  return (
    <div className="module-wrapper">
      <CSSTransition in={inViewport} timeout={1000} classNames="learning-module-ani">
        <div className="video-wrapper">
          <div className="video-intro-container">
            {module.module_type[0].video_intro_title && (
              <p className="video-intro-title">{module.module_type[0].video_intro_title}</p>
            )}

            {module.module_type[0].video_intro && (
              <div
                className="video-intro"
                dangerouslySetInnerHTML={{ __html: marked(module.module_type[0].video_intro) }}
              />
            )}
          </div>
          <div className="video-holder">
            <button
              className={`play-button ${hide && 'hide-placeholder'}`}
              type="button"
              onClick={() => {
                checkModuleCompletion(null, target);
                playVideo();
              }}
            >
              {placeholderImage ? (
                <img className="place-holder" src={APIURL + placeholderImage} alt={module.name} />
              ) : (
                <img className="place-holder" src={defaultPlaceholderImage} alt={module.name} />
              )}
              <div className="overlay" />
              <span className="video-play-icon">▶</span>
            </button>
            {hide && (
              <>
                {isVimeo ? (
                  <iframe
                    src={play ? videoLink : undefined}
                    frameBorder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    title={module.name}
                    ref={videoRef}
                  />
                ) : (
                  <YouTube
                    videoId={videoLink}
                    title={module.name}
                    ref={videoRef}
                    onReady={(e) => {
                      setVideo(e.target);
                      // video.setVolume(auth.audio.volume);
                      // if (auth.audio.isMuted) {
                      //   video.mute();
                      // }
                    }}
                  />
                )}
              </>
            )}
          </div>
          <LearningModuleControls module={module} />
        </div>
      </CSSTransition>
    </div>
  );
};

VideoModule.propTypes = {
  inViewport: PropTypes.bool.isRequired,
  module: PropTypes.objectOf(PropTypes.any).isRequired,
  auth: PropTypes.objectOf(PropTypes.any).isRequired,
  checkModuleCompletion: PropTypes.func.isRequired,
  targetModule: PropTypes.any,
};

VideoModule.defaultProps = {
  targetModule: null,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(VideoModule);
