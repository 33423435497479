import React, { useState, useEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import axios from 'axios';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { userLogin, userLogOut, loadUser } from '../../../actions/auth';
import Particles from '../../shared/Particles';

const ProviderSignIn = (props, res) => {
  const [text, setText] = useState('Loading...');
  const [error, setError] = useState('');
  const location = useLocation();
  const params = useParams();
  const history = useHistory();

  useEffect(() => {
    // API Request to get the Provider's token
    axios({
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL}/auth/${params.providerName}/callback${location.search}`,
      data: { tokenId: res.tokenId },
    })
      .then((response) => {
        if (response.status !== 200) {
          throw new Error(`Couldn't login to Strapi. Status: ${response.status}`);
        }
        return response;
      })
      .then((response) => {
        // DISPATCH
        props.userLogin(response.data);

        setText('You are connected. You will be redirected in a few seconds...');
        setTimeout(() => history.push('/dashboard'), 3000);
      })
      .catch((err) => {
        setError('Email is already registered. Try with another provider...');
        setTimeout(() => history.push('/login'), 3000);
      });
  }, []);

  return (
    <>
      <div>{error ? <p>{error}</p> : <p>{text}</p>}</div>
      <Particles />
    </>
  );
};

// Props Validation
ProviderSignIn.propTypes = {
  userLogin: PropTypes.func.isRequired,
};

// Get the State and connect it to the Component
const mapStateToProps = (state) => ({
  auth: state.auth,
});

//  Dispatch actions to the Redux Store
const mapDispatchToProps = (dispatch) => {
  return {
    userLogin: (data) => dispatch(userLogin(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProviderSignIn);
