import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Modal from 'react-modal';
import { isSmallViewport } from '../utils/TopicBrowserUtils';

const MobileExperienceWarning = () => {
  const [openModal, setOpenModal] = useState(false);
  const [wasOpen, setWasOpen] = useState(false);

  useEffect(() => {
    if (isSmallViewport()) {
      setOpenModal(true);
    }
  }, []);

  return (
    <Modal
      ariaHideApp={false}
      isOpen={openModal}
      closeTimeoutMS={500}
      shouldCloseOnEsc
      overlayClassName={`modal-panel__overlay ${wasOpen && 'overlay-after-open-warning'} ${
        !openModal && 'overlay-after-close'
      }`}
      className={`modal-pane slide-bottom ${wasOpen && 'content-after-open-warning'} ${
        !openModal && 'content-after-close'
      }`}
      onAfterOpen={() => setWasOpen(true)}
      onAfterClose={() => setWasOpen(false)}
    >
      <div className="default-modal">
        <div className="modal-body">
          <p>This site is best viewed on Desktop browsers.</p>
          <div className="cta">
            <Link
              className="btn btn-md btn-primary cta-btn"
              to="/"
              type="button"
              onClick={() => setOpenModal(false)}
            >
              Click to proceed anyway
            </Link>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default MobileExperienceWarning;
