import * as THREE from 'three';

import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';

import objUrl from '../../../../assets/models/fg-model-01.glb';

import { degreesToRadians, getRandomIntInclusive } from '../../../utils/TopicBrowserUtils';

class SubMenuFGModel extends THREE.Group {
  constructor(topic) {
    super();

    this.delegate = null;

    this.objUrl = objUrl;

    this.initPos = new THREE.Vector3();
    this.destPos = new THREE.Vector3();

    this.hasLoaded = false;

    this.startAnimateIn = false;

    this.clock = new THREE.Clock();

    this.lerpRate = getRandomIntInclusive(40, 80) / 100;

    this.topic = topic;

    this.init();
  }

  init = () => {
    let color;

    switch (this.topic) {
      case 'relationships':
        color = 0x977fff;
        break;
      case 'stigma':
        color = 0xff4a69;
        break;
      case 'identity':
        color = 0xd5db41;
        break;
      case 'current-culture-of-dementia-care':
        color = 0xfb5927;
        break;
      case 'possibilities':
        color = 0x86e3ed;
        break;
      default:
        color = 0xffffff;
        break;
    }

    const loader = new GLTFLoader();
    loader.load(
      this.objUrl,
      (gltf) => {
        const f = 10;
        gltf.scene.scale.set(f, f, f);

        const xRot = degreesToRadians(getRandomIntInclusive(0, 360));
        const yRot = degreesToRadians(getRandomIntInclusive(0, 360));
        const zRot = degreesToRadians(getRandomIntInclusive(0, 360));

        gltf.scene.rotation.set(xRot, yRot, zRot);

        gltf.scene.traverse(function (child) {
          const c = child;
          if (c instanceof THREE.Mesh) {
            c.material.specular = new THREE.Color(color);
            c.material.emissive = new THREE.Color(color);
            c.material.emissiveIntensity = 0.7;
            c.material.transparent = true;
            c.material.opacity = 0.2;
          }
        });
        this.add(gltf.scene);
        this.hasLoaded = true;

        if (this.delegate != null) {
          this.delegate.onFGModelLoadComplete();
        }

        window.setTimeout(this.animateIn, getRandomIntInclusive(100, 1500));
      },
      undefined,
      function (error) {
        // console.error(error);
      }
    );
  };

  animateIn = () => {
    this.startAnimateIn = true;
  };

  setMenuDestPos(pos) {
    this.destPos = pos;
  }

  setInitPos(pos) {
    this.initPos = pos;
  }

  destroy() {
    this.delegate = null;

    this.initPos = null;
    this.destPos = null;

    this.clock = null;

    this.traverse((object) => {
      if (!object.isMesh) return;

      object.geometry.dispose();
    });
  }

  animate() {
    if (this.hasLoaded) {
      if (this.startAnimateIn) {
        const delta = this.clock.getDelta();
        const newPos = this.position.lerp(this.destPos, this.lerpRate * delta);
        this.position.set(newPos.x, newPos.y, newPos.z);
      }
    }
  }
}

export default SubMenuFGModel;
