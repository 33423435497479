import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import ChildTopicBrowser from '../childTopics/ChildTopicBrowser';
import MobileChildTopicBrowser from '../childTopics/mobile-child-topics/MobileChildTopicBrowser';

import { detectIsMobile, isSmallViewport, isLowBandwidth } from '../../utils/TopicBrowserUtils';

// We can use this as the component where the user selects a child topic.
const ParentTopic = ({ parentTopic, user }) => {
  const [isMobile, setIsMobile] = useState(true);

  const onResize = (e) => {
    if (!isLowBandwidth(user)) {
      if (isSmallViewport()) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    }
  };

  useEffect(() => {
    if (isLowBandwidth(user)) {
      setIsMobile(true);
    } else {
      const hasTouchScreen = detectIsMobile();

      if (hasTouchScreen) {
        setIsMobile(true);
      } else if (isSmallViewport()) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }

      window.addEventListener('resize', onResize);
    }

    return () => {
      window.removeEventListener('resize', onResize);
    };
  });

  return (
    <>
      {isMobile ? (
        <MobileChildTopicBrowser parentTopic={parentTopic} />
      ) : (
        <ChildTopicBrowser parentTopic={parentTopic} />
      )}
    </>
  );
};

ParentTopic.defaultProps = {
  user: null,
};

ParentTopic.propTypes = {
  parentTopic: PropTypes.objectOf(PropTypes.any).isRequired,
  user: PropTypes.objectOf(PropTypes.any),
};

const mapStateToProps = (state) => ({
  parentTopic: state.activeModule.parentTopic,
  user: state.auth.user,
});

export default connect(mapStateToProps)(ParentTopic);
