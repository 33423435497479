import * as THREE from 'three';
import { MathUtils } from 'three';

import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';

import objUrl from '../../../../assets/models/bg-model-01.glb';

import { degreesToRadians, getRandomIntInclusive } from '../../../utils/TopicBrowserUtils';

class SubMenuBGModel extends THREE.Group {
  constructor() {
    super();

    this.delegate = null;

    this.matController = {};
    this.fadeLerp = 0;

    this.hasLoaded = false;

    this.init();
  }

  init = () => {
    const shardMaterial = new THREE.MeshPhongMaterial({
      color: 0xffffff,
      transparent: true,
      opacity: 0,
      emissive: new THREE.Color(255, 255, 255),
      emissiveIntensity: 0.001,
    });

    this.matController.material = shardMaterial;

    const loader = new GLTFLoader();
    loader.load(
      objUrl,
      (gltf) => {
        const f = 10;
        gltf.scene.scale.set(f, f, f);

        const xRot = degreesToRadians(getRandomIntInclusive(0, 360));
        const yRot = degreesToRadians(getRandomIntInclusive(0, 360));
        const zRot = degreesToRadians(getRandomIntInclusive(0, 360));

        gltf.scene.rotation.set(xRot, yRot, zRot);
        gltf.scene.traverse(function (child) {
          const c = child;
          if (c instanceof THREE.Mesh) {
            c.material = shardMaterial;
          }
        });
        this.add(gltf.scene);
        this.hasLoaded = true;

        if (this.delegate != null) {
          this.delegate.onBGModelLoadComplete();
        }
      },
      undefined,
      function (error) {
        console.error(error);
      }
    );
  };

  destroy() {
    this.delegate = null;
    this.matController = null;

    this.traverse((object) => {
      if (!object.isMesh) return;

      object.geometry.dispose();
    });
  }

  animate() {
    if (this.hasLoaded) {
      this.fadeLerp += 0.02;
      if (this.fadeLerp >= 1) {
        this.fadeLerp = 1;
      }
      this.matController.material.opacity = MathUtils.lerp(0, 0.2, this.fadeLerp);
    }
  }
}

export default SubMenuBGModel;
