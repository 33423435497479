import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import PropTypes from 'prop-types';

import ParentTopic from './ParentTopic';
import Loading from '../../shared/Loading';

import { loadParent } from '../../../actions/activeModule';

// Once the parent topic is sellected we need to load it into state then display it.
const ParentTopicContainer = ({ dispatchLoadParent, isLoading, setIsFound }) => {
  const [selectedTopic, setSelectedTopic] = useState('');
  const [isLoaded, setIsLoaded] = useState(false);
  const [showParent, setShowParent] = useState(false);

  useEffect(() => {
    // When the Component is rendered we want to grab the last string in the url, and remove the '/'
    const { pathname } = window.location;
    // const parent = pathname.replace(/[^a-zA-Z ]/g, '');
    const parent = pathname.split('/')[2];

    setSelectedTopic(parent);

    // Send API request to load Parent Topic Data into redux
    dispatchLoadParent(parent);
  }, []);

  useEffect(() => {
    setIsFound(true);
  }, []);

  return (
    <>
      {/* { */}
      {/* /* Switch between loading indicator and Parent Topic */}
      {/* isLoading ? ( */}
      <div className={`parent-topic-container-loader load-${selectedTopic}`}>
        <CSSTransition
          in={!isLoaded}
          timeout={1000}
          classNames="intro-container-ani"
          unmountOnExit
          onExited={() => setShowParent(true)}
        >
          <div>
            <Loading isLoading={isLoading} setIsLoaded={setIsLoaded} />
          </div>
        </CSSTransition>

        {/* ) : ( */}
        <CSSTransition
          in={showParent}
          timeout={1000}
          classNames="intro-container-ani"
          unmountOnExit
        >
          <ParentTopic />
        </CSSTransition>
      </div>
      {/* ) */}
      {/* } */}
    </>
  );
};

ParentTopicContainer.propTypes = {
  dispatchLoadParent: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  setIsFound: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  isLoading: state.activeModule.parentLoading,
  token: state.auth.token,
});

export default connect(mapStateToProps, { dispatchLoadParent: loadParent })(ParentTopicContainer);
