import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import PropTypes from 'prop-types';

const SubMenuTopNav = ({ topicName, topicUid, completed, max, isVisible }) => {
  return (
    <>
      <Link className="sub-top-nav-back-btn back-link-icon" type="button" to="/dashboard">
        back
      </Link>
      <div className={['sub-top-nav', isVisible ? 'active' : ''].join(' ')}>
        <div className={['sub-top-nav-topic', `sub-top-nav-${topicUid}`].join(' ')}>
          {topicName === 'Current Culture of Dementia Care'
            ? `Current Culture \n of Dementia`
            : topicName}
        </div>
        <div className="sub-topic-status">
          <span className="sub-topic-status-label">topics explored</span>
          <div className="sub-progress-bar">
            <div
              className="sub-progress"
              style={{
                width: `${(completed / max) * 100}%`,
              }}
            />
          </div>
          <span className="sub-topic-status-progress">
            {completed}
            /
            {max}
          </span>
        </div>
      </div>
    </>
  );
};

SubMenuTopNav.propTypes = {
  topicName: PropTypes.string.isRequired,
  topicUid: PropTypes.string.isRequired,
  completed: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  isVisible: PropTypes.bool.isRequired,
};

export default SubMenuTopNav;
