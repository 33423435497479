import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import marked from 'marked';

import defaultPlaceholderImage from '../../assets/images/video-placeholder.png';

const Instructions = ({ goToOptions, setGoToOptions, setGoToInstructions, instructionVideo }) => {
  const [hide, setHide] = useState(false);
  const [play, setPlay] = useState(false);

  const videoRef = useRef();

  const APIURL = process.env.REACT_APP_API_URL;

  const playVideo = () => {
    setPlay(true);
    setHide(true);
  };

  const handleClick = () => {
    setGoToInstructions(false);
  };

  function wait(ms) {
    return new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  }

  return (
    <>
      <h1>{instructionVideo.pageTitle}</h1>
      {instructionVideo.pageDescription && (
        <div
          className="page-description"
          dangerouslySetInnerHTML={{
            __html: marked(instructionVideo.pageDescription),
          }}
        />
      )}
      <div className="video-wrapper">
        <div className="video-holder">
          <button
            className={`play-button ${hide && 'hide-placeholder'}`}
            type="button"
            onClick={() => {
              playVideo();
            }}
          >
            {instructionVideo.placeholderImage ? (
              <img
                className="place-holder"
                src={APIURL + instructionVideo.placeholderImage.url}
                alt="instructional video"
              />
            ) : (
              <img
                className="place-holder"
                src={defaultPlaceholderImage}
                alt="instructional video"
              />
            )}
            <div className="overlay" />
            <span className="video-play-icon">▶</span>
          </button>
          <iframe
            src={play ? instructionVideo.url : undefined}
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Instruction video"
            ref={videoRef}
          />
        </div>
      </div>
      <div className="intro-controls instructions">
        <button
          type="button"
          id="next"
          className="btn btn-icon-md btn-secondary continue"
          onClick={handleClick}
        >
          Continue&nbsp;
        </button>
      </div>
    </>
  );
};

Instructions.propTypes = {
  goToOptions: PropTypes.bool.isRequired,
  setGoToOptions: PropTypes.func.isRequired,
  setGoToInstructions: PropTypes.func.isRequired,
  instructionVideo: PropTypes.any.isRequired,
};

export default Instructions;
