import React, { useEffect, useState, useRef } from 'react';
import { Link, useHistory, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import ReactToPrint from 'react-to-print';

import { logout, deleteAccount, setLowBandwidthMode } from '../../../../actions/auth';

import { Certificate } from '../../../shared/Certificate';

const Account = ({
  auth,
  progress,
  dispatchLogout,
  dispatchDeleteAccount,
  dispatchSetLowBandwidthMode,
}) => {
  const history = useHistory();
  const [openModal, setOpenModal] = useState(false);
  const [wasOpen, setWasOpen] = useState(false);
  const [password, setPassword] = useState('');
  const [isLowBandwidth, setIsLowBandwidth] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [showShare, setShowShare] = useState(false);
  const [userName, setUserName] = useState('');
  const [showNameEntry, setShowNameEntry] = useState(true);

  const printRef = useRef();

  if (!auth.user) {
    return <Redirect to="login" />;
  }

  useEffect(() => {
    if (progress.completedModules) {
      const checkComplete = progress.completedModules.filter((module) => {
        return module.completed === false;
      });
      if (checkComplete.length === 0) {
        setIsDisabled(false);
      }
    }
  }, [progress]);

  useEffect(() => {
    if (auth.user) {
      setIsLowBandwidth(auth.user.data.lowBandwidthMode);
    } else {
      const lowBandwidth = localStorage.getItem('lowBandwidth') === 'true';

      setIsLowBandwidth(lowBandwidth);
    }
  }, []);

  const handleLowBandwidthChange = async (e) => {
    setIsLowBandwidth(!isLowBandwidth);
    if (auth.user) {
      const user = auth.user.data;
      await dispatchSetLowBandwidthMode(user.id, auth.token, !isLowBandwidth);
    } else {
      localStorage.setItem('lowBandwidth', !isLowBandwidth);
    }
  };

  const handleLogoutClick = () => {
    dispatchLogout();
    history.push('/');
  };

  const handleDeleteClick = (user, token, deleteToken) => {
    setOpenModal(false);
    dispatchDeleteAccount(user, token, deleteToken).then((data) => {
      if (data) {
        history.push('/');
      }
    });
  };

  const onChange = (e) => {
    setPassword(e.target.value);
  };

  const LogInOut = () => {
    if (auth.user) {
      return (
        <button className="log-btn" type="button" onClick={handleLogoutClick}>
          Log out
        </button>
      );
    }
    return (
      <Link className="log-btn log-in-btn" to="/login">
        Log In
      </Link>
    );
  };

  const handleChange = (event) => {
    setUserName(event.target.value);
  };

  return (
    <div className="account-menu">
      <div className="account-header">
        <h2>Account</h2>
        <LogInOut />
      </div>
      <div className="account-email">
        <h4>Email</h4>
        <p>{auth.user ? auth.user.data.email : 'Please log in!'}</p>
      </div>
      <div className="account-settings">
        <h4>Settings</h4>
        <ul className="user-settings">
          <li>
            <input
              type="checkbox"
              name="low-bandwidth"
              checked={isLowBandwidth}
              onChange={handleLowBandwidthChange}
            />
            <label className="radio" htmlFor="option-1">
              Enable Low Bandwidth Mode
            </label>
          </li>
        </ul>
      </div>
      {!auth.user && (
        <div className="account-linked-accounts">
          <h4>Linked Accounts</h4>
          <p>
            <span>Sign up with Google</span>
            <a href={`${process.env.REACT_APP_API_URL}/connect/google`}>
              <button type="button" className="btn btn-icon google-btn">
                <i className="icon-google" />
                Connect
              </button>
            </a>
          </p>
          <p>
            <span>Sign up with Facebook</span>
            <a href={`${process.env.REACT_APP_API_URL}/connect/facebook`}>
              <button type="button" className="btn btn-icon facebook-btn">
                <i className="icon-facebook" />
                Connect
              </button>
            </a>
          </p>
        </div>
      )}
      <div className="account-completion">
        <h4>Completion Certificate</h4>
        {isDisabled && (
          <p className="needs-complete">Please complete the experience to download certificate.</p>
        )}
        {!isDisabled && (
          <div>
            {showNameEntry === true && (
              <>
                <label className="name-label" htmlFor="name">
                  Enter your name:
                </label>
                <input
                  className="name-input"
                  id="name"
                  type="text"
                  value={userName}
                  onChange={handleChange}
                />
                <button
                  className="submit-name-button btn btn-sm btn-ternary login-btn"
                  type="button"
                  onClick={() => {
                    setShowNameEntry(false);
                  }}
                  disabled={userName === '' && true}
                >
                  Submit
                </button>
              </>
            )}
            {showNameEntry === false && (
              <ReactToPrint
                trigger={() => {
                  return (
                    <button
                      className={`${isDisabled && 'disabled'}`}
                      type="button"
                      disabled={isDisabled}
                    >
                      Download certificate
                    </button>
                  );
                }}
                content={() => printRef.current}
              />
            )}
          </div>
        )}
      </div>
      <div style={{ display: 'none' }}>
        <Certificate ref={printRef} name={userName} />
      </div>
      {showShare && (
        <div className="account-share">
          <h4>Share</h4>
          <p>Share on Facebook</p>
          <p>Share on Twitter</p>
          <p>Share on LinkedIn</p>
        </div>
      )}
      {auth.user && (
        <div className="account-privacy">
          <h4>Privacy</h4>
          <button type="button" onClick={() => setOpenModal(true)}>
            Delete Account
          </button>
        </div>
      )}
      <Modal
        ariaHideApp={false}
        isOpen={openModal}
        closeTimeoutMS={500}
        shouldCloseOnEsc
        overlayClassName={`modal-panel__overlay ${wasOpen && 'overlay-after-open'} ${
          !openModal && 'overlay-after-close'
        }`}
        className={`modal-pane slide-bottom ${wasOpen && 'content-after-open'} ${
          !openModal && 'content-after-close'
        }`}
        onAfterOpen={() => setWasOpen(true)}
        onAfterClose={() => {
          setWasOpen(false);
          setPassword('');
        }}
      >
        <div className="default-modal">
          <div className="modal-body">
            <p className="description">
              Are you sure you want to delete your account? All progress will be lost.
            </p>
            <div className="cta">
              <button
                className="btn btn-md btn-primary cta-btn"
                // to="/"
                type="button"
                onClick={() =>
                  handleDeleteClick(auth.user.data, auth.token, auth.user.data.deleteToken)}
              >
                Delete Account
              </button>
              <span
                className="first-cta"
                onClick={() => setOpenModal(false)}
                onKeyDown={() => setOpenModal(false)}
                role="button"
                tabIndex="0"
              >
                Cancel
              </span>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

Account.propTypes = {
  auth: PropTypes.shape({
    token: PropTypes.string,
    user: PropTypes.objectOf(PropTypes.any),
    isAuthenticated: PropTypes.bool,
  }).isRequired,
  progress: PropTypes.objectOf(PropTypes.any).isRequired,
  dispatchLogout: PropTypes.func.isRequired,
  dispatchDeleteAccount: PropTypes.func.isRequired,
  dispatchSetLowBandwidthMode: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  progress: state.progressTracker,
});

export default connect(mapStateToProps, {
  dispatchLogout: logout,
  dispatchDeleteAccount: deleteAccount,
  dispatchSetLowBandwidthMode: setLowBandwidthMode,
})(Account);
