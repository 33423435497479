import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';

import ResourcesList from './ResourcesList';

const Resources = ({ parentTopics }) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (Object.keys(parentTopics).length) {
      setIsLoading(false);
    }
  }, [parentTopics]);

  return (
    <section className={`resources-modules ${isLoading && 'loading'}`}>
      <CSSTransition in={!isLoading} timeout={1000} classNames="intro-container-ani" unmountOnExit>
        <div>
          <div className="resources-modules-header">
            <h2>Resources</h2>
          </div>
          {parentTopics.map((parent) => {
            return (
              <div className="resources-module-container" key={parent.UID}>
                <h3 className="resources-parent">{parent.name}</h3>
                <ResourcesList modules={parent.info_links} />
              </div>
            );
          })}
        </div>
      </CSSTransition>
    </section>
  );
};

Resources.propTypes = {
  parentTopics: PropTypes.arrayOf(PropTypes.any).isRequired,
};

const mapStateToProps = (state) => ({
  parentTopics: state.activeModule.parentTopics,
});

export default connect(mapStateToProps)(Resources);
