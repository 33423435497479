import React from 'react';

import PropTypes from 'prop-types';

const SaveButton = ({ handleClick, active, hide }) => {
  return (
    <button
      type="button"
      className={`save-button ${hide ? 'hide' : ''} ${active ? 'active' : ''}`}
      onClick={handleClick}
    >
      <i className="save-icon" />
      <span className="save-text">{active ? 'saved' : 'save'}</span>
    </button>
  );
};

SaveButton.propTypes = {
  handleClick: PropTypes.func.isRequired,
  active: PropTypes.bool.isRequired,
  hide: PropTypes.bool.isRequired,
};

export default SaveButton;
