import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';

const CompletionCertificate = ({ progressTracker }) => {
  const [complete, setComplete] = useState(true);

  useEffect(() => {
    const completedParents = progressTracker.completedModules.filter(
      (module) => module.completed === true
    );

    if (completedParents.length === progressTracker.completedModules.length) {
      setComplete(true);
    }
  }, []);

  return (
    <section className="completion-certificate">
      <div className="close-link">
        <Link to="/dashboard">
          <span className="close-link-icon" />
          <span className="close-link-text">close</span>
        </Link>
      </div>
      {complete ? (
        <>
          <div className="certificate-container">
            <h1>Congratulations!</h1>
            <p className="thank-you-message">
              You have successfully completed
              {' '}
              <span style={{ fontStyle: 'italic' }}>
                Dementia in New Light: A Digital Learning Experience
              </span>
              . Thank you for joining us in reimagining dementia and exploring how we can all help
              to create a better world for everyone affected by dementia.
            </p>
            <p className="certificate-paragraph">
              A certificate of completion can be downloaded on your
              {' '}
              <Link to="/account">account</Link>
              {' '}
              page.
            </p>
          </div>
        </>
      ) : (
        <Redirect to="/dashboard" />
      )}
    </section>
  );
};

CompletionCertificate.propTypes = {
  progressTracker: PropTypes.objectOf(PropTypes.any).isRequired,
};

const mapStateToProps = (state) => ({
  progressTracker: state.progressTracker,
});

export default connect(mapStateToProps)(CompletionCertificate);
