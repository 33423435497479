import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import GlobalMenuItem from './GlobalMenuItem';
import ToolTip from '../../tool-tips/ToolTip';
import UserControls from '../UserControls';
import { loadMenu } from '../../../actions/activeModule';

const GlobalMenu = ({
  auth,
  dispatchLoadMenu,
  backgroundActive,
  parentMenu,
  backgroundMusicPlaying,
  setBackgroundMusicPlaying,
  audio,
  setAudio,
  hideMenu,
}) => {
  const [showGlobalMenu, setShowGlobalMenu] = useState(false);
  const [wasOpen, setWasOpen] = useState(false);
  const [showTips, setShowTips] = useState(false);
  const [hideControls, setHideControls] = useState(true);

  const showTipsLocal = JSON.parse(localStorage.getItem('showTips'));

  const history = useHistory();
  const currentPath = useLocation();

  const hidePaths = [
    '',
    '/',
    '/login',
    '/register',
    '/login-options',
    '/forgot-password',
    '/reset',
    '/auth/google/callback',
    '/auth/facebook/callback',
  ];

  const urlCheck = () => {
    return hidePaths.includes(currentPath.pathname) || hideMenu;
  };

  // hide the nav
  useEffect(() => {
    if (showGlobalMenu === false) {
      setHideControls(urlCheck());
      return history.listen((location, action) => {
        setHideControls(urlCheck());
      });
    }
  });

  useEffect(() => {
    setShowGlobalMenu(false);
    // setToggleControlDisplay(urlCheck());
    setWasOpen(false);
    dispatchLoadMenu();
  }, []);

  useEffect(() => {
    const showTipsTemp = JSON.parse(localStorage.getItem('showTips'));
    if (localStorage.getItem('showTips')) {
      if (!showTipsTemp.show) {
        setShowTips(false);
      } else {
        setShowTips(auth.showTips.showTip.browser);
      }
    }
  }, [auth.showTips]);

  const closeModal = () => {
    setShowGlobalMenu(false);
    setHideControls(true);
  };

  const openModal = () => {
    if (parentMenu.length > 0) {
      setShowGlobalMenu(true);
      setHideControls(false);
    }
  };

  const show = urlCheck();

  return (
    !show && (
      <div className={`menu-wrapper ${urlCheck() ? 'hide' : 'show'}`} data-test={hideControls}>
        {showTips && <ToolTip toolType="browser-onboarding" />}
        <div
          className={`menu-drawer ${urlCheck() ? 'hide' : 'show'} ${
            showGlobalMenu ? 'hidden' : ''
          }`}
        >
          <button className="menu-btn" id="navigation" type="button" onClick={() => openModal()}>
            menu
          </button>
          <Modal
            ariaHideApp={false}
            aria={{ label: 'main menu' }}
            isOpen={showGlobalMenu}
            closeTimeoutMS={500}
            shouldCloseOnEsc
            overlayClassName={`slide-panel__overlay ${wasOpen && 'overlay-after-open'} ${
              !showGlobalMenu && 'overlay-after-close'
            }`}
            className={`slide-pane slide-bottom ${wasOpen && 'content-after-open'} ${
              !showGlobalMenu && 'content-after-close'
            }`}
            onAfterOpen={() => setWasOpen(true)}
            onAfterClose={() => setWasOpen(false)}
          >
            <GlobalMenuItem
              closeModal={closeModal}
              hideControls={hideControls}
              showGlobalMenu={showGlobalMenu}
            />
          </Modal>
        </div>
        <UserControls
          hideControls={hideControls}
          closeModal={closeModal}
          showGlobalMenu={showGlobalMenu}
          backgroundActive={backgroundActive}
          useAudio
          backgroundMusicPlaying={backgroundMusicPlaying}
          setBackgroundMusicPlaying={setBackgroundMusicPlaying}
          audio={audio}
          setAudioFile={setAudio}
        />
      </div>
    )
  );
};

GlobalMenu.propTypes = {
  auth: PropTypes.shape({
    token: PropTypes.string,
    isAuthenticated: PropTypes.bool,
    user: PropTypes.objectOf(PropTypes.any),
    showTips: PropTypes.objectOf(PropTypes.any).isRequired,
  }).isRequired,
  dispatchLoadMenu: PropTypes.func.isRequired,
  backgroundActive: PropTypes.bool.isRequired,
  parentMenu: PropTypes.arrayOf(PropTypes.any).isRequired,
  backgroundMusicPlaying: PropTypes.bool.isRequired,
  setBackgroundMusicPlaying: PropTypes.func.isRequired,
  audio: PropTypes.objectOf(PropTypes.any),
  setAudio: PropTypes.func.isRequired,
  hideMenu: PropTypes.bool.isRequired,
};

GlobalMenu.defaultProps = {
  audio: null,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  parentMenu: state.activeModule.parentMenu,
});

export default connect(mapStateToProps, {
  dispatchLoadMenu: loadMenu,
})(GlobalMenu);
