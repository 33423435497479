import { useEffect, useState } from 'react';
import axios from 'axios';
import { useLocation, useHistory } from 'react-router-dom';

const GlobalAudio = (
  useAudio,
  backgroundMusicPlaying,
  setBackgroundMusicPlaying,
  audio,
  setAudioFile
) => {
  const [playing, setPlaying] = useState(false);
  const [volume, setVolume] = useState(1);
  const [loc, setLoc] = useState();
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    return history.listen((locationObj) => {
      setLoc(locationObj.pathname);
    });
  }, [history]);

  useEffect(() => {
    if (
      audio &&
      playing &&
      (loc?.split('/').length >= 4 || loc?.split('/')[1] === 'instructions')
    ) {
      const audioInstance = audio;
      const fadeAudio = setInterval(() => {
        if (audio && audio.volume > 0.1) {
          audioInstance.volume -= 0.1;
        }
        if (audio && audio.volume < 0.1) {
          // if (loc.split('/')[1] === 'instructions') {
          //   audioInstance.pause();
          // }
          clearInterval(fadeAudio);
          // if (audio && playing && loc.split('/').length >= 4) {
          //   audioInstance.pause();
          // }
        }
      }, 200);
    }

    if (
      audio &&
      backgroundMusicPlaying &&
      (loc?.split('/').length < 4 || loc?.split('/')[1] !== 'instructions')
    ) {
      const audioInstance = audio;
      const fadeInAudio = setInterval(() => {
        if (audio && audio.volume < 1) {
          audioInstance.volume += 0.1;
        }
        if (audio && audio.volume === 1) {
          clearInterval(fadeInAudio);
        }
      }, 200);
    }
  }, [loc]);

  const playAttempt = setInterval(() => {
    if (location.pathname.split('/').length < 4 && useAudio && backgroundMusicPlaying === false) {
      if (audio) {
        const startPlayPromise = new Promise((resolve, reject) => {
          const audioTemp = audio;
          audioTemp.muted = true;
          resolve(true);
        });

        if (startPlayPromise !== undefined) {
          startPlayPromise
            .then(() => {
              audio.play();
              const audioTemp = audio;
              audioTemp.muted = false;
              setPlaying(true);
              setBackgroundMusicPlaying(true);
              clearInterval(playAttempt);
              audio.addEventListener('ended', () => audio.play());
            })
            .catch((error) => {
              setPlaying(false);
            });
        }
      }
    }
  }, 1000);

  useEffect(() => {
    let song;
    axios
      .get(`${process.env.REACT_APP_API_URL}/experience-audio`)
      .then((response) => {
        song = response.data.song.url;
        if (!audio) {
          setAudioFile(new Audio(`${process.env.REACT_APP_API_URL}${song}`));
        }
      })
      .catch((err) => {});
  }, []);

  const playAudioOnClick = () => {
    if (location.pathname.split('/').length < 4 && useAudio && backgroundMusicPlaying === false) {
      if (audio) {
        const startPlayPromise = audio.play();
        if (startPlayPromise !== undefined) {
          startPlayPromise
            .then(() => {
              setPlaying(true);
              clearInterval(playAttempt);
              audio.addEventListener('ended', () => audio.play());
              setBackgroundMusicPlaying(true);
            })
            .catch((error) => {
              setPlaying(false);
            });
        }
      }
    }
  };

  useEffect(() => {
    window.addEventListener('touchstart', playAudioOnClick);

    return () => window.removeEventListener('touchstart', playAudioOnClick);
  }, [audio]);

  useEffect(() => {
    window.addEventListener('click', playAudioOnClick);

    return () => window.removeEventListener('click', playAudioOnClick);
  }, [audio]);

  useEffect(() => {
    window.addEventListener('scroll', playAudioOnClick);

    return () => window.removeEventListener('scroll', playAudioOnClick);
  }, [audio]);

  const toggle = () => {
    setPlaying(!playing);
  };

  useEffect(() => {
    if (audio && loc !== 'instructions') {
      return playAttempt;
    }
  }, []);

  // useEffect(() => {
  //   const audioInstance = audio;
  //   if (audio) {
  //     audioInstance.volume = volume;
  //   }
  // });

  useEffect(() => {
    const audioInstance = audio;
    if (audio && volume < 0.1) {
      audioInstance.muted = true;
    } else if (audio && volume > 0.1) {
      audioInstance.muted = false;
    }
  }, [volume]);

  return [setVolume, toggle, volume, playing, setPlaying];
};

export default GlobalAudio;
