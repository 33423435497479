import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { logout } from '../../actions/auth';

import Particles from '../shared/Particles';

import logo from '../../assets/images/dementia-logo-white.png';
import logo2x from '../../assets/images/dementia-logo-white@2x.png';

const NoContent = ({ auth, dispatchLogout, setHideMenu }) => {
  const [showNav, setShowNav] = useState(true);
  const history = useHistory();
  const handleLogoutClick = () => {
    dispatchLogout();
    history.push('/');
  };

  const containsLogin = window.location.href.indexOf('login') > -1;

  const authLinks = (
    <ul className="auth-links">
      <li>
        <Link to="/dashboard">Dashboard</Link>
      </li>
      <li>
        <button type="button" className="btn-text" onClick={handleLogoutClick}>
          Logout
        </button>
      </li>
    </ul>
  );

  const guestLinks = (
    <ul className="auth-links">
      {containsLogin ? (
        <>
          <li className="hide-md">Don&apos;t have an account?</li>
          <li>
            <Link to="/register">Sign Up</Link>
          </li>
        </>
      ) : (
        <>
          <li className="hide-md">Already have an account?</li>
          <li>
            <Link to="/login">Log in</Link>
          </li>
        </>
      )}
    </ul>
  );

  useEffect(() => {
    if (history.location.pathname.includes('content')) {
      setShowNav(false);
    }

    return () => {
      setShowNav(true);
    };
  }, []);

  // useEffect(() => {
  //   setHideMenu(true);

  //   return () => {
  //     setHideMenu(false);
  //   };
  // }, []);

  const backLink = history.location.pathname.includes('content') ? '/dashboard' : '/';

  return (
    <section className="page-not-found">
      {showNav ? (
        <nav className="auth-navigation">
          <Link className="cracked-logo" to="/">
            <img srcSet={`${logo}, ${logo2x} 2x`} src={logo} alt="dementia in a new light logo" />
          </Link>
          {auth.isAuthenticated ? authLinks : guestLinks}
        </nav>
      ) : null}
      <h1>Page Not Found</h1>
      <p>Sorry, the page you’re looking for could not be found.</p>
      <button
        type="button"
        onClick={() => {
          history.push(backLink);
          setHideMenu(false);
          setShowNav(true);
        }}
        className="btn btn-lg btn-secondary continue-btn"
      >
        Home
      </button>
      <Particles />
    </section>
  );
};

NoContent.propTypes = {
  auth: PropTypes.shape({
    isAuthenticated: PropTypes.bool,
  }).isRequired,
  dispatchLogout: PropTypes.func.isRequired,
  setHideMenu: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {
  dispatchLogout: logout,
})(NoContent);
