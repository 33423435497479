import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useInView } from 'react-intersection-observer';

// modules
import TextModule from './learning-modules/TextModule';
import Quote from './learning-modules/Quote';
import VideoModule from './learning-modules/VideoModule';
import ImageGallery from './learning-modules/ImageGallery';
import AnimationModule from './learning-modules/AnimationModule';
import AudioModule from './learning-modules/AudioModule';
import ReflectionQuestion from './reflectionQuestions/ReflectionQuestion';

const LearningModule = ({ module, moduleRefs, targetModule, markComplete }) => {
  const type = module.module_type[0]?.Type;
  const [questionTracker, setQuestionTracker] = useState([]);
  const [questionIds, setQuestionIds] = useState([]);
  const [target, setTarget] = useState();

  useEffect(() => {
    setTarget(targetModule);
  }, [targetModule]);

  // content check; dont return module without content
  if (!type) return null;

  // Detect if learning module is in viewport
  const { ref, inView, entry } = useInView({
    threshold: 0.1,
  });

  // update our moduleRefs array
  if (entry && !moduleRefs.includes(entry.target)) {
    moduleRefs.push(entry.target);
  }

  const hasReflectionQuestion = module.ReflectionQuestion[0]?.id;

  const checkModuleCompletion = (selectedAnswerId, targetedModule) => {
    if (hasReflectionQuestion && selectedAnswerId) {
      const isAnswered = questionTracker.filter((item) => item === selectedAnswerId).length > 0;
      if (!isAnswered) {
        setQuestionTracker((prev) => [...prev, selectedAnswerId]);
      } else {
        markComplete(targetedModule);
      }
    } else if (!hasReflectionQuestion) {
      markComplete(targetedModule);
    }
  };

  useEffect(() => {
    if (
      questionTracker.length === module.ReflectionQuestion.length &&
      module.ReflectionQuestion.length !== 0
    ) {
      markComplete(targetModule);
    }
  }, [questionTracker]);

  useEffect(() => {
    module.ReflectionQuestion.map((item, index) => {
      const hasId = questionIds.filter((id, i) => id === `${module.uid}-question-${i}`).length > 0;
      if (!hasId) setQuestionIds((prev) => [...prev, `${module.uid}-question-${index}`]);
      return null;
    });
  }, []);

  useEffect(() => {
    const completedQuestions = JSON.parse(localStorage.getItem('answerArray'));
    const previouslyCompleted = completedQuestions?.filter(
      (item) => questionIds.indexOf(item.uid) >= 0
    );
    previouslyCompleted?.map((question) => {
      const isAnswered = questionTracker.filter((item) => item === question.uid).length > 0;
      if (!isAnswered) setQuestionTracker((prev) => [...prev, question.uid]);
      return null;
    });
  }, [questionIds]);

  // assemble our classes
  const classes = `module learning-module-ani ${module.module_type[0].Type}-module ${
    inView ? 'in-view' : ''
  } ${hasReflectionQuestion ? 'has-reflection' : ''}`;

  return (
    <section className={classes} id={module.uid} ref={ref} key={module.id}>
      {type === 'quote' && (
        <Quote
          module={module}
          inViewport={inView}
          checkModuleCompletion={checkModuleCompletion}
          targetModule={target}
        />
      )}
      {type === 'video' && (
        <VideoModule
          module={module}
          inViewport={inView}
          checkModuleCompletion={checkModuleCompletion}
          targetModule={target}
        />
      )}
      {type === 'photo_gallery' && (
        <ImageGallery
          module={module}
          inViewport={inView}
          checkModuleCompletion={checkModuleCompletion}
          targetModule={target}
        />
      )}
      {type === 'text' && (
        <TextModule
          module={module}
          inViewport={inView}
          checkModuleCompletion={checkModuleCompletion}
          targetModule={target}
        />
      )}
      {type === 'animation' && (
        <AnimationModule
          module={module}
          inViewport={inView}
          checkModuleCompletion={checkModuleCompletion}
          targetModule={target}
        />
      )}
      {type === 'audio' && (
        <AudioModule
          module={module}
          inViewport={inView}
          checkModuleCompletion={checkModuleCompletion}
          targetModule={target}
        />
      )}
      {hasReflectionQuestion &&
        module.ReflectionQuestion.map((question, index) => {
          const i = index;
          return (
            <ReflectionQuestion
              reflectionQuestion={question}
              moduleName={module.uid}
              key={`${module.uid}-question-${i}`}
              index={i}
              checkModuleCompletion={checkModuleCompletion}
            />
          );
        })}
    </section>
  );
};

LearningModule.defaultProps = {
  targetModule: null,
};

LearningModule.propTypes = {
  module: PropTypes.objectOf(PropTypes.any).isRequired,
  moduleRefs: PropTypes.arrayOf(PropTypes.any).isRequired,
  targetModule: PropTypes.objectOf(PropTypes.any),
  markComplete: PropTypes.func.isRequired,
};

export default LearningModule;
