// Alerts
export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';

// Registration
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';

// Login
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const TEMP_LOGIN_SUCESS = 'TEMP_LOGIN_SUCCESS';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';
export const SET_TOKEN = 'SET_TOKEN';

// Delete User
export const DELETE_ACCOUNT = 'DELETE_ACCOUNT';

// Password Reset
export const PASSWORD_EMAIL = 'PASSWORD_EMAIL';
export const RESET_FAIL = 'RESET_FAIL';
export const TOKEN_SUCCESS = 'TOKEN_SUCCESS';

export const ACCOUNT_DELETED = 'ACCOUNT_DELETED';

// Turn off tooltip
export const SET_TIPS = 'SET_TIPS';

// Content
export const LOAD_MENU = 'LOAD_MENU';
export const LOAD_ALL_PARENTS = 'LOAD_ALL_PARENTS';
export const LOAD_PARENT = 'LOAD_PARENT';
export const LOAD_ALL_CHILDREN = 'LOAD_ALL_CHILDREN';
export const LOAD_CHILD = 'LOAD_CHILD';
export const CHILD_LOADING = 'CHILD_LOADING';
export const LOAD_LEARNING_MODULE = 'LOAD_LEARNING_MODULE';
export const INIT_LOAD_PARENT = 'INIT_LOAD_PARENT';

// Progress tracker
export const SET_MODULES = 'SET_MODULES';
export const UPDATE_MODULES = 'UPDATE_MODULES';
export const CLEAR_MODULES = 'CLEAR_MODULES';
export const USER_OVERRIDE = 'USER_OVERRIDE';
export const SESSION_TRACKER = 'SESSION_TRACKER';
export const SAVE_MODULES = 'SAVE_MODULES';

// User Updates
export const SAVE_MODULE = 'SAVE_MODULE';
export const SAVE_ANSWER = 'SAVE_ANSWER';

//  Audio
export const SET_USER_VOLUME = 'SET_USER_VOLUME';
export const SET_AUDIO = 'SET_AUDIO';

// Low Bandwidth
export const SET_LOW_BANDWIDTH = 'SET_LOW_BANDWIDTH';
