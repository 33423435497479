import React from 'react';
import PropTypes from 'prop-types';

const ResourcesItem = ({ module }) => {
  return (
    <a href={module.url} target="_blank" rel="noreferrer">
      <div className="resource">
        <div className="module-info-container">
          <div className="module-info">
            <h4>{`${module.LinkTitle}`}</h4>
          </div>
        </div>
        <figure className="module-link-image" />
      </div>
    </a>
  );
};

ResourcesItem.propTypes = {
  module: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default ResourcesItem;
