import React, { Component } from 'react';

import PropTypes from 'prop-types';

class SubMenuLabel extends Component {
  static STATE_LABEL_HIDDEN = 0;

  static STATE_LABEL_VISIBLE = 1;

  static STATE_LABEL_INACTIVE = 2;

  constructor(props) {
    super(props);

    this.state = {
      posX: 0,
      posY: 0,
    };
  }

  handleKeyDown = (e) => {
    if (e.keyCode === 32 || e.keyCode === 13) {
      this.props.onLabelClick(e);
    }
  };

  transform(xVal, yVal) {
    this.setState({
      posX: xVal,
      posY: yVal,
    });
  }

  render() {
    const { posX, posY } = this.state;
    const { labelState, isCompleted, labelSide, onLabelClick, label, uid, tabIndex } = this.props;
    let lState = SubMenuLabel.STATE_LABEL_HIDDEN;
    switch (labelState) {
      case SubMenuLabel.STATE_LABEL_HIDDEN:
        lState = 'sub-topic-label-hidden';
        break;
      case SubMenuLabel.STATE_LABEL_VISIBLE:
        lState = 'sub-topic-label-visible';
        break;
      case SubMenuLabel.STATE_LABEL_INACTIVE:
        lState = 'sub-topic-label-inactive';
        break;
      default:
        lState = 'sub-topic-label-hidden';
        break;
    }

    let completed = '';
    if (isCompleted) {
      completed = 'completed';
    }

    let sideVal = 0;
    if (labelSide === 1) {
      sideVal = '-100%';
    }

    return (
      <div
        role="button"
        tabIndex={tabIndex}
        className={['sub-topic-label', lState].join(' ')}
        onClick={onLabelClick}
        onKeyDown={this.handleKeyDown}
        style={{
          transform: `translate(${sideVal}, -50%) translate(${posX}px, ${posY}px)`,
        }}
        uid={uid}
      >
        <p>{label}</p>
        <div className="sub-topic-label-status">
          <span className="sub-topic-status-label">topic explored</span>
          <div className={['progress', completed].join(' ')} />
        </div>
      </div>
    );
  }
}

SubMenuLabel.propTypes = {
  labelSide: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  labelState: PropTypes.number.isRequired,
  isCompleted: PropTypes.bool.isRequired,
  onLabelClick: PropTypes.func.isRequired,
  uid: PropTypes.string.isRequired,
  tabIndex: PropTypes.number.isRequired,
};

export default SubMenuLabel;
