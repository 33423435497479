import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import MobileShardLabel from './MobileShardLabel';

const MobileTopicCarouselItem = ({ topic, progress, isSelected, tabIndex }) => {
  const history = useHistory();

  const onLabelClick = (e) => {
    const loc = `/content/${topic.UID}`;
    history.push(loc);
  };

  const calcCompleted = (topics) => {
    let comp = 0;

    for (let j = 0; j < topics.length; j += 1) {
      if (topics[j].completed) {
        comp += 1;
      }
    }

    return comp;
  };

  return (
    <div className={['mobile-topic-carousel-item', isSelected ? 'selected' : ''].join(' ')}>
      <div className="mobile-topic-carousel-item-container">
        <figure className={['img', `img-${topic.UID}`].join(' ')} />
      </div>
      {progress && (
        <MobileShardLabel
          label={topic.name}
          labelState={0}
          onLabelClick={onLabelClick}
          completed={calcCompleted(progress.child_topics)}
          max={progress.child_topics.length}
          labelUid={topic.UID}
          tabIndex={tabIndex}
        />
      )}
    </div>
  );
};

MobileTopicCarouselItem.propTypes = {
  topic: PropTypes.objectOf(PropTypes.any).isRequired,
  progress: PropTypes.objectOf(PropTypes.any).isRequired,
  isSelected: PropTypes.bool.isRequired,
  tabIndex: PropTypes.number.isRequired,
};

export default MobileTopicCarouselItem;
