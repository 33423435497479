import { React, useState } from 'react';

const Styleguide = () => {
  const [themeColor, setThemeColor] = useState('theme-light');

  const handleClick = () => {
    document.body.classList.toggle(setThemeColor(themeColor) ? 'theme-light' : 'theme-dark');
  };

  return (
    <>
      <h1 className="styleguide-h1">Style Guide</h1>
      <div className="styleguide-intro">
        <button type="button" className="btn btn-sm btn-toggle" onClick={handleClick}>
          Toggle theme colour scheme
        </button>
        <p>Adjust viewport in browser to view mobile sizing</p>
      </div>
      <section className="styleguide-container">
        <div className="styleguide-core-colors">
          <div>
            <h2 className="styleguide-heading">Core colours</h2>
            <span>Black</span>
            <span>#000</span>
            <div className="core-colors-primary" />
            <span>Black hover</span>
            <span>#232323</span>
            <div className="core-colors-primary-lighter" />
            <span>White</span>
            <span>#fff</span>
            <div className="core-colors-secondary" />
            <span>White hover</span>
            <span>#dadadc</span>
            <div className="core-colors-secondary-lighter" />
            <span>Dark purple</span>
            <span>#01020f</span>
            <div className="core-colors-dark-purple" />
          </div>
          <div>
            <h2 className="styleguide-heading">Gray colours</h2>
            <span>Dark gray</span>
            <span>#979797</span>
            <div className="gray-colors-dark-gray" />
            <span>Steel</span>
            <span>#80838e</span>
            <div className="gray-colors-steel" />
            <span>Slate gray</span>
            <span>#53545c</span>
            <div className="gray-colors-slate-grey" />
            <span>Bluey gray</span>
            <span>#9b9da6</span>
            <div className="gray-colors-bluey-grey" />
          </div>
          <div>
            <h2 className="styleguide-heading">Borders + backgrounds</h2>
            <span>Border colour</span>
            <span>lighten(#979797, 65%)</span>
            <div className="border-bg-colors-border-color" />
            <span>Border colour dark</span>
            <span>darken(#979797, 75%)</span>
            <div className="border-bg-colors-border-color-dark" />
            <span>Border colour light</span>
            <span>lighten(#979797, 3%)</span>
            <div className="border-bg-colors-border-color-light" />
            <span>Background colour</span>
            <span>#fff</span>
            <div className="border-bg-colors-bg-color" />
            <span>Background colour dark</span>
            <span>#0f1224</span>
            <div className="border-bg-colors-bg-color-dark" />
            <span>Menu background colour</span>
            <span>rgba(0, 0, 0, 0.75)</span>
            <div className="border-bg-colors-menu-bg-color" />
          </div>
          <div>
            <h2 className="styleguide-heading">Control colours</h2>
            <span>Success</span>
            <span>#32b643</span>
            <div className="control-colors-success" />
            <span>Warning</span>
            <span>#ffb700</span>
            <div className="control-colors-warning" />
            <span>Error</span>
            <span>#e85600</span>
            <div className="control-colors-error" />
          </div>
          <div>
            <h2 className="styleguide-heading">Other colours</h2>
            <span>Watermelon</span>
            <span>#ff4a69</span>
            <div className="other-colors-watermelon" />
          </div>
        </div>
        <div className="styleguide-typography">
          <h2 className="styleguide-heading">Typography</h2>
          <div className="style-guide columns">
            <div className="column col-12">
              <span>H1 Heading</span>
              <h1>Lorem Ipsum</h1>
            </div>
            <div className="column col-12">
              <span>H2 Heading</span>
              <h2>Lorem Ipsum</h2>
            </div>
            <div className="column col-12">
              <span>H3 Heading</span>
              <h3>Lorem Ipsum</h3>
            </div>
            <div className="column col-12">
              <span>H4 Heading</span>
              <h4>Lorem Ipsum</h4>
            </div>
            {/* JR: do we need h5 and h6? */}
            <div className="column col-12">
              <span>H5 Heading [TBD]</span>
              <h5>Lorem Ipsum</h5>
            </div>
            <div className="column col-12">
              <span>H6 Heading [TBD]</span>
              <h6>Lorem Ipsum</h6>
            </div>
            <div className="column col-12">
              <span>Numeral bold</span>
              <p className="numeral-bold">100</p>
            </div>
            <div className="column col-12">
              <span>Numeral light</span>
              <p className="numeral-light">100</p>
            </div>
            <div className="column col-12">
              <span>Paragraph XL</span>
              <p className="serif-text text-xl">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                incididunt ut labore et dolore magna aliqua.
              </p>
            </div>
            <div className="column col-12">
              <span>Paragraph L</span>
              <p className="serif-text text-lg">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                incididunt ut labore et dolore magna aliqua.
              </p>
            </div>
            <div className="column col-12">
              <span>Paragraph M</span>
              <p className="serif-text text-md">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                incididunt ut labore et dolore magna aliqua.
              </p>
            </div>
            <div className="column col-12">
              <span>Paragraph S</span>
              <p className="serif-text text-sm">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                incididunt ut labore et dolore magna aliqua.
              </p>
            </div>
            <div className="column col-12">
              <span>Paragraph XS</span>
              <p className="serif-text text-xs">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                incididunt ut labore et dolore magna aliqua.
              </p>
            </div>
            <div className="column col-12">
              <span>Description</span>
              <p className="description">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                incididunt ut labore et dolore magna aliqua.
              </p>
            </div>
            <div className="column col-12">
              <span>Legal</span>
              <p className="legal">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
            </div>

            <div className="column col-12">
              <span>Quote</span>
              <p className="quote">Lorem ipsum.</p>
            </div>
          </div>
        </div>
        <div className="styleguide-buttons">
          <h2 className="styleguide-heading">Buttons</h2>
          <div>
            <span>Button L primary</span>
            <button type="button" className="btn btn-lg btn-primary">
              Button
            </button>
            <span>Button L secondary</span>
            <button type="button" className="btn btn-lg btn-secondary">
              Button
            </button>
            <div className="hide-for-light">
              <span>Button L ternary (Only used in dark theme)</span>
              <button type="button" className="btn btn-lg btn-ternary">
                Button
              </button>
            </div>
            <span>Button M primary</span>
            <button type="button" className="btn btn-md btn-primary">
              Button
            </button>
            <span>Button M secondary</span>
            <button type="button" className="btn btn-md btn-secondary">
              Button
            </button>
            <div className="hide-for-light">
              <span>Button M ternary (Only used in dark theme)</span>
              <button type="button" className="btn btn-md btn-ternary">
                Button
              </button>
            </div>
            <span>Button S primary</span>
            <button type="button" className="btn btn-sm btn-primary">
              Button
            </button>
            <span>Button S secondary</span>
            <button type="button" className="btn btn-sm btn-secondary">
              Button
            </button>
            <div className="hide-for-light">
              <span>Button S ternary (Only used in dark theme)</span>
              <button type="button" className="btn btn-sm btn-ternary">
                Button
              </button>
            </div>
            <span>Button M icon primary</span>
            <button type="button" className="btn btn-icon-md btn-primary">
              Button
            </button>
            <span>Button M icon secondary</span>
            <button type="button" className="btn btn-icon-md btn-secondary">
              Button
            </button>
            <span>Button S icon primary [TBD]</span>
            <button type="button" className="btn btn-icon-sm btn-primary">
              Button
            </button>
            <span>Button S icon secondary [TBD]</span>
            <button type="button" className="btn btn-icon-sm btn-secondary">
              Button
            </button>
            <span>Button L icon primary</span>
            <button type="button" className="btn btn-icon-lg btn-primary google">
              Sign up with Google
            </button>
            <span>Button L icon secondary</span>
            <button type="button" className="btn btn-icon-lg btn-secondary google">
              Sign up with Google
            </button>
            <span>Button L icon primary</span>
            <button type="button" className="btn btn-icon-lg btn-primary facebook">
              Sign up with Facebook
            </button>
            <span>Button L icon secondary</span>
            <button type="button" className="btn btn-icon-lg btn-secondary facebook">
              Sign up with Facebook
            </button>
            <span>Link icon</span>
            <div className="styleguide-link-icons">
              <a href="/styleguide" className="back-link-icon">
                back
              </a>
              <br />
              <a href="/styleguide" className="close-link-icon">
                close
              </a>
            </div>
            <span>Button label [TBD]</span>
            <a href="/styleguide" className="btn-label">
              save
            </a>
            <span>Function icon [TBD]</span>
            <a href="/styleguide" className="clear-link-icon">
              Clear
            </a>
          </div>
        </div>

        <div className="styleguide-lists">
          <h2 className="styleguide-heading">Lists [TBD]</h2>
          <div className="column col-12">
            <span>List item in unordered list</span>
            <ul>
              <li className="list-item">List item</li>
            </ul>
            <span>List item in ordered list</span>
            <ol>
              <li className="list-item">List item</li>
            </ol>
          </div>
        </div>

        <div className="styleguide-navigation">
          <h2 className="styleguide-heading">Navigation</h2>
          <div className="column col-12">
            <span>Menu nav</span>
            <p className="menu-nav-item">Nav item</p>
          </div>
          <div className="column col-12">
            <span>Menu Nav Secondary</span>
            <p className="menu-nav-item-secondary">Nav item</p>
          </div>
          <div className="column col-12">
            <span>Module nav</span>
            <p className="module-nav-item">Nav item</p>
          </div>
          <div className="column col-12">
            <span>Topic Nav Parent</span>
            <p className="topic-nav-parent">Topic name</p>
          </div>
          <div className="column col-12">
            <span>Topic Nav Child</span>
            <p className="topic-nav-child">Topic name</p>
          </div>
        </div>

        <div className="styleguide-forms">
          <h2 className="styleguide-heading">Form elements</h2>
          <form className="column col-12">
            <label htmlFor="input-0" className="form-field-label">
              Label
            </label>
            <input
              value="Text input"
              placeholder="Placeholder"
              id="input-0"
              name="input-0"
              type="text"
            />
            <label htmlFor="input-1">Label</label>
            <textarea
              value="Textarea"
              placeholder="Placeholder"
              id="input-1"
              name="input-1"
              type="text"
            />
            <label htmlFor="input-2">Label</label>
            <input placeholder="Placeholder" id="input-2" name="input-2" type="text" />
            <div className="column col-12">
              <ul className="radio-list">
                <li>
                  <input type="radio" id="option-1" name="selector" />
                  <label className="radio" htmlFor="option-1">
                    Radio list item
                  </label>
                </li>
                <li>
                  <input checked type="radio" id="option-2" name="selector" />
                  <label className="radio" htmlFor="option-2">
                    Radio list item
                  </label>
                </li>
              </ul>
            </div>
          </form>
        </div>

        <div className="styleguide-pagination">
          <h2 className="styleguide-heading">Pagination</h2>
          <div className="column col-12">
            <p className="pagination">Pagination 0/10</p>
          </div>
        </div>
      </section>
      {/* <div className="menu-closed" />
        <div className="menu-open">menu open</div> */}
    </>
  );
};

export default Styleguide;
