import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';

import ParentTopicContainer from './parentTopics/ParentTopicContainer';
import ChildTopicContainer from './childTopics/ChildTopicContainer';
import NoContent from './NoContent';

import { loadAllParents, loadAllChildren } from '../../actions/activeModule';

const Topics = ({
  dispatchLoadParents,
  dispatchLoadChildren,
  parentTopics,
  childTopics,
  backgroundActive,
  setBackgroundActive,
  setHideMenu,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isFound, setIsFound] = useState(false);
  const [notFound, setNotFound] = useState(false);

  useEffect(() => {
    // load list of parent and child topics
    dispatchLoadParents();
    dispatchLoadChildren();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 3000);
  }, []);

  useEffect(() => {
    let notFoundTimer;
    if (!isFound && !isLoading) {
      notFoundTimer = setTimeout(() => {
        setNotFound(true);
      }, 5000);
    } else {
      clearTimeout(notFoundTimer);
    }
  }, [isFound, isLoading]);

  return (
    <>
      {
        /* for each child topic, create a PrivateRoute with a route of '/${parentTopicUID}/${$childTopicUID}' */
        childTopics.map((child) => {
          return (
            // Parent topic check to make sure it renders the correct component.
            // Will have error loading component otherwise
            child.parent_topic && (
              <Route key={child.uid} exact path={`/content/${child.parent_topic.UID}/${child.uid}`}>
                {({ match }) => (
                  <CSSTransition in={match != null} timeout={300} classNames="page" unmountOnExit>
                    <div className={`page ${child.parent_topic.UID}`}>
                      <ChildTopicContainer
                        backgroundActive={backgroundActive}
                        setBackgroundActive={setBackgroundActive}
                        setIsFound={setIsFound}
                      />
                    </div>
                  </CSSTransition>
                )}
              </Route>
            )
          );
        })
      }
      {
        /* for each parent topic, create a PrivateRoute with the uid as the path */
        parentTopics.map((parent) => {
          return (
            <Route key={`parent-${parent.id}`} exact path={`/content/${parent.UID}`}>
              <ParentTopicContainer setIsFound={setIsFound} />
            </Route>
          );
        })
      }
      {notFound && (
        <Route path="/content/" render={() => <NoContent setHideMenu={setHideMenu} />} />
      )}
    </>
  );
};

Topics.propTypes = {
  dispatchLoadParents: PropTypes.func.isRequired,
  dispatchLoadChildren: PropTypes.func.isRequired,
  parentTopics: PropTypes.arrayOf(PropTypes.any).isRequired,
  childTopics: PropTypes.arrayOf(PropTypes.any).isRequired,
  backgroundActive: PropTypes.bool.isRequired,
  setBackgroundActive: PropTypes.func.isRequired,
  setHideMenu: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  childTopics: state.activeModule.childTopics,
  parentTopics: state.activeModule.parentTopics,
  token: state.auth.token,
});

export default connect(mapStateToProps, {
  dispatchLoadParents: loadAllParents,
  dispatchLoadChildren: loadAllChildren,
})(Topics);
