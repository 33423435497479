import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';

import { removeModule } from '../../../../../actions/auth';

const SavedModulesItem = ({
  module,
  editMode,
  auth,
  update,
  setUpdate,
  dispatchRemoveModule,
  savedModules,
}) => {
  const history = useHistory();

  // handle delete click
  const handleDeleteClick = (e) => {
    e.preventDefault();

    // if user is in edit mode, delete entry, otherwise navigate to child topic
    if (auth.user) {
      if (!editMode) {
        history.push(`/content/${module.parentId}/${module.childId}/#${module.moduleId}`);
      } else {
        dispatchRemoveModule([{ id: module.moduleId }], auth);
      }
    } else if (!auth.user && !editMode) {
      history.push(`/content/${module.parentId}/${module.childId}/#${module.moduleId}`);
    } else if (!auth.user && editMode) {
      dispatchRemoveModule([{ id: module.moduleId }], auth);

      const localSaved = JSON.parse(localStorage.getItem('savedModules'));
      const index = localSaved.indexOf(module.moduleId);
      localSaved.splice(index, 1);

      localStorage.setItem('savedModules', JSON.stringify(localSaved));

      setUpdate(!update);
    }
  };

  return (
    <div className="saved-module">
      <Link
        className="module-info-container"
        to={`/content/${module.parentId}/${module.childId}/#${module.moduleId}`}
      >
        <div className="module-type-image" />
        <div className="module-info">
          <h3>{module.moduleName}</h3>
          <h4>
            {`${module.childName}`}
            <span className="module-type">{` - ${module.moduleType}`}</span>
          </h4>
        </div>
      </Link>
      <button
        type="button"
        className={`module-link-image ${editMode && `edit`}`}
        onClick={handleDeleteClick}
      >
        <div className="background" />
      </button>
    </div>
  );
};

SavedModulesItem.propTypes = {
  module: PropTypes.objectOf(PropTypes.any).isRequired,
  editMode: PropTypes.bool.isRequired,
  auth: PropTypes.objectOf(PropTypes.any).isRequired,
  dispatchRemoveModule: PropTypes.func.isRequired,
  savedModules: PropTypes.objectOf(PropTypes.any).isRequired,
  update: PropTypes.bool.isRequired,
  setUpdate: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  savedModules: state.activeModule.savedModules,
});

export default connect(mapStateToProps, { dispatchRemoveModule: removeModule })(SavedModulesItem);
