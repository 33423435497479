import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import ChildMenu from './child-topic-browser/ChildMenu';

// Finds all child topics of the parent topic and adds each to page
const ChildTopicBrowser = ({ parentTopic }) => {
  return (
    <div className="child-browser-container">
      <ChildMenu topic={parentTopic} />
    </div>
  );
};

ChildTopicBrowser.propTypes = {
  parentTopic: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default ChildTopicBrowser;
