import axios from 'axios';
import jwt from 'jsonwebtoken';
// we can call setAlert fn from anywhere to set the error message (validation)
import { setAlert } from './alert';
import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  USER_LOADED,
  AUTH_ERROR,
  TEMP_LOGIN_SUCESS,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  PASSWORD_EMAIL,
  RESET_FAIL,
  SET_MODULES,
  CLEAR_MODULES,
  USER_OVERRIDE,
  DELETE_ACCOUNT,
  SESSION_TRACKER,
  LOAD_MENU,
  SET_USER_VOLUME,
  SET_TIPS,
  SAVE_MODULE,
  SET_LOW_BANDWIDTH,
  SAVE_ANSWER,
  SET_AUDIO,
  SET_TOKEN,
} from './types';

// Dummy data created for purpose of testing progress on menu
const dummyTestData = require('../components/utils/dummyTestData.json');

// Helper function to create a parent topic (used in registration and login)
const createModuleParentTopic = (parentTopic) => {
  const parentTracker = {};
  parentTracker.id = parentTopic.id;
  parentTracker.completed = false;
  parentTracker.child_topics = [];

  // if parent topic has children, parse and add them to completed module object
  if (parentTopic.child_topics) {
    parentTopic.child_topics.forEach((childTopic) => {
      const childTopicTracker = {};
      childTopicTracker.id = childTopic.id;
      childTopicTracker.completed = false;
      // Added in case there are child topics that don't have modules
      if (childTopic.learning_modules) {
        childTopicTracker.numTotalModules = childTopic.learning_modules.length;
      }
      childTopicTracker.completedLearningModules = [];
      parentTracker.child_topics.push(childTopicTracker);
    });
  }
  return parentTracker;
};

// Get completed modules function
const getCompleteModules = async (dispatch) => {
  const modules = [];
  const childTopics = [];
  try {
    const childTopicList = await axios.get(`${process.env.REACT_APP_API_URL}/child-topics`);
    childTopicList.data.map((childTopic) => childTopics.push(childTopic));
  } catch (err) {
    if (err) {
      const errors = err.response?.data.message[0].messages;
      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.message, 'danger')));
      }
    }
  }

  try {
    // making api call to parent topics to set them
    const parentTopics = await axios.get(`${process.env.REACT_APP_API_URL}/parent-topics`);
    parentTopics.data.forEach((parentTopic) => {
      const ct = childTopics.filter((childTopic) => childTopic.parent_topic.id === parentTopic.id);
      const newParentTopic = { ...parentTopic, child_topics: ct };
      modules.push(createModuleParentTopic(newParentTopic));
    });
  } catch (err) {
    if (err) {
      const errors = err.response?.data.message[0].messages;
      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.message, 'danger')));
      }
    }
  }

  return modules;
};

// Function to check if the user completed modules are up to date
// NOTE: In strapi, remember to add "update" to user permissions
const checkModules = async (userModules, dispatch) => {
  // Function to compare two arrays (used for checking user topics to master copy)
  const containsAll = (arr1, arr2) => {
    const arr1Ids = arr1.map((arr1Item) => arr1Item.id);
    const arr2Ids = arr2.map((arr2Item) => arr2Item.id);
    return arr2Ids.every((item) => arr1Ids.includes(item));
  };

  // Checks and returns new topic id if there are any
  const difference = (arr1, arr2) => {
    const arr1Ids = arr1.map((arr1Item) => arr1Item.id);
    const arr2Ids = arr2.map((arr2Item) => arr2Item.id);
    return arr2Ids.filter((value) => !arr1Ids.includes(value));
  };

  // Funciton to remove topic (parent or child)
  const removeTopic = (masterCopy, userCopy) => {
    const missingItems = difference(masterCopy, userCopy);
    missingItems.forEach((item) => {
      const itemToRemove = masterCopy.find((obj) => obj.id === item);
      userCopy.splice(userCopy.indexOf(itemToRemove));
    });
  };

  // Check both parent and child topics here
  try {
    // Commented out for using dummy test in utils
    const parentTopics = await getCompleteModules();
    // const parentTopics = dummyTestData;
    const userParentTopics = userModules;

    // Checks and removes parent topics if they don't exist in master copy
    if (!containsAll(parentTopics, userParentTopics)) {
      removeTopic(parentTopics, userParentTopics);
    }

    // checks child topics of each parent before new parent topics are added
    userParentTopics.forEach((parentTopic) => {
      // Creating child topics of parent topic in question
      const parentToLookup = parentTopics.find((obj) => obj.id === parentTopic.id);
      const userChildTopics = parentTopic.child_topics;
      if (parentToLookup) {
        const masterChildTopics = parentToLookup.child_topics;

        // Checks and removes child topics if they don't exist in master copy
        if (!containsAll(masterChildTopics, userChildTopics)) {
          removeTopic(masterChildTopics, userChildTopics);
        }

        // Checks and adds missing child topics to user
        if (!containsAll(userChildTopics, masterChildTopics)) {
          const missingItems = difference(userChildTopics, masterChildTopics);
          missingItems.forEach((item) => {
            const newChildTopic = masterChildTopics.find((obj) => obj.id === item);
            userChildTopics.push({
              id: newChildTopic.id,
              name: newChildTopic.name,
              completed: false,
              numTotalModules: newChildTopic.learning_modules.length,
              completedLearningModules: [],
            });
          });
        }
      }
    });

    // Checks and adds missing parent topics to user
    if (!containsAll(userParentTopics, parentTopics)) {
      const missingItems = difference(userParentTopics, parentTopics);
      missingItems.forEach((item) => {
        const newParentTopic = parentTopics.find((obj) => obj.id === item);
        userParentTopics.push(createModuleParentTopic(newParentTopic));
      });
    }

    return userParentTopics;
  } catch (err) {
    if (err) {
      const errors = err.response?.data.message[0].messages;
      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.message, 'danger')));
      }
    }
  }
};

// Load User action
export const loadUser = (token) => async (dispatch) => {
  // Check if there is a token, if so, put it in a global header. utils/setAuthToken.js
  axios
    .get(`${process.env.REACT_APP_API_URL}/users/me`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      const toolTipObject = JSON.parse(localStorage.getItem('showTips'));
      toolTipObject.show = response.data.showTips;
      localStorage.setItem('showTips', JSON.stringify(toolTipObject));
      dispatch({
        type: SET_TIPS,
        payload: toolTipObject,
      });

      dispatch({
        type: USER_LOADED,
        payload: response,
      });
    })
    .catch((err) => {
      let errors;
      if (err.response) errors = err.response.data.message[0].messages;

      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.message, 'danger')));
      }

      dispatch({
        type: AUTH_ERROR,
      });
    });
};

// Register User Action
export const register =
  ({ email, password, userType }) =>
  async (dispatch) => {
    // Query the db to find the list of parent topics, child topics, and learning modules

    let completedModules;
    // calling and setting tracker data to object
    try {
      completedModules = await getCompleteModules();
    } catch (err) {
      if (err) {
        const errors = err.response?.data.message[0].messages;
        if (errors) {
          errors.forEach((error) => dispatch(setAlert(error.message, 'danger')));
        }
      }
    }

    // Prevent user from being created until pregress object is complete
    function checkStatus() {
      if (Object.keys(completedModules).length !== 0) {
        // If completed modules object is complete register user
        axios
          .post(`${process.env.REACT_APP_API_URL}/auth/local/register`, {
            email,
            password,
            userType,
            completedModules,
            savedModules: [],
            userVolume: 1,
            savedAnswers: [],
            showTips: true,
            lowBandwidthMode: false,
            deleteToken: jwt.sign(email, process.env.REACT_APP_SECRET),
          })
          .then((response) => {
            // Handle success.
            dispatch({
              type: REGISTER_SUCCESS,
              payload: response.data,
            });
            dispatch({
              type: SET_MODULES,
              payload: completedModules,
            });
            dispatch(loadUser(response.data.jwt));
          })
          .catch((err) => {
            // Handle error.
            if (err) {
              const errors = err.response?.data.message[0].messages;
              if (errors) {
                errors.forEach((error) => dispatch(setAlert(error.message, 'danger')));
              }
            }

            dispatch({
              type: REGISTER_FAIL,
            });
          });
      } else {
        // If completedModules object is still empty, check again in 100ms
        setTimeout(checkStatus, 100);
      }
    }
    checkStatus();
  };

// Login Action
export const login = (email, password, temp) => async (dispatch) => {
  // Function to check if the user completed modules are up to date
  // NOTE: In strapi, remember to add "update" to user permissions
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/auth/local`, {
      identifier: email,
      password,
    });
    // if user doesn't want to stay logged in, dispatch to sessionstorage
    if (temp) {
      dispatch({
        type: TEMP_LOGIN_SUCESS,
        payload: response.data,
      });
    } else {
      dispatch({
        type: LOGIN_SUCCESS,
        payload: response.data,
      });
    }

    // Update completed modules before logging in
    const userId = response.data.user.id;
    const updatedModule = await checkModules(response.data.user.completedModules);

    await axios.put(
      `${process.env.REACT_APP_API_URL}/users/${userId}`,
      { completedModules: updatedModule },
      {
        headers: {
          Authorization: `Bearer ${response.data.jwt}`,
        },
      }
    );
    // USER_OVERRIDE will set modulesLoaded to false so user data will overwrite default
    dispatch({
      type: USER_OVERRIDE,
    });
    dispatch(loadUser(response.data.jwt));
    dispatch({
      type: SET_MODULES,
      payload: updatedModule,
    });
  } catch (err) {
    let errors;
    if (err.response) errors = err.response.data.message[0].messages;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.message, 'danger')));
    }

    dispatch({
      type: LOGIN_FAIL,
    });
  }
};

// Logout
export const logout = () => async (dispatch) => {
  axios
    .get(`${process.env.REACT_APP_API_URL}/progress-tracker`)
    .then((response) => {
      dispatch({
        type: CLEAR_MODULES,
        payload: response.data.modules,
      });
    })
    .catch((err) => {
      if (err) {
        const errors = err.response?.data.message[0].messages;
        if (errors) {
          errors.forEach((error) => dispatch(setAlert(error.message, 'danger')));
        }
      }
    });
  dispatch({
    type: LOGOUT,
  });
};

// Delete Account
export const deleteAccount = (user, token, deleteToken) => async (dispatch) => {
  let response;
  try {
    response = await axios.get(`${process.env.REACT_APP_API_URL}/users/me`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  } catch (err) {
    if (err) {
      const errors = err.response?.data.message[0].messages;
      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.message, 'danger')));
      }
    }
  }

  if (response) {
    try {
      if (deleteToken === response.data.deleteToken) {
        const success = await axios.delete(`${process.env.REACT_APP_API_URL}/users/${user.id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        dispatch({
          type: LOGOUT,
        });
        return success;
      }
    } catch (err) {
      const { errors } = err.response.data;

      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
      }
    }
  }
};

// Forgot Password
export const passwordEmail = (userEmail) => async (dispatch) => {
  try {
    const res = await axios.post(`${process.env.REACT_APP_API_URL}/auth/forgot-password`, {
      email: userEmail,
    });

    dispatch({
      type: PASSWORD_EMAIL,
      payload: res.data,
    });
  } catch (err) {
    const { errors } = err;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: RESET_FAIL,
    });
  }
};

// Action Creators for Providers (Facebook and Google)
export const userLogin = (data) => async (dispatch) => {
  let completedModules;
  // calling and setting tracker data to object
  try {
    completedModules = await getCompleteModules();
  } catch (err) {
    return;
  }

  // Prevent user from being created until pregress object is complete
  function checkStatus() {
    if (Object.keys(completedModules).length !== 0) {
      // If completed modules object is complete register user
      axios
        .put(`${process.env.REACT_APP_API_URL}/users/${data.user.id}`, {
          completedModules,
          savedModules: [],
          userVolume: 1,
          savedAnswers: [],
          showTips: true,
          lowBandwidthMode: false,
          deleteToken: jwt.sign(data.user.email, process.env.REACT_APP_SECRET),
        })
        .then((response) => {
          // Handle success.
          // dispatch({
          //   type: REGISTER_SUCCESS,
          //   payload: response.data,
          // });
          dispatch({
            type: SET_MODULES,
            payload: completedModules,
          });
          // dispatch(loadUser(response.data.jwt));
          const payload = { jwt: data.jwt, user: response.data };
          dispatch({
            type: TEMP_LOGIN_SUCESS,
            payload,
          });
        })
        .catch((err) => {
          // Handle error.
          if (err) {
            const errors = err.response?.data.message[0].messages;
            if (errors) {
              errors.forEach((error) => dispatch(setAlert(error.message, 'danger')));
            }
          }

          // dispatch({
          //   type: REGISTER_FAIL,
          // });
        });
    } else {
      // If completedModules object is still empty, check again in 100ms
      setTimeout(checkStatus, 100);
    }
  }
  checkStatus();
};

export const userLogOut = () => ({
  type: LOGOUT,
});

// Set default empty progress tracker, will update if changes are made to DB
export const setDefaultModules = () => async (dispatch) => {
  try {
    const modules = await getCompleteModules();
    await axios.put(`${process.env.REACT_APP_API_URL}/progress-tracker`, { modules });
  } catch (err) {
    let errors;
    if (err.response) errors = err.response.data.message[0].messages;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.message, 'danger')));
    }
  }
};

// Set user saved modules
export const saveModule = (module, auth) => async (dispatch) => {
  if (auth) {
    const { token } = auth;
    const { savedModules, id } = auth.user.data;

    savedModules.push(module[0].id);

    try {
      const res = await axios.put(
        `${process.env.REACT_APP_API_URL}/users/${id}`,
        {
          savedModules,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      dispatch({
        type: SAVE_MODULE,
        payload: res,
      });
    } catch (err) {
      if (err) {
        const errors = err.response?.data.message[0].messages;
        if (errors) {
          errors.forEach((error) => dispatch(setAlert(error.message, 'danger')));
        }
      }
    }
  } else {
    const savedModules = JSON.parse(localStorage.getItem('savedModules'));

    savedModules.push(module[0].id);

    localStorage.setItem('savedModules', JSON.stringify(savedModules));
  }
};

// Remove user saved module
export const removeModule = (module, auth) => async (dispatch) => {
  if (auth.user) {
    const { token } = auth;
    const { savedModules, id } = auth.user.data;

    const index = savedModules.indexOf(module[0].id);

    savedModules.splice(index, 1);

    try {
      const res = await axios.put(
        `${process.env.REACT_APP_API_URL}/users/${id}`,
        {
          savedModules,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      dispatch({
        type: SAVE_MODULE,
        payload: res,
      });
    } catch (err) {
      if (err) {
        const errors = err.response?.data.message[0].messages;
        if (errors) {
          errors.forEach((error) => dispatch(setAlert(error.message, 'danger')));
        }
      }
    }
  } else {
    const savedModules = JSON.parse(localStorage.getItem('savedModules'));

    const index = savedModules.indexOf(module[0].id);
    savedModules.splice(index, 1);

    localStorage.setItem('savedModules', JSON.stringify(savedModules));
  }
};

// Save users volume preference
export const setUserVolume = (userId, token, volume, isMuted) => async (dispatch) => {
  try {
    const response = axios.put(
      `${process.env.REACT_APP_API_URL}/users/${userId}`,
      { userVolume: volume },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (response) {
      dispatch({
        type: SET_USER_VOLUME,
        payload: volume,
      });
    }
  } catch (err) {
    const { errors } = err.response.data;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
    }
  }
};

export const turnOffTooltip =
  (userId, token, toolType, toggle = true) =>
  async (dispatch) => {
    const toolTipObject = JSON.parse(localStorage.getItem('showTips'));

    if (userId && token) {
      try {
        const response = await axios.put(
          `${process.env.REACT_APP_API_URL}/users/${userId}`,
          { showTips: toggle },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      } catch (err) {
        let errors;
        if (err.response) errors = err.response.data.message[0].messages;
        if (errors) {
          errors.forEach((error) => dispatch(setAlert(error.message, 'danger')));
        }
      }

      if (toggle === true && toolType === 'module-onboarding') {
        toolTipObject.showTip.module = false;
        localStorage.setItem('showTips', JSON.stringify(toolTipObject));
        dispatch({
          type: SET_TIPS,
          payload: toolTipObject,
        });
      }

      if (toggle === true && toolType === 'browser-onboarding') {
        toolTipObject.showTip.browser = false;
        localStorage.setItem('showTips', JSON.stringify(toolTipObject));
        dispatch({
          type: SET_TIPS,
          payload: toolTipObject,
        });
      }

      if (toggle === false) {
        toolTipObject.show = false;
        localStorage.setItem('showTips', JSON.stringify(toolTipObject));
        dispatch({
          type: SET_TIPS,
          payload: toolTipObject,
        });
      }
    } else if (!userId && toolType === 'module-onboarding') {
      toolTipObject.showTip.module = false;
      toolTipObject.show = toggle;
      localStorage.setItem('showTips', JSON.stringify(toolTipObject));
      dispatch({
        type: SET_TIPS,
        payload: toolTipObject,
      });
    } else if (!userId && toolType === 'browser-onboarding') {
      toolTipObject.showTip.browser = false;
      toolTipObject.show = toggle;
      localStorage.setItem('showTips', JSON.stringify(toolTipObject));
      dispatch({
        type: SET_TIPS,
        payload: toolTipObject,
      });
    } else if (!userId && toggle === false) {
      toolTipObject.show = false;
      localStorage.setItem('showTips', JSON.stringify(toolTipObject));
      dispatch({
        type: SET_TIPS,
        payload: toolTipObject,
      });
    }
  };

// Low Bandwidth Mode
export const setLowBandwidthMode = (userId, token, isEnabled) => async (dispatch) => {
  try {
    const response = axios.put(
      `${process.env.REACT_APP_API_URL}/users/${userId}`,
      { lowBandwidthMode: isEnabled },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (response) {
      dispatch({
        type: SET_LOW_BANDWIDTH,
        payload: isEnabled,
      });
    }
  } catch (err) {
    const { errors } = err.response.data;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
    }
  }
};

// Save answers from completed learning modules to User object
export const saveAnswer = (user, answers, token) => async (dispatch) => {
  if (user) {
    const userId = user.data.id;
    const { savedAnswers } = user.data;
    const currentAnswer = savedAnswers.filter((answer) => answer.moduleName === answers.moduleName);
    if (currentAnswer.length === 0) {
      savedAnswers.push(answers);
    } else {
      const replaceIndex = savedAnswers.findIndex(
        (answer) => answer.moduleName === answers.moduleName
      );
      savedAnswers[replaceIndex] = answers;
    }

    try {
      const res = await axios.put(
        `${process.env.REACT_APP_API_URL}/users/${userId}`,
        { savedAnswers },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      dispatch({
        type: SAVE_ANSWER,
        payload: res,
      });
    } catch (err) {
      if (err) {
        const errors = err.response?.data.message[0].messages;

        if (errors) {
          errors.forEach((error) => dispatch(setAlert(error.message, 'danger')));
        }
      }
    }
  } else {
    const savedAnswers = JSON.parse(localStorage.getItem('savedAnswers'));
    const currentAnswer = savedAnswers.filter((answer) => answer.moduleName === answers.moduleName);
    if (currentAnswer.length === 0) {
      savedAnswers.push(answers);
    } else {
      const replaceIndex = savedAnswers.findIndex(
        (answer) => answer.moduleName === answers.moduleName
      );
      savedAnswers[replaceIndex] = answers;
    }
    localStorage.setItem('savedAnswers', savedAnswers);
  }
};

export const setAudio = (auth, volume, muted, play) => async (dispatch) => {
  if (auth !== null) {
    if (auth.user) {
      try {
        const response = axios.put(
          `${process.env.REACT_APP_API_URL}/users/${auth.user.data.id}`,
          { userVolume: volume },
          {
            headers: {
              Authorization: `Bearer ${auth.token}`,
            },
          }
        );
        if (response) {
          const tempAudio = JSON.parse(localStorage.getItem('audio'));
          if (volume) {
            tempAudio.volume = volume;
          }
          if (muted !== null) {
            tempAudio.isMuted = muted;
            tempAudio.volume = volume;
          }
          if (play) {
            tempAudio.play = play;
          }
          localStorage.setItem('audio', JSON.stringify(tempAudio));
          dispatch({
            type: SET_AUDIO,
            payload: tempAudio,
          });
        }
      } catch (err) {
        const { errors } = err.response.data;

        if (errors) {
          errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
        }
      }
    }
  } else {
    const tempAudio = JSON.parse(localStorage.getItem('audio'));
    if (volume) {
      tempAudio.volume = volume;
    }
    if (muted !== null) {
      tempAudio.isMuted = muted;
      tempAudio.volume = volume;
    }
    if (play) {
      tempAudio.play = play;
    }
    localStorage.setItem('audio', JSON.stringify(tempAudio));
    dispatch({
      type: SET_AUDIO,
      payload: tempAudio,
    });
  }
};
