import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { setLowBandwidthMode } from '../../../../actions/auth';

const Settings = ({ auth, dispatchSetLowBandwidthMode }) => {
  const [isLowBandwidth, setIsLowBandwidth] = useState(false);

  useEffect(() => {
    if (auth.user) {
      setIsLowBandwidth(auth.user.data.lowBandwidthMode);
    } else {
      const lowBandwidth = localStorage.getItem('lowBandwidth') === 'true';

      setIsLowBandwidth(lowBandwidth);
    }
  }, []);

  const handleLowBandwidthChange = async (e) => {
    setIsLowBandwidth(!isLowBandwidth);
    if (auth.user) {
      const user = auth.user.data;
      await dispatchSetLowBandwidthMode(user.id, auth.token, !isLowBandwidth);
    } else {
      localStorage.setItem('lowBandwidth', !isLowBandwidth);
    }
  };

  return (
    <>
      <h2>Settings</h2>
      <ul className="user-settings">
        <li>
          <input
            type="checkbox"
            name="low-bandwidth"
            checked={isLowBandwidth}
            onChange={handleLowBandwidthChange}
          />
          <label className="radio" htmlFor="option-1">
            Enable Low Bandwidth Mode
          </label>
        </li>
      </ul>
    </>
  );
};

Settings.propTypes = {
  auth: PropTypes.shape({
    token: PropTypes.string,
    user: PropTypes.objectOf(PropTypes.any),
    isAuthenticated: PropTypes.bool,
  }).isRequired,
  dispatchSetLowBandwidthMode: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { dispatchSetLowBandwidthMode: setLowBandwidthMode })(
  Settings
);
