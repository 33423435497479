import React, { useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { logout, setDefaultModules } from '../../actions/auth';

import logo from '../../assets/images/dementia-logo-white.png';
import logo2x from '../../assets/images/dementia-logo-white@2x.png';

const AuthNavigation = ({ auth, dispatchLogout }) => {
  const [showAuthNav, setShowAuthNav] = useState(false);

  const history = useHistory();
  const handleLogoutClick = () => {
    dispatchLogout();
    history.push('/');
  };

  const currentPath = useLocation();
  const showPaths = ['/', '/login', '/register', '/login-options', '/forgot-password', '/reset'];

  const urlCheck = () => {
    return showPaths.includes(currentPath.pathname);
  };

  useEffect(() => {
    setShowAuthNav(urlCheck());
    return history.listen((location, action) => {
      setShowAuthNav(urlCheck());
    });
  });

  const authLinks = (
    <ul className="auth-links">
      <li>
        <Link to="/dashboard">Dashboard</Link>
      </li>
      <li>
        <button type="button" className="btn-text" onClick={handleLogoutClick}>
          Logout
        </button>
      </li>
    </ul>
  );

  // Check what page the user is on inorder to show the right navigation link
  const containsLogin = window.location.href.indexOf('login') > -1;

  const guestLinks = (
    <ul className="auth-links">
      {containsLogin ? (
        <>
          <li className="hide-md">Don&apos;t have an account?</li>
          <li>
            <Link to="/register">Sign Up</Link>
          </li>
        </>
      ) : (
        <>
          <li className="hide-md">Already have an account?</li>
          <li>
            <Link to="/login">Log in</Link>
          </li>
        </>
      )}
    </ul>
  );

  return (
    <nav className={`auth-navigation ${showAuthNav} ${showAuthNav ? 'show' : 'hide-nav'}`}>
      <Link className="cracked-logo" to="/">
        <img srcSet={`${logo}, ${logo2x} 2x`} src={logo} alt="dementia in a new light logo" />
      </Link>
      {auth.isAuthenticated ? authLinks : guestLinks}
    </nav>
  );
};

AuthNavigation.propTypes = {
  auth: PropTypes.shape({
    isAuthenticated: PropTypes.bool,
  }).isRequired,
  dispatchLogout: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {
  dispatchLogout: logout,
  dispatchSetModules: setDefaultModules,
})(AuthNavigation);
