import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { debounce } from 'lodash';
import { setUserVolume, setAudio } from '../../actions/auth';
import GlobalAudio from '../utils/GlobalAudio';

import logo from '../../assets/images/dementia-logo-white.png';
import logo2x from '../../assets/images/dementia-logo-white@2x.png';
import logoDark from '../../assets/images/dementia-logo-black.png';
import logoDark2x from '../../assets/images/dementia-logo-black@2x.png';

const UserControls = ({
  auth,
  closeModal,
  hideControls,
  dispatchSetUserVolume,
  dispatchSetAudio,
  showGlobalMenu,
  backgroundActive,
  useAudio,
  backgroundMusicPlaying,
  setBackgroundMusicPlaying,
  audio,
  setAudioFile,
}) => {
  const [setVolume, toggle, volume, playing, setPlaying] = GlobalAudio(
    useAudio,
    backgroundMusicPlaying,
    setBackgroundMusicPlaying,
    audio,
    setAudioFile
  );
  const [toggleSound, setToggleSound] = useState(false);
  const [prevVolume, setPrevVolume] = useState(volume);
  const [audioParams, setAudioParams] = useState();
  const [volumeDebounce, setVolumeDebounce] = useState();
  const [isInfoPopupOpen, setIsInfoPopupOpen] = useState(false);
  const [pathname, setPathnamne] = useState();
  const popUp = useRef(null);
  const location = useLocation();

  const handleVolume = (newValue) => {
    if (auth.user) {
      dispatchSetAudio(auth, newValue, false, null);
    } else if (newValue >= 0.1) {
      dispatchSetAudio(null, newValue, false, null);
    } else {
      dispatchSetAudio(null, newValue, true, null);
    }
  };

  useEffect(() => {
    const debouncer = debounce(handleVolume, 300, {
      leading: false,
      trailing: true,
    });
    setVolumeDebounce(() => debouncer);
  }, []);

  useEffect(() => {
    if (auth.user) {
      setVolume(auth.user.data?.userVolume);
    } else if (localStorage.getItem('audio')) {
      const tempAudio = JSON.parse(localStorage.getItem('audio'));
      if (tempAudio.isLoaded === false) {
        tempAudio.isLoaded = true;
        setAudioParams(JSON.parse(localStorage.getItem('audio')));
        dispatchSetAudio(auth, tempAudio.volume, tempAudio.isMuted, tempAudio.play);
      } else {
        setAudioParams(auth.audio);
      }
    }
  }, [auth.user]);

  useEffect(() => {
    if (auth.audio) {
      setVolume(auth.audio.volume);
      if (auth.audio.isMuted === false && auth.audio.isPaused === false) {
        setPlaying(true);
      }
    }
  }, [auth.audio]);

  useEffect(() => {
    const closePopUpMenu = (e) => {
      if (popUp.current) {
        if (e.target === popUp.current.parentNode) {
          return;
        }

        if (e.target !== popUp.current && popUp.current.classList.contains('show')) {
          popUp.current.classList.remove('show');
        }
      }
    };
    window.addEventListener('mousedown', (e) => {
      closePopUpMenu(e);
    });
  });

  const handleChange = (event) => {
    setVolume(event.target.value);
    volumeDebounce(event.target.value);
  };

  const mute = async () => {
    if (volume > 0.1) {
      if (auth.user) {
        const user = auth.user.data;
        await dispatchSetUserVolume(user.id, 0);
      } else {
        dispatchSetAudio(null, 0, true, null);
      }
      setPrevVolume(volume);
      setVolume(0);
    } else {
      if (auth.user) {
        const user = auth.user.data;
        await dispatchSetUserVolume(user.id, 0);
      } else {
        dispatchSetAudio(null, prevVolume, false, null);
        setVolume(prevVolume);
      }
      setVolume(1);
    }
  };

  const handleToggleSound = (e) => {
    setToggleSound(!toggleSound);
    mute();
  };

  const toggleInfoPopup = () => {
    if (isInfoPopupOpen) {
      setIsInfoPopupOpen(false);
    } else {
      setIsInfoPopupOpen(true);
    }
  };

  const closeInfoPopup = () => {
    setIsInfoPopupOpen(false);
  };

  const whiteLogo =
    location.pathname === '/account' ||
    location.pathname === '/saved' ||
    location.pathname === '/about' ||
    location.pathname === '/resources' ||
    location.pathname === '/privacy' ||
    location.pathname === '/terms-and-conditions' ||
    location.pathname === '/instructions' ||
    location.pathname === '/reset-password' ||
    location.pathname === '/dashboard';

  return (
    <nav
      className={`user-controls ${hideControls} ${hideControls ? 'hide' : 'show'}`}
      aria-label="Back to home"
    >
      <Link
        className="bottom-logo"
        to="/dashboard"
        onClick={() => closeModal()}
        aria-label="Dementia in New Light logo"
      >
        {showGlobalMenu || whiteLogo ? (
          <img
            className={`logo-icon ${showGlobalMenu || whiteLogo ? 'light' : 'dark'} ${
              backgroundActive ? 'light' : 'dark'
            }`}
            src={logo}
            srcSet={`${logo}, ${logo2x} 2x`}
            alt="dementia a new light logo"
          />
        ) : (
          <img
            className={`logo-icon ${showGlobalMenu || whiteLogo ? 'light' : 'dark'}`}
            src={backgroundActive ? logo : logoDark}
            srcSet={`${backgroundActive || whiteLogo ? logo : logoDark}, ${
              backgroundActive || whiteLogo ? logo2x : logoDark2x
            } 2x`}
            alt="dementia a new light logo"
          />
        )}
      </Link>
      <div className="controller">
        <div className="controls">
          <div className="control">
            <Link className="clickable" to="/account" onClick={() => closeModal()}>
              <span className="visually-hidden">account</span>
              <i className="user-icon" aria-hidden="true" />
            </Link>
          </div>
          <div className="control" to="/">
            <button type="button" onClick={toggleInfoPopup}>
              <span className="visually-hidden">info</span>
              <i className="info-icon" aria-hidden="true" />
            </button>
          </div>
          <div
            className={['tool-tip-popup', 'info-popup', isInfoPopupOpen ? '' : 'popup-hidden'].join(
              ' '
            )}
          >
            <p>
              Dementia in New Light aims to reimagine dementia and dementia care by challenging
              stigma associated with dementia and illustrating the key principles of a relational
              approach to dementia care.
            </p>
            <div className="cta">
              <Link className="btn btn-primary cta-btn" to="/about" onClick={closeInfoPopup}>
                <span>Read more</span>
              </Link>
            </div>
          </div>
          <div className={`control${location.pathname === '/instructions' ? ' hide' : ''}`}>
            <button type="button" onClick={handleToggleSound}>
              <span className="visually-hidden">sound options</span>
              <i className={volume < 0.1 ? 'mute-icon' : 'sound-icon'} aria-hidden="true" />
            </button>
          </div>
        </div>
        <div className="controls controls-volume-small">
          <div className="control">
            <button type="button" onClick={handleToggleSound}>
              <span className="visually-hidden">sound options</span>
              <i className={volume < 0.1 ? 'mute-icon' : 'sound-icon'} aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>
    </nav>
  );
};

UserControls.defaultProps = {
  useAudio: false,
  backgroundActive: false,
};

UserControls.propTypes = {
  auth: PropTypes.shape({
    token: PropTypes.string,
    isAuthenticated: PropTypes.bool,
    user: PropTypes.objectOf(PropTypes.any),
    audio: PropTypes.objectOf(PropTypes.any),
  }).isRequired,
  closeModal: PropTypes.func.isRequired,
  hideControls: PropTypes.bool.isRequired,
  dispatchSetUserVolume: PropTypes.func.isRequired,
  dispatchSetAudio: PropTypes.func.isRequired,
  showGlobalMenu: PropTypes.bool.isRequired,
  backgroundActive: PropTypes.bool,
  useAudio: PropTypes.bool,
  backgroundMusicPlaying: PropTypes.bool.isRequired,
  setBackgroundMusicPlaying: PropTypes.func.isRequired,
  audio: PropTypes.objectOf(PropTypes.any),
  setAudioFile: PropTypes.func.isRequired,
};

UserControls.defaultProps = {
  audio: null,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {
  dispatchSetUserVolume: setUserVolume,
  dispatchSetAudio: setAudio,
})(UserControls);
