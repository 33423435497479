import React, { useEffect, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import PropTypes from 'prop-types';

import marked from 'marked';

const MultipleChoice = ({
  handleSubmit,
  handleClear,
  handleSelection,
  handleType,
  disabled,
  reflectionQuestion,
  selectedNode,
  showWritten,
  toggleWritten,
  handleToggle,
  moduleName,
  showAnswers,
  setToggleWritten,
  setShowAnswers,
  typedAnswer,
  multipleChoiceField,
  inputRefs,
}) => {
  // Set up ref for input node
  const addNode = useRef();
  // Set up ref for textarea
  const textEntry = useRef();

  // If a user has selected a radio button and clicked to type written answer,
  // Add Append input node
  useEffect(() => {
    if (addNode.current && selectedNode) {
      addNode.current.appendChild(selectedNode);
      Array.from(addNode.current.children).map((elem) => elem.classList.add('selected'));
    }
  });

  useEffect(() => {
    if (textEntry.current && textEntry.current.value === '') {
      textEntry.current.focus();
    }
  });

  return (
    <form className="mChoiceForm">
      <h3 dangerouslySetInnerHTML={{ __html: marked(reflectionQuestion.Question) }} />
      <CSSTransition
        in={showAnswers}
        timeout={300}
        classNames="display"
        unmountOnExit
        appear
        onExited={() => setToggleWritten(true)}
      >
        <fieldset ref={multipleChoiceField}>
          <div
            className="input-button"
            ref={(ref) => {
              if (!inputRefs.current.includes(ref)) {
                inputRefs.current.push(ref);
              }
            }}
          >
            <input
              type="radio"
              name={`${moduleName}-answer`}
              id={`${moduleName}-answer1`}
              value={reflectionQuestion.answer1}
              onClick={handleSelection}
              onKeyDown={handleSelection}
            />
            <label htmlFor="answer1">{reflectionQuestion.answer1}</label>
          </div>
          <div
            className="input-button"
            ref={(ref) => {
              if (!inputRefs.current.includes(ref)) {
                inputRefs.current.push(ref);
              }
            }}
          >
            <input
              type="radio"
              name={`${moduleName}-answer`}
              id={`${moduleName}-answer2`}
              value={reflectionQuestion.answer2}
              onClick={handleSelection}
              onKeyDown={handleSelection}
            />
            <label htmlFor="answer2">{reflectionQuestion.answer2}</label>
          </div>
          <div
            className="input-button"
            ref={(ref) => {
              if (!inputRefs.current.includes(ref)) {
                inputRefs.current.push(ref);
              }
            }}
          >
            <input
              type="radio"
              name={`${moduleName}-answer`}
              id={`${moduleName}-answer3`}
              value={reflectionQuestion.answer3}
              onClick={handleSelection}
              onKeyDown={handleSelection}
            />
            <label htmlFor="answer3">{reflectionQuestion.answer3}</label>
          </div>
          <div
            className="input-button"
            ref={(ref) => {
              if (!inputRefs.current.includes(ref)) {
                inputRefs.current.push(ref);
              }
            }}
          >
            <input
              type="radio"
              name={`${moduleName}-answer`}
              id={`${moduleName}-answer4`}
              value={reflectionQuestion.answer4}
              onClick={handleSelection}
              onKeyDown={handleSelection}
            />
            <label htmlFor="answer4">{reflectionQuestion.answer4}</label>
          </div>
        </fieldset>
      </CSSTransition>
      <CSSTransition in={showWritten} timeout={300} classNames="display" unmountOnExit>
        <button
          type="button"
          name="add-written"
          id="add-written"
          className="add-written"
          onClick={handleToggle}
        >
          Add written answer
          <span>+</span>
        </button>
      </CSSTransition>
      <CSSTransition
        in={toggleWritten}
        timeout={300}
        classNames="display"
        unmountOnExit
        onExited={() => {
          setShowAnswers(true);
        }}
      >
        <>
          <div ref={addNode} className="addNode" />
          <textarea
            placeholder="Type your answer"
            onChange={handleType}
            className={`${showWritten}`}
            value={typedAnswer}
            ref={textEntry}
          />
        </>
      </CSSTransition>
      <div className="actions">
        <button
          type="button"
          name="submit"
          className="submit"
          disabled={disabled}
          onClick={handleSubmit}
        >
          Continue
          <span className="next-arrow" />
        </button>
        <CSSTransition in={showWritten} timeout={300} classNames="display" unmountOnExit>
          <button
            type="button"
            name="clear"
            className="clear"
            disabled={disabled}
            onClick={handleClear}
          >
            Clear
            <span className="clear-x" />
          </button>
        </CSSTransition>
      </div>
    </form>
  );
};

MultipleChoice.defaultProps = {
  selectedNode: null,
};

MultipleChoice.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleClear: PropTypes.func.isRequired,
  handleSelection: PropTypes.func.isRequired,
  handleType: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  reflectionQuestion: PropTypes.objectOf(PropTypes.any).isRequired,
  selectedNode: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  toggleWritten: PropTypes.bool.isRequired,
  handleToggle: PropTypes.func.isRequired,
  showWritten: PropTypes.bool.isRequired,
  moduleName: PropTypes.string.isRequired,
  showAnswers: PropTypes.bool.isRequired,
  setToggleWritten: PropTypes.func.isRequired,
  setShowAnswers: PropTypes.func.isRequired,
  typedAnswer: PropTypes.string.isRequired,
  multipleChoiceField: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]).isRequired,
  inputRefs: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default MultipleChoice;
