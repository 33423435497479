import * as THREE from 'three';

class MainCamera extends THREE.PerspectiveCamera {
  constructor(fov, aspect, near, far) {
    super(fov, aspect, near, far);

    this.delegate = null;

    this.curLookAt = new THREE.Vector3();
    this.curPos = new THREE.Vector3();

    this.destRot = new THREE.Vector3();
    this.destPos = new THREE.Vector3();

    this.clock = new THREE.Clock();

    this.rot = new THREE.Vector3();

    this.isSelected = false;
    this.distanceSelected = 0;
    this.maxSelectedDistance = 0;
    this.isSelectComplete = false;
    this.isAnimating = false;
  }

  setDestinationPosition(pos) {
    this.isAnimating = true;
    this.destPos = pos;
  }

  setDestinationRotation(rot) {
    this.destRot = rot;
  }

  selectShard(maxDistance) {
    this.isSelected = true;
    this.distanceSelected = 0;
    this.maxSelectedDistance = maxDistance;
  }

  animate() {
    if (this.isSelected) {
      if (this.distanceSelected >= this.maxSelectedDistance) {
        if (this.delegate != null) {
          if (!this.isSelectComplete) {
            this.delegate.shardSelectCompleted();
          }
        }

        this.isSelectComplete = true;
      } else {
        const rate = 1.5;
        this.translateZ(-rate);
        this.distanceSelected += rate;
      }
    } else {
      const delta = this.clock.getDelta();

      const lerpRate = 2;

      const newPos = this.position.lerp(this.destPos, lerpRate * delta);

      this.position.set(newPos.x, newPos.y, newPos.z);

      if (this.isAnimating) {
        const dist = this.position.distanceTo(this.destPos);
        if (dist <= 4) {
          this.isAnimating = false;
        }
      }

      this.rot.x = this.rotation.x;
      this.rot.y = this.rotation.y;
      this.rot.z = this.rotation.z;

      const newRot = this.rot.lerp(this.destRot, lerpRate * delta);
      this.rotation.set(newRot.x, newRot.y, newRot.z);
    }
    this.updateProjectionMatrix();
  }
}

export default MainCamera;
