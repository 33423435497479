import React, { useState, useEffect, useRef, Fragment } from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';
import marked from 'marked';
import LearningModuleControls from '../LearningModuleControls';

// ImageGallery component is set up to receive an array of images.
const ImageGallery = ({ module, inViewport, checkModuleCompletion, targetModule }) => {
  const [cards, setArray] = useState([]);
  const [activeCard, setActiveCard] = useState();
  const [isComplete, setIsComplete] = useState(false);
  const [disableNext, setDisableNext] = useState(false);
  const [disablePrev, setDisablePrev] = useState(true);
  const [target, setTarget] = useState();

  useEffect(() => {
    if (targetModule !== null || (targetModule !== undefined && targetModule?.id !== 'intro'))
      setTarget(targetModule);
  }, [targetModule]);

  const cardList = useRef(null);
  const cardRefs = useRef([]);
  const captionRef = useRef(null);

  const APIURL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    // Create an array in State from anything with a class of 'card'
    setArray(Array.from(cardRefs.current));
  }, [cardRefs]);

  useEffect(() => {
    setActiveCard(cards[cards.length - 1]);
  }, [cards]);

  const nextCard = () => {
    // Find the total length of the array
    const lastCard = cards.length - 1;
    // const nextButton = document.querySelector('.next');
    // Splice the top card off of the array, and an animation class on it
    const splicedCard = cards.splice(lastCard, 1);
    // remove pointer events while the animation runs to avoid any errors
    // nextButton.classList.add('click-event');
    splicedCard[0].classList.add('next-animate');
    // Wait for the animation to run, then remove class and append to the back of the deck
    setArray([splicedCard[0], ...cards]);
    // nextButton.classList.remove('click-event');
    cardList.current.insertBefore(splicedCard[0], cardList.current.firstChild);
    setTimeout(() => {
      splicedCard[0].classList.remove('next-animate');
    }, 1000);
    if (!isComplete) {
      setIsComplete(true);
      checkModuleCompletion(null, target);
    }
    if (activeCard.id === cardRefs.current[cardRefs.current.length - 1].id) {
      setDisablePrev(false);
    }
    if (activeCard.id === cardRefs.current[1].id) {
      setDisableNext(true);
    }
  };

  const prevCard = () => {
    const firstCard = 0;
    // const prevButton = document.querySelector('.prev');
    // prevButton.classList.add('click-event');
    // Take the bottom card from the deck and add a reverse animation
    const splicedCard = cards.splice(firstCard, 1);
    splicedCard[0].classList.add('prev-animate');
    // Append to top of deck
    setArray([...cards, splicedCard[0]]);
    // const cardList = document.querySelector('.cardList');
    cardList.current.appendChild(splicedCard[0]);
    setTimeout(() => {
      // prevButton.classList.remove('click-event');
      // Remove animation
      splicedCard[0].classList.remove('prev-animate');
    }, 1000);
    if (!isComplete) {
      setIsComplete(true);
      checkModuleCompletion(null, target);
    }
    if (activeCard.id === cardRefs.current[cardRefs.current.length - 2].id) {
      setDisablePrev(true);
    }
    if (activeCard.id === cardRefs.current[0].id) {
      setDisableNext(false);
    }
  };

  useEffect(() => {
    if (captionRef.current) {
      const captionLink = captionRef.current.querySelector('a');
      if (captionLink) {
        captionLink.target = '_blank';
        captionLink.rel = 'noreferrer noopener';
      }
    }
  }, [captionRef.current, activeCard]);

  return (
    <div className="module-wrapper" id={module.id}>
      <CSSTransition in={inViewport} timeout={1000} classNames="learning-module-ani">
        <div className="gallery-wrapper">
          <div className="container-card">
            <div className="card-stack">
              <ul className="card-list" ref={cardList}>
                {/* Map over array and display any media included as list items stacked like cards */}
                {module.module_type[0].Photos
                  ? module.module_type[0].Photos.map((image, index) => {
                      const count = (index - module.module_type[0].Photos.length) * -1;
                      return (
                        <Fragment key={image.id}>
                          <li
                            className="card"
                            id={image.id}
                            ref={(el) => {
                              cardRefs.current[index] = el;
                            }}
                          >
                            <div className="image-container">
                              <img
                                src={`${APIURL + image.photo.url}`}
                                alt={image.photo.alternativeText}
                              />
                            </div>
                            {activeCard?.id === image.id.toString() && (
                              <div className="body-text">
                                {module.module_type[0].Photos.length > 1 && (
                                  <p className="count">
                                    {count}
                                    {' / '}
                                    {module.module_type[0].Photos.length}
                                  </p>
                                )}
                                {module.module_type[0].Photos.length > 1 && (
                                  <p className="instruction-text">
                                    Please use the arrows to go through the slides
                                  </p>
                                )}
                                {image.caption && (
                                  <div
                                    ref={captionRef}
                                    className="description"
                                    dangerouslySetInnerHTML={{ __html: marked(image.caption) }}
                                  />
                                )}
                              </div>
                            )}
                          </li>
                        </Fragment>
                      );
                    })
                  : null}
              </ul>
            </div>
          </div>
          <LearningModuleControls
            module={module}
            forwards={nextCard}
            backwards={prevCard}
            cards={cards}
            disableNext={disableNext}
            disablePrev={disablePrev}
          />
        </div>
      </CSSTransition>
    </div>
  );
};

ImageGallery.propTypes = {
  module: PropTypes.objectOf(PropTypes.any).isRequired,
  inViewport: PropTypes.bool.isRequired,
  checkModuleCompletion: PropTypes.func.isRequired,
  targetModule: PropTypes.any,
};

ImageGallery.defaultProps = {
  targetModule: null,
};

export default ImageGallery;
