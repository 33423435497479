import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';
import marked from 'marked';
import LearningModuleControls from '../LearningModuleControls';

const TextModule = ({ module, inViewport, checkModuleCompletion, targetModule }) => {
  const [current, setCurrent] = useState();
  const [displayCitation, setDisplayCitation] = useState(false);
  const [currentItem, setCurrentItem] = useState(0);
  const [disableNext, setDisableNext] = useState(false);
  const [disablePrev, setDisablePrev] = useState(false);
  const [target, setTarget] = useState();

  useEffect(() => {
    if (targetModule !== null || (targetModule !== undefined && targetModule?.id !== 'intro'))
      setTarget(targetModule);
  }, [targetModule]);

  const textValues = module.module_type[0].text_snippet;
  const itemCount = textValues.length;

  // let currentItem = 1; // starts at 1

  const updateCurrentText = (incValue = true) => {
    if (incValue && currentItem < itemCount - 1) setCurrentItem(currentItem + 1);
    setCurrent(currentItem);
    if (currentItem === itemCount - 1) {
      setDisplayCitation(true);
    } else {
      setDisplayCitation(false);
    }
  };

  useEffect(() => {
    updateCurrentText(false); // set current on load

    // Swap our text based on inViewport
    if (inViewport) {
      window.timer = setInterval(() => {
        updateCurrentText(); //
      }, 14000);
    } else {
      clearInterval(window.timer);
    }

    if (itemCount === 1 && target?.id === module.uid) {
      checkModuleCompletion(null, target);
    }

    // unmount
    return () => {
      clearInterval(window.timer);
    };
  }, [inViewport]);

  // External links open in new window
  useEffect(() => {
    const links = document.querySelectorAll('.text-wrapper a');
    links.forEach((link) => {
      const targetLink = link;
      if (link.hostname !== window.location.hostname) {
        targetLink.target = '_blank';
        targetLink.rel = 'noreferrer noopener';
      }
    });
  }, []);

  const [index, setIndex] = useState(0);

  // const mod = (n, m) => ((n % m) + m) % m;

  // const forwards = useCallback(
  //   () => setIndex((state) => mod(state + 1, textValues.length)),
  //   [setIndex, textValues]
  // );

  const forwards = () => {
    if (index < itemCount - 1) setIndex(index + 1);
    if (index === itemCount - 1) {
      setDisableNext(true);
      setDisablePrev(false);
    }
  };

  // const backwards = useCallback(
  //   () => setIndex((state) => mod(state - 1, textValues.length)),
  //   [setIndex, textValues]
  // );

  const backwards = () => {
    if (index > 0) setIndex(index - 1);
  };

  useEffect(() => {
    if (index === 0) {
      setDisablePrev(true);
    } else if (disablePrev === true) {
      setDisablePrev(false);
    }
    if (index === itemCount - 1) {
      setDisableNext(true);
      checkModuleCompletion(null, target);
    } else if (disableNext === true) {
      setDisableNext(false);
    }
  }, [index]);

  return (
    <div className="module-wrapper">
      <CSSTransition in={inViewport} timeout={1000} classNames="learning-module-ani">
        <div className="text-wrapper">
          <div className="text-wrap" data-count={`${itemCount}`}>
            <div className="item-wrap current">
              <p
                className="text"
                dangerouslySetInnerHTML={{ __html: marked(textValues[index].text_snippet) }}
              />
            </div>
          </div>
          <LearningModuleControls
            module={module}
            textValues={textValues}
            backwards={backwards}
            forwards={forwards}
            disableNext={disableNext}
            disablePrev={disablePrev}
          />
          {itemCount > 1 && (
            <div className="body-text">
              <p className="count">
                {index + 1}
                {' / '}
                {itemCount}
              </p>
              <p className="instruction-text">Please use the arrows to go through the slides</p>
            </div>
          )}
        </div>
      </CSSTransition>
    </div>
  );
};

TextModule.propTypes = {
  module: PropTypes.objectOf(PropTypes.any).isRequired,
  inViewport: PropTypes.bool.isRequired,
  checkModuleCompletion: PropTypes.func.isRequired,
  targetModule: PropTypes.any,
};

TextModule.defaultProps = {
  targetModule: null,
};

export default TextModule;
