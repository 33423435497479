import React, { useEffect, useState, useRef, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';
import _ from 'lodash';
import { useInView } from 'react-intersection-observer';
import LearningModule from './LearningModule';
import ChildTopicCompletion from '../childTopics/childTopicCompletion/ChildTopicCompletion';
import NextSteps from '../childTopics/childTopicCompletion/NextSteps';
import TextModule from './learning-modules/TextModule';
import LearningModuleIntro from './LearningModuleIntro';

import Particles from '../../shared/Particles';

import { sortChildTopics, sortParentTopics } from '../../utils/TopicBrowserUtils';

const LearningModuleContainer = ({
  childTopic,
  moduleRefs,
  handleScroll,
  targetModule,
  progressTracker,
  activeModule,
  parentCompletion,
  markComplete,
  top,
  isLoaded,
  backgroundActive,
  setBackgroundActive,
}) => {
  const [parent, setParent] = useState();
  const [nextChild, setNextChild] = useState();
  const [parentLink, setParentLink] = useState();

  const { ref, inView, entry } = useInView({
    threshold: 0.25,
  });

  useEffect(() => {
    setBackgroundActive(inView);
  }, [inView]);

  useEffect(() => {
    if (parentCompletion) {
      setParent(parentCompletion);
    }
  }, [parentCompletion, progressTracker]);

  useEffect(() => {
    if (activeModule && parent) {
      const currentParent = activeModule.parentTopics.filter(
        (parentModule) => parentModule.id === childTopic.parent_topic.id
      )[0];
      const remainingIds = parent.child_topics
        .filter((childModule) => !childModule.completed)
        .map((childModule) => childModule.id)
        .filter((childId) => childId !== childTopic.id);
      const remainingChildren = sortChildTopics(currentParent.child_topics);
      const currentChild = remainingChildren.filter(
        (child) => child.id === activeModule.childTopic.id
      );
      const currentIndex = remainingChildren.indexOf(currentChild[0]);
      const nextInOrder = remainingChildren[currentIndex + 1];

      if (nextInOrder !== undefined) {
        setNextChild(nextInOrder);
        setParentLink(currentParent.UID);
      } else {
        const parentIndex = sortParentTopics(activeModule.parentTopics).indexOf(currentParent);
        const nextParentInOrder =
          parentIndex + 1 <= activeModule.parentTopics.length - 1
            ? sortParentTopics(activeModule.parentTopics)[parentIndex + 1]
            : sortParentTopics(activeModule.parentTopics)[0];
        if (nextParentInOrder !== undefined) {
          const moveToNextParent = sortChildTopics(nextParentInOrder.child_topics)[0];
          setNextChild(moveToNextParent);
          setParentLink(nextParentInOrder.UID);
        } else {
          const moveToNextParent =
            parentIndex + 1 <= activeModule.parentTopics.length - 1
              ? sortChildTopics(
                  sortParentTopics(activeModule.parentTopics)[parentIndex + 1].child_topics
                )[0]
              : sortChildTopics(sortParentTopics(activeModule.parentTopics)[0].child_topics)[0];
          setNextChild(moveToNextParent);
          setParentLink(activeModule.parentTopics[0].UID);
        }
      }
    }
  }, [activeModule, parent]);

  return (
    <div
      className={`learning-modules-container ${backgroundActive ? 'bg-active' : 'bg-disabled'} ${
        childTopic.parent_topic.UID
      }`}
      onScroll={handleScroll}
      ref={top}
    >
      <LearningModuleIntro
        desc={childTopic.description}
        moduleRefs={moduleRefs}
        isLoaded={isLoaded}
      />
      {childTopic.learning_modules.map((module) => {
        return (
          <LearningModule
            key={module.id}
            module={module}
            moduleRefs={moduleRefs}
            targetModule={targetModule}
            markComplete={markComplete}
          />
        );
      })}
      <Particles section={childTopic.parent_topic.UID} />
      <section className="child-topic-completion" ref={ref}>
        <ChildTopicCompletion parentName={childTopic.parent_topic.name} childTopic={childTopic} />
        {nextChild && (
          <NextSteps
            childName={childTopic.name}
            nextChild={nextChild}
            top={top}
            parentLink={parentLink}
          />
        )}
      </section>
    </div>
  );
};

LearningModuleContainer.defaultProps = {
  targetModule: null,
  parentCompletion: null,
};

LearningModuleContainer.propTypes = {
  childTopic: PropTypes.objectOf(PropTypes.any).isRequired,
  moduleRefs: PropTypes.arrayOf(PropTypes.any).isRequired,
  handleScroll: PropTypes.func.isRequired,
  targetModule: PropTypes.objectOf(PropTypes.any),
  progressTracker: PropTypes.objectOf(PropTypes.any).isRequired,
  activeModule: PropTypes.objectOf(PropTypes.any).isRequired,
  parentCompletion: PropTypes.objectOf(PropTypes.any),
  markComplete: PropTypes.func.isRequired,
  top: PropTypes.objectOf(PropTypes.any).isRequired,
  isLoaded: PropTypes.bool.isRequired,
  backgroundActive: PropTypes.bool.isRequired,
  setBackgroundActive: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  progressTracker: state.progressTracker,
  activeModule: state.activeModule,
});

export default connect(mapStateToProps)(LearningModuleContainer);
