import React from 'react';
import { Link } from 'react-router-dom';

import PropTypes from 'prop-types';

const MobileChildTopicTopNav = ({ topic, topicUid, completed, max, isVisible }) => {
  return (
    <div className={['mobile-sub-top-nav', 'sub-top-nav', isVisible ? 'active' : ''].join(' ')}>
      <Link className="sub-top-nav-back-btn" type="button" to="/dashboard" />
      <div className={`sub-top-nav-topic sub-top-nav-${topicUid}`}>
        {topic === 'Current Culture of Dementia Care'
          ? `Current Culture \n of Dementia Care`
          : topic}
      </div>
      <div className="sub-topic-status">
        <div className="sub-progress-bar">
          <div
            className="sub-progress"
            style={{
              width: `${(completed / max) * 100}%`,
            }}
          />
        </div>
        <span className="sub-topic-status-progress">
          {completed}
          /
          {max}
        </span>
      </div>
    </div>
  );
};

MobileChildTopicTopNav.propTypes = {
  topic: PropTypes.string.isRequired,
  topicUid: PropTypes.string.isRequired,
  completed: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  isVisible: PropTypes.bool.isRequired,
};

export default MobileChildTopicTopNav;
