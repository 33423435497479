import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { sortChildTopics } from '../../utils/TopicBrowserUtils';

const MenuChildTopics = ({ parentURL, trackerChildTopics, childMenu, closeMenu, parentID }) => {
  const childTopicMenu = childMenu.filter((obj) => {
    return parseInt(obj.parent_topic, 10) === parseInt(parentID, 10);
  });

  return (
    <div>
      {trackerChildTopics &&
        sortChildTopics(childTopicMenu).map((childMenuItem) => {
          const complete = trackerChildTopics.filter((obj) => obj.id === childMenuItem.id)[0]
            .completed;

          return (
            <Link
              to={`/content/${parentURL}/${childMenuItem.uid}`}
              key={childMenuItem.id}
              className="child-link"
              onClick={closeMenu}
              aria-label={childMenuItem.name}
            >
              <div className="child-topics">
                <div>
                  <span className="child-title">{childMenuItem.name}</span>
                  <div>
                    <span className={complete ? 'explored' : 'not-explored'}>topic explored</span>
                    <span className={`explore-radio ${complete ? 'completed' : 'not-completed'}`} />
                  </div>
                </div>
                <span className="child-link">&gt;</span>
              </div>
            </Link>
          );
        })}
    </div>
  );
};

MenuChildTopics.propTypes = {
  parentURL: PropTypes.string.isRequired,
  parentID: PropTypes.string.isRequired,
  trackerChildTopics: PropTypes.arrayOf(PropTypes.any).isRequired,
  childMenu: PropTypes.arrayOf(PropTypes.any).isRequired,
  closeMenu: PropTypes.func.isRequired,
};

export default MenuChildTopics;
