import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const ParentCompleted = ({ topic, activeModule }) => {
  const [loading, setLoading] = useState(true);
  const [nextModule, setNextModule] = useState({});

  useEffect(() => {
    const currentIndex = activeModule.parentTopics.findIndex((parent) => parent.id === topic.id);
    let nextIndex;
    if (currentIndex + 1 < activeModule.parentTopics.length) {
      nextIndex = currentIndex + 1;
    } else {
      nextIndex = 0;
    }

    setNextModule(activeModule.parentTopics[nextIndex]);

    setLoading(false);
  }, [activeModule]);

  return (
    !loading && (
      <section className="parent-completed-overlay">
        <div className="completed-popup">
          <div className="completed-checkmark">
            <div className="image-container">
              <div className="checkmark" aria-hidden="true" />
            </div>
          </div>
          <div className="completed-module-info">
            <h1>Complete</h1>
            <p className="completed-description">{topic.CompletedDescription}</p>
          </div>
          <div className="completed-share-container">
            <h2>Share</h2>
            <div className="share-links-container">
              <div className="share-link share-linkedin" />
              <div className="share-link share-twitter" />
              <div className="share-link share-facebook" />
            </div>
          </div>
          <div className="completed-more-info-container">
            <h2>{`More on ${topic.name}`}</h2>
            <div className="more-info-link-container">
              {topic.info_links.map((link) => {
                return (
                  <Link to={link.url} id={link.id} className="more-info-link">
                    {`${link.LinkTitle}`}
                  </Link>
                );
              })}
            </div>
          </div>
          <div className="next-topic-overlay">
            <h2>{nextModule.name}</h2>
            <Link to={`/content/${nextModule.UID}`} className="next-button-overlay">
              {`Explore ${nextModule.name}`}
              <div className="next-arrow" />
            </Link>
          </div>
        </div>
      </section>
    )
  );
};

ParentCompleted.propTypes = {
  topic: PropTypes.objectOf(PropTypes.any).isRequired,
  activeModule: PropTypes.objectOf(PropTypes.any).isRequired,
};

const mapStateToProps = (state) => ({
  activeModule: state.activeModule,
});

export default connect(mapStateToProps)(ParentCompleted);
