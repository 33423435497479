import axios from 'axios';

import {
  LOAD_MENU,
  LOAD_ALL_PARENTS,
  LOAD_PARENT,
  LOAD_ALL_CHILDREN,
  LOAD_CHILD,
  LOAD_LEARNING_MODULE,
  INIT_LOAD_PARENT,
  CHILD_LOADING,
} from './types';

import { setAlert } from './alert';

// Load all Parent Topics
export const loadMenu = () => async (dispatch) => {
  try {
    const menuItems = await axios.get(`${process.env.REACT_APP_API_URL}/menu`);

    if (menuItems.data.menu.length > 0) {
      dispatch({
        type: LOAD_MENU,
        payload: menuItems.data,
      });
    }
  } catch (err) {
    if (err) {
      const errors = err.response?.data.message[0].messages;
      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.message, 'danger')));
      }
    }
  }
};

export const loadAllParents = () => async (dispatch) => {
  axios
    .get(`${process.env.REACT_APP_API_URL}/parent-topics`)
    .then((response) => {
      // Store name and UIDs of parent topics
      const parentNames = [];
      // Iterate through all parent topics
      response.data.forEach((parent) => {
        // Temp object to group name and uid
        const parentInfo = {};
        parentInfo.name = parent.name;
        parentInfo.uid = parent.UID;
        parentInfo.childTopics = parent.child_topics;
        // Add name and uid to end of parentNames object
        parentNames.push(parentInfo);
      });

      // Recursive function to prevent state update until parentNames has content
      function checkStatus() {
        // if the array is empty, wait 500ms and check again,
        // if array has content, update state
        if (parentNames.length > 0) {
          dispatch({
            type: LOAD_ALL_PARENTS,
            payload: response.data,
          });
        } else {
          setTimeout(checkStatus, 500);
        }
      }
      checkStatus();
    })
    .catch((err) => {
      // Collect any errors and display in Alert banner
      let errors;
      if (err.response) errors = err.response.data.message[0].messages;

      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.message, 'danger')));
      }
    });
};

// Load Parent Topic into redux state
export const loadParent = (parentTopic) => async (dispatch) => {
  dispatch({
    type: INIT_LOAD_PARENT,
  });

  axios
    .get(`${process.env.REACT_APP_API_URL}/parent-topics`)
    .then((response) => {
      // Find the Parent Topic with UID that matches URL
      const activeParent = response.data.filter((parent) => {
        return parent.UID === parentTopic;
      });

      // Dispatch to redux
      dispatch({
        type: LOAD_PARENT,
        payload: activeParent[0],
      });
    })
    .catch((err) => {
      // Collect any errors and display in Alert banner
      let errors;
      if (err.response) errors = err.response.data.message[0].messages;

      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.message, 'danger')));
      }
    });
};

// Load All Child Topics for Routes
export const loadAllChildren = () => async (dispatch) => {
  axios
    .get(`${process.env.REACT_APP_API_URL}/child-topics`)
    .then((response) => {
      // Set up array to store child topic names
      const childNames = [];
      response.data.forEach((child) => {
        // for each child, add name and uid to list
        const childInfo = {};
        childInfo.name = child.name;
        childInfo.uid = child.uid;
        childInfo.order = child.order;
        if (child.parent_topic) childInfo.parent = child.parent_topic.UID;
        childNames.push(child);
      });
      // Recursive function to prevent state update until parentNames has content
      function checkStatus() {
        // if the array is empty, wait 500ms and check again,
        // if array has content, update state
        if (childNames.length > 0) {
          dispatch({
            type: LOAD_ALL_CHILDREN,
            payload: childNames,
          });
        } else {
          setTimeout(checkStatus, 500);
        }
      }
      checkStatus();
    })
    .catch((err) => {
      // Collect any errors and display in Alert banner
      let errors;
      if (err.response) errors = err.response.data.message[0].messages;

      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.message, 'danger')));
      }
    });
};

// Load Child Topic into redux state
export const loadChild = (childTopic) => async (dispatch) => {
  dispatch({
    type: CHILD_LOADING,
  });
  axios
    .get(`${process.env.REACT_APP_API_URL}/child-topics`)
    .then((response) => {
      // Find the Child Topic with UID that matches URL
      const activeChild = response.data.filter((child) => {
        return child.uid === childTopic;
      });

      // Dispatch to redux
      dispatch({
        type: LOAD_CHILD,
        payload: activeChild[0],
      });
    })
    .catch((err) => {
      // Collect any errors and display in Alert banner
      let errors;
      if (err.response) errors = err.response.data.message[0].messages;

      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.message, 'danger')));
      }
    });
};

// Load Learning Module into redux state
export const loadLearningModule = (learningModule) => async (dispatch) => {
  axios
    .get(`${process.env.REACT_APP_API_URL}/learning-modules`)
    .then((response) => {
      // Find the Learning Module with UID that matches URL '#'
      const activeLearningModule = response.data.filter((module) => {
        return module.uid === learningModule;
      });

      // Dispatch to redux
      dispatch({
        type: LOAD_LEARNING_MODULE,
        payload: activeLearningModule[0],
      });
    })
    .catch((err) => {
      // Collect any errors and display in Alert banner
      let errors;
      if (err.response) errors = err.response.data.message[0].messages;

      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.message, 'danger')));
      }
    });
};
