import React, { Fragment, useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
// Connect the Register component with Redux - Step 1
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { setAlert } from '../../../actions/alert';
import { register } from '../../../actions/auth';
// import validation rules
import { emailValidation, passwordValidation } from '../../utils/validationRules';
// import provider login logic
import AuthProviders from '../providers/AuthProviders';
import LoadingSpinner from '../LoadingSpinner';
import userDemographics from './userDemographics';

import dropdownArrow from './assets/chevron-down.svg';

const Register = ({
  isAuthenticated,
  dispatchSetAlert,
  dispatchRegister,
  // dispatchGuestLogin,
  moduleCompletion,
  alert,
}) => {
  // Declaring form data states
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    password2: '',
    userType: '',
  });

  // Declaring show/hide password object
  const [showHide, setShowHide] = useState({
    showPassword: false,
    showCheckPassword: false,
  });

  // setting formdata variables
  const { email, password, password2, userType } = formData;

  // error states
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [passwordMatchingError, setPasswordMatchingError] = useState(false);
  const [isRegistering, setIsRegistering] = useState(false);

  // Validation if the passwords don't match
  const onSubmit = async (e) => {
    e.preventDefault();
    setIsRegistering(true);

    const isValidEmail = emailValidation(email);
    const isValidPassword = passwordValidation(password);

    if (!isValidEmail) {
      setEmailError(true);
      setIsRegistering(false);
    } else if (password !== password2) {
      setPasswordMatchingError(true);
      setIsRegistering(false);
    } else if (!isValidPassword) {
      setPasswordError(true);
      setIsRegistering(false);
    } else {
      setIsRegistering(true);
      setEmailError(false);
      setPasswordError(false);
      setPasswordMatchingError(false);
      dispatchRegister({ email, password, moduleCompletion });
      setTimeout(() => {
        setIsRegistering(false);
      }, 2000);
    }
  };

  // Grab the current formdata state and update it as it changes
  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setEmailError(false);
    setPasswordMatchingError(false);
    setPasswordError(false);
  };

  // Show and hide password
  const passwordVisibilityHandler = (e) => {
    const passwordInput = e.target.parentNode.classList.contains('password-visibility')
      ? e.target.parentNode.previousElementSibling
      : e.target.previousElementSibling;
    // const passwordInput = e.target.parentNode.previousElementSibling.querySelector('input');
    const button = e.target.parentNode.classList.contains('password-visibility')
      ? e.target.parentNode
      : e.target;
    const isAriaPressed = button.getAttribute('aria-pressed') === 'true';
    button.setAttribute('aria-pressed', isAriaPressed ? 'false' : 'true');

    const correctKey = e.keyCode === 13 || e.keyCode === 32;

    if (e.keyCode) {
      if (passwordInput.type === 'password' && correctKey === true) {
        passwordInput.type = 'text';
        if (passwordInput.id === 'password1') {
          setShowHide({ ...showHide, showPassword: true });
        } else {
          setShowHide({ ...showHide, showCheckPassword: true });
        }
      } else if (passwordInput.type !== 'password' && correctKey === true) {
        passwordInput.type = 'password';
        if (passwordInput.id === 'password1') {
          setShowHide({ ...showHide, showPassword: false });
        } else {
          setShowHide({ ...showHide, showCheckPassword: false });
        }
      }
    }

    if (e.keyCode === undefined) {
      if (passwordInput.type === 'password') {
        passwordInput.type = 'text';
        if (passwordInput.id === 'password1') {
          setShowHide({ ...showHide, showPassword: true });
        } else {
          setShowHide({ ...showHide, showCheckPassword: true });
        }
      } else if (passwordInput.type !== 'password') {
        passwordInput.type = 'password';
        if (passwordInput.id === 'password1') {
          setShowHide({ ...showHide, showPassword: false });
        } else {
          setShowHide({ ...showHide, showCheckPassword: false });
        }
      }
    }
  };

  const isShowingAlert = alert?.length > 0;

  // Update class on dropdown button to hide on second click
  const updateDropdownButton = (e) => {
    const dropdownButton = document.querySelector('.dropdown-toggle');
    dropdownButton.classList.toggle('open');

    // If 'open' class is removed, remove focus from element to close dropdown
    if (!dropdownButton.classList.contains('hide') && !dropdownButton.classList.contains('open')) {
      dropdownButton.blur();
    }

    // Toggles the 'hide' class off permanently, prevents bug on first click
    dropdownButton.classList.toggle('hide', false);
  };

  // Helper function to remove 'open' class when user clicks focus away naturally
  const removeClass = () => {
    const dropdownButton = document.querySelector('.dropdown-toggle');
    dropdownButton.classList.remove('open');
  };

  // Redirect if Logged in
  if (isAuthenticated) {
    return <Redirect to="/dashboard" />;
  }

  return (
    <Fragment key="register">
      <div className="link-back">
        <Link to="/" className="back-link-icon">
          <span className="hide-xs">back</span>
        </Link>
      </div>
      <div className="container container-auth text-center register-container">
        <h1>Create an Account</h1>
        <form onSubmit={(e) => onSubmit(e)}>
          <div className="form-group">
            <label htmlFor="email">Email address</label>
            <input
              id="email"
              type="email"
              name="email"
              value={email}
              className={emailError ? 'error-state' : ''}
              onChange={(e) => onChange(e)}
            />
            {emailError && <p className="error-message">Please enter a valid email address</p>}
          </div>
          <div className="form-group password-group">
            <label htmlFor="password1">Password</label>
            <input
              id="password1"
              type="password"
              name="password"
              value={password}
              className={passwordError ? 'error-state' : ''}
              onChange={(e) => onChange(e)}
            />
            {passwordError && (
              <p className="error-message">
                Please ensure your password is longer than 6 characters
              </p>
            )}
            <div
              role="button"
              tabIndex="0"
              aria-pressed="false"
              className="password-visibility visibility"
              onClick={passwordVisibilityHandler}
              onKeyDown={passwordVisibilityHandler}
            >
              {showHide.showPassword === false ? (
                <span className="password-show">show</span>
              ) : (
                <span className="password-hide">hide</span>
              )}
            </div>
          </div>
          <div className="form-group password-group">
            <label htmlFor="password2">Re-enter password</label>
            <input
              id="password2"
              type="password"
              name="password2"
              value={password2}
              className={passwordMatchingError ? 'error-state' : ''}
              onChange={(e) => onChange(e)}
            />
            {passwordMatchingError && <p className="error-message">Passwords do not match</p>}
            <div
              role="button"
              tabIndex="0"
              aria-pressed="false"
              className="password-visibility visibility"
              onClick={passwordVisibilityHandler}
              onKeyDown={passwordVisibilityHandler}
            >
              <span className="password-show">show</span>
              <span className="password-hide">hide</span>
            </div>
          </div>
          <div className="form-group password-group">
            <label htmlFor="type">User Type</label>
            <div className="user-type-dropdown">
              <input
                id="userType"
                type="text"
                name="userType"
                value={userType}
                className="user-type-input"
                placeholder="Select user type..."
                onChange={(e) => onChange(e)}
                disabled
              />
              <div className="dropdown visibility dropdown-button-container">
                <div
                  role="button"
                  tabIndex="0"
                  className="dropdown-toggle hide"
                  onClick={updateDropdownButton}
                  onKeyDown={updateDropdownButton}
                  onBlur={removeClass}
                >
                  <div className="dropdown-arrow">
                    <img src={dropdownArrow} alt="Dropdown icon" className="dropdown-image" />
                  </div>
                </div>
                <ul className="menu">
                  {userDemographics.map((item) => (
                    <Fragment key={item.label}>
                      <li>
                        <button
                          type="button"
                          name="userType"
                          value={item.value}
                          onClick={(e) => onChange(e)}
                          onKeyDown={(e) => onChange(e)}
                        >
                          {item.label}
                        </button>
                      </li>
                      <br />
                    </Fragment>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          <button type="submit" className="btn btn-lg btn-ternary register-btn" name="login">
            {isRegistering && !isShowingAlert ? <LoadingSpinner /> : 'Continue'}
          </button>
        </form>
        <p className="divider-text">
          <span>or</span>
        </p>
        <AuthProviders />
      </div>
    </Fragment>
  );
};

Register.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  dispatchSetAlert: PropTypes.func.isRequired,
  dispatchRegister: PropTypes.func.isRequired,
  moduleCompletion: PropTypes.arrayOf(PropTypes.any).isRequired,
  alert: PropTypes.arrayOf(
    PropTypes.shape({
      msg: PropTypes.string.isRequired,
      alertType: PropTypes.string.isRequired,
      timeout: PropTypes.number,
    })
  ).isRequired,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated, // state.auth would give us every property
  moduleCompletion: state.progressTracker.completedModules,
  alert: state.alert,
});

// Connect the Register component with Redux - Step 2
// first parameter is for whatever state we want to map.
// The second parameter is an object with the actions we need to implement.
// We can access the actions with props
export default connect(mapStateToProps, {
  dispatchSetAlert: setAlert,
  dispatchRegister: register,
})(Register);
