import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { CSSTransition } from 'react-transition-group';

import IntroAnimation from './introAnimation';
import Loading from '../shared/Loading';
import MobileExperienceWarning from './MobileExperienceWarning';

const IntroContainer = () => {
  // set up state variables
  const [position, setPosition] = useState(0);
  const [introText, setIntroText] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [isLoaded, setIsLoaded] = useState(false);
  const [showIntro, setShowIntro] = useState(false);
  const [proceed, setProceed] = useState(false);
  const [goToInstructions, setGoToInstructions] = useState(false);
  const [goToOptions, setGoToOptions] = useState(false);
  const [hideText, setHideText] = useState(false);
  const [showPrev, setShowPrev] = useState(false);
  const [showNext, setShowNext] = useState(true);
  const [instructionVideo, setInstructionVideo] = useState();
  const [hideButtons, setHideButtons] = useState();
  const [instructionClasses, setInstructionClasses] = useState(false);

  function wait(ms) {
    return new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  }

  // Get Intro text from db
  useEffect(async () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/intro`)
      .then(await wait(2000))
      .then((response) => {
        // Handle state updates
        setIntroText(response.data.IntroText);
        setIsLoading(false);
      })
      .catch((err) => {});
  }, []);

  // Get Instruction video from db
  useEffect(async () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/instruction-video`)
      .then(await wait(2000))
      .then((response) => {
        // Handle state updates
        setInstructionVideo(response.data);
      })
      .catch((err) => {});
  }, []);

  const handleClick = (e) => {
    // Proceed to sign in if skip/continue button is pressed
    if (e.target.id === 'continue') {
      setProceed(true);
    }

    // Change position in intro text
    if (e.target.id === 'next' || e.target.parentElement.id === 'next') {
      const newPosition = position + 1;
      setPosition(newPosition);
      if (newPosition === 0) {
        setShowPrev(false);
      } else {
        setShowPrev(true);
      }
      if (newPosition === introText.length - 1) {
        setShowNext(false);
      } else {
        setShowNext(true);
      }
    } else if (e.target.id === 'previous' || e.target.parentElement.id === 'previous') {
      const newPosition = position - 1;
      setPosition(newPosition);
      if (newPosition === 0) {
        setShowPrev(false);
      } else {
        setShowPrev(true);
      }
      if (newPosition === introText.length - 1) {
        setShowNext(false);
      } else {
        setShowNext(true);
      }
    }
  };

  const handleGoTo = () => {
    setHideText(true);
    setTimeout(() => {
      setHideButtons(true);
    }, 1100);
  };

  return (
    <div className={`intro-container${instructionClasses ? ' instructions' : ''}`}>
      <CSSTransition
        in={!isLoaded}
        timeout={1000}
        classNames="intro-container-ani"
        unmountOnExit
        onExited={() => setShowIntro(true)}
      >
        <Loading isLoading={isLoading} setIsLoaded={setIsLoaded} />
      </CSSTransition>
      <CSSTransition in={showIntro} timeout={1000} classNames="intro-container-ani" unmountOnExit>
        <div className="intro-container-ani">
          <h1 className="visuallyhidden">Dementia in New Light</h1>
          <IntroAnimation
            handleClick={handleClick}
            handleGoTo={handleGoTo}
            isLoading={isLoading}
            introText={introText}
            proceed={proceed}
            position={position}
            goToInstructions={goToInstructions}
            setGoToInstructions={setGoToInstructions}
            goToOptions={goToOptions}
            setGoToOptions={setGoToOptions}
            hideText={hideText}
            showNext={showNext}
            showPrev={showPrev}
            instructionVideo={instructionVideo}
            hideButtons={hideButtons}
            setInstructionClasses={setInstructionClasses}
          />
        </div>
      </CSSTransition>
      <MobileExperienceWarning />
    </div>
  );
};

export default IntroContainer;
