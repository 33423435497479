import React from 'react';

const particleCount = 200;

const Particles = () => {
  return (
    <div className="particle-container motion">
      {Array.from(Array(particleCount), (e, i) => {
        return (
          <div key={`particle-${i}`} className="particle-wrapper">
            <div className="particle" />
          </div>
        );
      })}
    </div>
  );
};

export default Particles;
