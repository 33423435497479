import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';

import ChildTopic from './ChildTopic';
import Loading from '../../shared/Loading';

import { loadChild } from '../../../actions/activeModule';

// Once the parent topic is sellected we need to load it into state then display it.
const ChildTopicContainer = ({
  childTopic,
  dispatchLoadChild,
  isLoading,
  backgroundActive,
  setBackgroundActive,
  setIsFound,
}) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [showChild, setShowChild] = useState(false);

  useEffect(() => {
    // When the Component is rendered we want to grab the last string in the url, and remove the '/'
    const { pathname } = window.location;
    const child = pathname.substring(1).split('/')[2];

    // Send API request to load Parent Topic Data into redux
    dispatchLoadChild(child);
  }, []);

  useEffect(() => {
    setIsFound(true);
  }, []);

  return (
    <>
      <CSSTransition
        in={!isLoaded}
        timeout={2000}
        classNames="childTopic"
        unmountOnExit
        onExited={() => setShowChild(true)}
      >
        <Loading setIsLoaded={setIsLoaded} isLoading={isLoading} />
      </CSSTransition>
      <CSSTransition in={showChild} timeout={1000} classNames="childTopic" unmountOnExit>
        <ChildTopic
          childTopic={childTopic}
          isLoading={isLoading}
          backgroundActive={backgroundActive}
          setBackgroundActive={setBackgroundActive}
        />
      </CSSTransition>
    </>
  );
};

ChildTopicContainer.propTypes = {
  childTopic: PropTypes.objectOf(PropTypes.any).isRequired,
  dispatchLoadChild: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  backgroundActive: PropTypes.bool.isRequired,
  setBackgroundActive: PropTypes.func.isRequired,
  setIsFound: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  childTopic: state.activeModule.childTopic,
  isLoading: state.activeModule.childLoading,
  token: state.auth.token,
});

export default connect(mapStateToProps, { dispatchLoadChild: loadChild })(ChildTopicContainer);
