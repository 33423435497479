import axios from 'axios';
import { SESSION_TRACKER, SET_MODULES, UPDATE_MODULES } from './types';

import { setAlert } from './alert';

export const loadModules = (token) => async (dispatch) => {
  // If user has not logged in, get default completion data, else fetch user data
  const sessionModules = JSON.parse(sessionStorage.getItem('progress'));
  if (!token && !sessionModules) {
    axios
      // Set Base data from progress-tracker db entry
      .get(`${process.env.REACT_APP_API_URL}/progress-tracker`)
      .then((response) => {
        dispatch({
          type: SET_MODULES,
          payload: response.data.modules,
        });
      })
      .catch((err) => {
        if (err) {
          const errors = err.response?.data.message[0].messages;

          if (errors) {
            errors.forEach((error) => dispatch(setAlert(error.message, 'danger')));
          }
        }
      });
  } else if (!token && sessionModules) {
    dispatch({
      type: SET_MODULES,
      payload: sessionModules,
    });
  } else {
    axios
      // Set Authorized User Data
      .get(`${process.env.REACT_APP_API_URL}/users/me`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        dispatch({
          type: SET_MODULES,
          payload: response.data.completedModules,
        });
      })
      .catch((err) => {
        if (err) {
          const errors = err.response?.data.message[0].messages;
          if (errors) {
            errors.forEach((error) => dispatch(setAlert(error.message, 'danger')));
          }
        }
      });
  }
};

// Update progress tracker state as users complete modules
export const completeLearningModule =
  (auth, updatedParentTopic, parentTopicIndex) => async (dispatch) => {
    // If user is guest, only update state
    if (!auth.user) {
      dispatch({
        type: SESSION_TRACKER,
        payload: {
          index: parentTopicIndex,
          parentTopic: updatedParentTopic,
        },
      });
      dispatch({
        type: UPDATE_MODULES,
        payload: {
          index: parentTopicIndex,
          parentTopic: updatedParentTopic,
        },
      });
    } else {
      // If User is logged in, update their user data in DB
      let updatedModule;

      // Fetch the user entry
      await axios
        .get(`${process.env.REACT_APP_API_URL}/users/me`, {
          headers: {
            Authorization: `Bearer ${auth.token}`,
          },
        })
        .then(async (response) => {
          // Create new array to modify, then update the relevant parent topic
          updatedModule = response.data.completedModules;
          updatedModule[parentTopicIndex] = updatedParentTopic;
          // Also update application state

          await axios
            .put(
              `${process.env.REACT_APP_API_URL}/users/${auth.user.data.id}`,
              {
                completedModules: updatedModule,
              },
              {
                headers: {
                  Authorization: `Bearer ${auth.token}`,
                },
              }
            )
            .then((r) => {
              dispatch({
                type: UPDATE_MODULES,
                payload: {
                  index: parentTopicIndex,
                  parentTopic: updatedParentTopic,
                },
              });
            });
        })
        .catch((err) => {
          if (err) {
            const errors = err.response?.data.message[0].messages;

            if (errors) {
              errors.forEach((error) => dispatch(setAlert(error.message, 'danger')));
            }
          }
        });
    }
  };
