import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Citation from '../content/learningModules/learning-modules/Citation';

const CitationToolTip = ({ citation }) => {
  const [showCitation, setShowCitation] = useState(false);

  return (
    <>
      <button
        type="button"
        onClick={() => setShowCitation(!showCitation)}
        className={`button-controls citation-button ${showCitation && 'active'}`}
      >
        <CitationPopup citation={citation} showCitation={showCitation} />
        <i className="citation-icon" />
      </button>
    </>
  );
};

const CitationPopup = ({ showCitation, citation }) => {
  return (
    <div className={`citation-box ${showCitation ? 'show' : ''}`}>
      <Citation citation={citation} />
    </div>
  );
};

CitationToolTip.propTypes = {
  citation: PropTypes.string.isRequired,
};

CitationPopup.propTypes = {
  showCitation: PropTypes.bool.isRequired,
  citation: PropTypes.string.isRequired,
};

export default CitationToolTip;
