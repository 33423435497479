import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { turnOffTooltip } from '../../actions/auth';

const ToolTip = ({ toolType, offset, auth, dispatchTurnOffTooltip }) => {
  const [body, setBody] = useState('');
  const [step, setStep] = useState(0);

  // Add to these as necessary if there are extra steps
  const browserOnboarding = [
    'Track progress, manage saved items, and share your experience.',
    'Manage account and settings, and learn more about Cracked',
  ];

  const moduleOnboarding = ['Browse content by scrolling or by using this navigation'];

  useEffect(() => {
    if (toolType === 'browser-onboarding') setBody(browserOnboarding[0]);
    if (toolType === 'module-onboarding') setBody(moduleOnboarding[0]);
  }, []);

  const turnOff = async (toggle) => {
    if (auth.user) {
      const user = auth.user.data;
      await dispatchTurnOffTooltip(user.id, auth.token, toolType, toggle);
    } else {
      await dispatchTurnOffTooltip(null, null, toolType, toggle);
    }
  };

  const nextStep = () => {
    if (toolType === 'browser-onboarding') setBody(browserOnboarding[step + 1]);
    if (toolType === 'module-onboarding') setBody(moduleOnboarding[step + 1]);
    setStep(step + 1);
  };

  return (
    // Tool type is passed in and determines positioning. (see GlobalMenu and ChildTopic files)
    <div className={`tool-tip-popup ${toolType}-${step} y-offset-${offset}`}>
      <div className="tool-tip-body">
        {body}
        <div className="cta">
          <span
            className="first-cta"
            onClick={() => turnOff(false)}
            onKeyDown={() => {}}
            role="button"
            tabIndex="0"
          >
            Don&apos;t show any tips
          </span>
          <div
            className="btn btn-primary cta-btn"
            type="button"
            // If more steps are added, change the logic underneath to fit
            onClick={() =>
              step === 0 && toolType !== 'module-onboarding' ? nextStep() : turnOff()}
            onKeyDown={() => {}}
            role="button"
            tabIndex="0"
          >
            {step === 0 && toolType !== 'module-onboarding' ? 'Next' : 'Got it'}
            {step === 0 && toolType !== 'module-onboarding' && (
              <span className="next-arrow">→</span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

ToolTip.defaultProps = {
  offset: '0',
};

ToolTip.propTypes = {
  auth: PropTypes.shape({
    token: PropTypes.string,
    isAuthenticated: PropTypes.bool,
    user: PropTypes.objectOf(PropTypes.any),
  }).isRequired,
  toolType: PropTypes.string.isRequired,
  offset: PropTypes.string,
  dispatchTurnOffTooltip: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {
  dispatchTurnOffTooltip: turnOffTooltip,
})(ToolTip);
