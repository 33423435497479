import React from 'react';
import PropTypes from 'prop-types';
import logoDark from '../../assets/images/dementia-logo-black@2x.png';

export const Certificate = React.forwardRef((props, ref) => {
  let today = new Date();
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  today = today.toLocaleDateString('en-US', options);

  return (
    <section ref={ref} className="completion-certificate">
      <div className="certificate-container">
        <h1>Certificate of Completion</h1>
        <h2 className="name">{props.name}</h2>
        <p className="thank-you-message">
          has successfully completed
          {' '}
          <a href="https://dementiainnewlight.com" style={{ fontStyle: 'italic' }}>
            Dementia in New Light: A Digital Learning Experience
          </a>
          {' '}
          (https://dementiainnewlight.com).
        </p>
        <p className="thank-you-message">
          The 
          {' '}
          <span style={{ fontStyle: 'italic' }}>Dementia in New Light</span>
          {' '}
          team would like to
          thank you for participating in reimagining dementia. We all have a part to play in
          creating a better world for everyone affected by dementia.
        </p>
        <p className="thank-you-message">{today}</p>
        <div className="image-container">
          <img className="completed-logo" src={logoDark} alt="Cracked Logo" />
        </div>
      </div>
    </section>
  );
});

Certificate.propTypes = {
  name: PropTypes.string.isRequired,
};
