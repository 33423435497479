import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { FacebookShareButton, LinkedinShareButton, TwitterShareButton } from 'react-share';
import UserControls from '../UserControls';

const MenuFooter = ({ closeModal, auth, hideControls, showGlobalMenu }) => {
  const popUp = useRef(null);

  useEffect(() => {
    // const closePopUpMenu = (e) => {
    //   if (popUp.current) {
    //     // if (e.target === popUp.current.parentNode) {
    //     //   return;
    //     // }
    //     // if (e.target !== popUp.current && popUp.current.classList.contains('show')) {
    //     //   popUp.current.classList.remove('show');
    //     // }
    //   }
    // };
    // window.addEventListener('mousedown', (e) => {
    //   closePopUpMenu(e);
    // });
  });

  const popUpMenu = (e) => {
    // Prevents keys other than enter from opening popup
    if (e.keyCode && e.keyCode === 13) {
      popUp.current.classList.toggle('show');
    } else if (!e.keyCode) {
      popUp.current.classList.toggle('show');
    }
  };

  return (
    <div className="footer-container">
      <nav className="menu-footer-navigation">
        <Link className="footer-link clickable" to="/about" onClick={() => closeModal()}>
          <div className="link-container">
            <i className="about-icon" />
            About
          </div>
        </Link>
        <Link className="footer-link clickable" to="/saved" onClick={() => closeModal()}>
          <div className="link-container">
            <i className="saved-icon" />
            Saved
          </div>
        </Link>
        {/* <div
          className="footer-link popUp-button"
          onClick={popUpMenu}
          onKeyDown={popUpMenu}
          role="button"
          tabIndex="0"
        >
          <div className="link-container">
            <div className="popup" ref={popUp}>
              <div className="popup-link">
                <LinkedinShareButton
                  url={`${process.env.REACT_APP_URL}`}
                  title="Cracked"
                  description="This is cracked"
                  source="Cracked: Dementia in new light"
                >
                  <i className="linkedIn-icon" />
                </LinkedinShareButton>
              </div>
              <div className="popup-link">
                <TwitterShareButton url={`${process.env.REACT_APP_URL}`} title="Cracked">
                  <i className="twitter-icon" />
                </TwitterShareButton>
              </div>
              <div className="popup-link">
                <FacebookShareButton
                  url={`${process.env.REACT_APP_URL}`}
                  quote="Cracked: Dementia in lew light"
                >
                  <i className="facebook-icon" />
                </FacebookShareButton>
              </div>
            </div>
            <i className="share-icon" />
            Share
          </div>
        </div> */}
        <Link className="footer-link clickable" to="/resources" onClick={() => closeModal()}>
          <div className="link-container">
            <i className="resources-icon" />
            Resources
          </div>
        </Link>
        <div className="legal-container">
          <div>
            <div className="legal-links">
              <Link
                to="/privacy"
                onClick={() => {
                  closeModal();
                }}
              >
                Privacy Policy
              </Link>
              <Link
                to="/terms-and-conditions"
                onClick={() => {
                  closeModal();
                }}
              >
                Terms & Conditions
              </Link>
            </div>
            <p>©2021 Dementia in New Light</p>
          </div>
          <UserControls
            hideControls={hideControls}
            closeModal={closeModal}
            showGlobalMenu={showGlobalMenu}
          />
        </div>
      </nav>
    </div>
  );
};

MenuFooter.propTypes = {
  closeModal: PropTypes.func.isRequired,
  auth: PropTypes.objectOf(PropTypes.any).isRequired,
  hideControls: PropTypes.bool.isRequired,
  showGlobalMenu: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(MenuFooter);
