import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

const Alert = ({ alerts }) => {
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    if (alerts !== null && alerts.length > 0) {
      setShowAlert(true);
    }
    return () => {
      setShowAlert(false);
    };
  });

  return (
    <TransitionGroup>
      {alerts !== null &&
        alerts.length > 0 &&
        alerts.map((alert) => (
          <CSSTransition classNames="alert" timeout={500} key={alert.id}>
            <div key={alert.id} className={`alert alert-${alerts[0].alertType}`}>
              {alert.msg}
            </div>
          </CSSTransition>
        ))}
    </TransitionGroup>
  );
};

Alert.propTypes = {
  alerts: PropTypes.arrayOf(
    PropTypes.shape({
      msg: PropTypes.string.isRequired,
      alertType: PropTypes.string.isRequired,
      timeout: PropTypes.number,
    })
  ).isRequired,
};

const mapStateToProps = (state) => ({
  alerts: state.alert,
});

export default connect(mapStateToProps)(Alert);
