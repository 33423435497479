import React from 'react';
import PropTypes from 'prop-types';

const MobileShardLabel = ({
  label,
  labelState,
  onLabelClick,
  completed,
  max,
  labelUid,
  tabIndex,
}) => {
  return (
    <div className={['mobile-shard-label', 'shard-label', ''].join(' ')}>
      <div>
        <p>{label}</p>
        <div className="topic-status">
          <span className="topic-status-label">topics explored</span>
          <div className="progress-bar">
            <div
              className="progress"
              style={{
                width: `${(completed / max) * 100}%`,
              }}
            />
          </div>
          <span className="topic-status-progress">
            {completed}
            /
            {max}
          </span>
        </div>
      </div>
      <button
        className="btn-explore"
        type="button"
        onClick={onLabelClick}
        onKeyDown={() => {}}
        uid={labelUid}
        tabIndex={tabIndex}
      >
        Explore
      </button>
    </div>
  );
};

MobileShardLabel.propTypes = {
  label: PropTypes.string.isRequired,
  labelState: PropTypes.number.isRequired,
  onLabelClick: PropTypes.func.isRequired,
  completed: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  labelUid: PropTypes.string.isRequired,
  tabIndex: PropTypes.number.isRequired,
};

export default MobileShardLabel;
