import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// import { loadModules } from '../actions/progressTracker';

import ParentTopicBrowser from './content/parentTopics/ParentTopicBrowser';
import MobileParentTopicBrowser from './content/parentTopics/mobile-parent-topics/MobileParentTopicBrowser';
import { detectIsMobile, isSmallViewport, isLowBandwidth } from './utils/TopicBrowserUtils';

const Dashboard = ({ auth }) => {
  const [isMobile, setIsMobile] = useState(true);

  const onResize = (e) => {
    if (!isLowBandwidth(auth.user)) {
      if (isSmallViewport()) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    }
  };

  useEffect(() => {
    if (isLowBandwidth(auth.user)) {
      setIsMobile(true);
    } else {
      const hasTouchScreen = detectIsMobile();

      if (hasTouchScreen) {
        setIsMobile(true);
      } else if (isSmallViewport()) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }

      window.addEventListener('resize', onResize);
    }

    return () => {
      window.removeEventListener('resize', onResize);
    };
  });

  return <>{isMobile ? <MobileParentTopicBrowser /> : <ParentTopicBrowser />}</>;
};

Dashboard.propTypes = {
  auth: PropTypes.shape({
    token: PropTypes.string,
    isAuthenticated: PropTypes.bool,
    user: PropTypes.objectOf(PropTypes.any),
  }).isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(Dashboard);
