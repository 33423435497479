import relationshipInactiveUrl from '../../assets/textures/shard-relationships-inactive.jpg';
import relationshipActiveUrl from '../../assets/textures/shard-relationships-active.jpg';
import stigmaInactiveUrl from '../../assets/textures/shard-stigma-inactive.jpg';
import stigmaActiveUrl from '../../assets/textures/shard-stigma-active.jpg';
import identityInactiveUrl from '../../assets/textures/shard-identity-inactive.jpg';
import identityActiveUrl from '../../assets/textures/shard-identity-active.jpg';
import cultureInactiveUrl from '../../assets/textures/shard-culture-inactive.jpg';
import cultureActiveUrl from '../../assets/textures/shard-culture-active.jpg';
import possibilitiesInactiveUrl from '../../assets/textures/shard-possibilities-inactive.jpg';
import possibilitesActiveUrl from '../../assets/textures/shard-possibilities-active.jpg';
import alphaMap from '../../assets/textures/dissolve-alpha-map.jpg';

export function getRandomIntInclusive(minValue, maxValue) {
  const min = Math.ceil(minValue);
  const max = Math.floor(maxValue);
  return Math.floor(Math.random() * (max - min + 1) + min); // The maximum is inclusive and the minimum is inclusive
}

export function degreesToRadians(valInDegrees) {
  return valInDegrees * (Math.PI / 180);
}

export function easeInCirc(val) {
  return 1 - Math.sqrt(1 - val ** 2);
}

export function getPercentageScrolled(dis, h, element) {
  if (element != null) {
    const distanceScrolled = dis;
    const height = h;
    const totalHeight = element.clientHeight;
    const calcHeight = totalHeight - height;
    return distanceScrolled / calcHeight;
  }

  return 0;
}

export function setPercentageScrolled(element, percent) {
  if (element != null) {
    const height = window.innerHeight;
    const totalHeight = element.clientHeight;
    const calcHeight = totalHeight - height;

    window.scrollTo(0, calcHeight * percent);
  }
}

export function getTextureUrls() {
  return [
    possibilitiesInactiveUrl,
    possibilitesActiveUrl,
    cultureInactiveUrl,
    cultureActiveUrl,
    identityInactiveUrl,
    identityActiveUrl,
    stigmaInactiveUrl,
    stigmaActiveUrl,
    relationshipInactiveUrl,
    relationshipActiveUrl,
    alphaMap,
  ];
}

export function detectIsMobile() {
  let hasTouchScreen = false;
  if ('maxTouchPoints' in navigator) {
    hasTouchScreen = navigator.maxTouchPoints > 0;
  } else if ('msMaxTouchPoints' in navigator) {
    hasTouchScreen = navigator.msMaxTouchPoints > 0;
  } else {
    const mQ = window.matchMedia && matchMedia('(pointer:coarse)');

    if (mQ && mQ.media === '(pointer:coarse)') {
      hasTouchScreen = !!mQ.matches;
    } else if ('orientation' in window) {
      hasTouchScreen = true;
    } else {
      const UA = navigator.userAgent;

      hasTouchScreen =
        /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(UA) ||
        /\b(Android|Windows Phone|iPad|iPod)\b/i.test(UA);
    }
  }

  return hasTouchScreen;
}

export function isSmallViewport() {
  const windowWidth = window.innerWidth;

  if (windowWidth <= 650) {
    return true;
  }

  return false;
}

export function isLowBandwidth(userObject) {
  // If a user is logged in
  if (userObject) {
    if ('data' in userObject) {
      if (userObject.data.lowBandwidthMode) {
        return true;
      }
    }

    return false;
  }

  // Guest user, check local storage
  const lowBandwidth = localStorage.getItem('lowBandwidth') === 'true';

  if (lowBandwidth) {
    return true;
  }

  return false;
}

export function clamp(val, min, max) {
  if (val < min) {
    return min;
  }

  if (val > max) {
    return max;
  }

  return val;
}

export function sortParentTopics(list) {
  const sorted = list.sort(function (a, b) {
    if (a.id < b.id) return -1;
    if (a.id > b.id) return 1;
    return 0;
  });

  return sorted;
}

export function sortChildTopics(list) {
  const sorted = list.sort(function (a, b) {
    if (a.order < b.order) return -1;
    if (a.order > b.order) return 1;
    return 0;
  });

  return sorted;
}

export function topicShardVertShader() {
  return `
    varying vec2 vUv;
    varying vec3 vecPos;
    varying vec3 vecNormal;
    varying vec3 testPos;

    void main(){
      vUv = uv;
      testPos = position;

      //Since the light is in camera coordinates, I'll need the vertex position in camera coord too
      vecPos = (modelViewMatrix * vec4(position, 1.0)).xyz;

      // That's NOT exacly how you should transform your
      // normals but this will work fine, since my model
      // matrix is pretty basic
      vecNormal = (modelViewMatrix * vec4(normal, 0.0)).xyz;

      vec4 modelViewPosition = modelViewMatrix * vec4(position, 1.0);

      gl_Position = projectionMatrix * modelViewPosition;
    }
    `;
}

export function topicShardFragShader() {
  return `
    varying vec2 vUv;
    varying vec3 vecPos;
    varying vec3 vecNormal;
    varying vec3 testPos;

    struct Material{
      vec3 ambient;
      vec3 diffuse;
      vec3 specular;
      float shininess;
    };

    struct DirectionalLight {
      vec3 direction;
      vec3 color;
    };

    struct PointLight {
      vec3 position;
      vec3 color;
      float decay;
      float distance;
    };

    uniform Material material;

    #if NUM_DIR_LIGHTS > 0
    uniform DirectionalLight directionalLights[ NUM_DIR_LIGHTS ];
    #endif

    #if NUM_POINT_LIGHTS > 0
    uniform PointLight pointLights[ NUM_POINT_LIGHTS ];
    #endif

    uniform vec3 ambientLightColor;

    uniform sampler2D texActive;
    uniform sampler2D texInactive;
    uniform sampler2D alphaMap;

    uniform float mixValue;

    //Prototype function defined before the main function so it can be called in the main function. Function definition is written at the bottom
    vec3 CalcDirLight(DirectionalLight light, vec3 normal, vec3 viewDir);
    vec3 CalcPointLight(PointLight light, vec3 normal, vec3 fragPos, vec3 viewDir);

    void main(){
      //Properties
      vec3 norm = normalize(vecNormal);
      vec3 viewPos = cameraPosition;
      vec3 viewDir = normalize(viewPos - vecPos);

      vec3 results = vec3(0.0);

      //Directional Lights
      #if NUM_DIR_LIGHTS > 0
      for(int i=0 ; i<NUM_DIR_LIGHTS ; i++){
        results += CalcDirLight(directionalLights[i], norm, viewDir);
      }
      #endif

      //Point Lights
      #if NUM_POINT_LIGHTS > 0
      for(int i=0 ; i<NUM_POINT_LIGHTS ; i++){
        results += CalcPointLight(pointLights[i], norm, vecPos, viewDir);
      }
      #endif

      //Ambient lighting
      vec3 ambient = ambientLightColor * material.ambient;
      results += ambient;

      //Texture map mix
      vec3 n = normalize(testPos);

      vec4 objectColor = texture2D(texInactive, vUv);
      vec4 nexColor = texture2D(texActive, vUv);
      vec4 alphaMapColor = 1.0 - texture2D(alphaMap, vec2(n.x, n.y + 0.5));
      // vec4 alphaMapColor = 1.0 - texture2D(alphaMap, vUv);

      float blendVal = 0.0;

      blendVal = clamp(alphaMapColor.r - mixValue + 1.0, 0.0, 1.0);

      vec3 blendColor = mix(objectColor.rgb, nexColor.rgb, blendVal);

      results *= blendColor;
      
      // results = vec3(n.y + 0.5);

      gl_FragColor =  vec4(results, 1.0);
    }

    vec3 CalcDirLight(DirectionalLight light, vec3 normal, vec3 viewDir){
      vec3 lightDir = normalize(light.direction);

      //Diffuse Shading
      float diff = max(dot(normal, lightDir), 0.0);

      //Specular Shading
      vec3 reflectDir = reflect(-lightDir, normal);
      float spec = pow(max(dot(viewDir, reflectDir), 0.0), material.shininess);

      //Combine results
      vec3 diffuse = light.color * (diff * material.diffuse);
      vec3 specular = light.color * (spec * material.specular);

      return (diffuse + specular);
    }

    vec3 CalcPointLight(PointLight light, vec3 normal, vec3 fragPos, vec3 viewDir){
      vec3 lightDir = normalize(light.position - fragPos);

      //Diffuse Shading
      float diff = max(dot(normal, lightDir), 0.0);

      //Specular Shading
      vec3 reflectDir = reflect(-lightDir, normal);
      float spec = pow(max(dot(viewDir, reflectDir), 0.0), material.shininess);

      //Combine results
      vec3 diffuse = light.color * (diff * material.diffuse);
      vec3 specular = light.color * (spec * material.specular);

      diffuse *= light.decay;
      specular *= light.decay;

      return (diffuse + specular);
    }
    `;
}
