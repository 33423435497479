import {
  LOAD_MENU,
  LOAD_ALL_PARENTS,
  LOAD_PARENT,
  LOAD_ALL_CHILDREN,
  CHILD_LOADING,
  LOAD_CHILD,
  LOAD_LEARNING_MODULE,
  INIT_LOAD_PARENT,
  SAVE_MODULES,
} from '../actions/types';

const initialState = {
  loadingTopics: true,
  parentTopics: [],
  parentMenu: [],
  parentLoading: true,
  parentTopic: null,
  childTopics: [],
  childMenu: {},
  childLoading: true,
  childTopic: {},
  learningModuleLoading: true,
  learningModule: null,
  savedModules: {},
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case LOAD_MENU: {
      const parentTopics = payload.menu.map((item) => {
        return item.parent_topic;
      });
      const childTopics = payload.menu
        .map((item) => {
          return item.child_topics;
        })
        .flat();
      return {
        ...state,
        parentMenu: parentTopics,
        childMenu: childTopics,
      };
    }
    case LOAD_ALL_PARENTS:
      return {
        ...state,
        parentTopics: payload,
        loadingTopics: false,
      };
    case LOAD_PARENT:
      return {
        ...state,
        parentTopic: payload,
        parentLoading: false,
      };
    case LOAD_ALL_CHILDREN:
      return {
        ...state,
        childTopics: payload,
      };
    case CHILD_LOADING:
      return {
        ...state,
        childLoading: true,
      };
    case LOAD_CHILD:
      return {
        ...state,
        childTopic: payload,
        childLoading: false,
      };
    case LOAD_LEARNING_MODULE:
      return {
        ...state,
        learningModule: payload,
        learningModuleLoading: false,
      };
    case INIT_LOAD_PARENT:
      return {
        ...state,
        parentTopic: null,
        parentLoading: true,
      };
    case SAVE_MODULES:
      return {
        ...state,
        savedModules: { ...payload },
      };
    default:
      return state;
  }
}
