import React from 'react';

import PropTypes from 'prop-types';

const SubMenuIntro = ({ percentLoaded, hasLoaded, topic, isVisible, onSubMenuContinueClick }) => {
  return (
    <div className={['sub-menu-intro', isVisible ? '' : 'hidden'].join(' ')}>
      <h3>{topic.name}</h3>
      <p className="serif-text text-xl">{topic.description}</p>
      {/* <p className={['numeral-light', hasLoaded ? 'completed' : ''].join(' ')}>
        {`${percentLoaded}%`}
      </p> */}
      <button
        className={[
          'btn btn-icon-md btn-secondary child-intro-btn',
          // topic.UID,
          hasLoaded ? 'completed' : '',
        ].join(' ')}
        type="button"
        onClick={onSubMenuContinueClick}
      >
        Continue
      </button>
    </div>
  );
};

SubMenuIntro.propTypes = {
  percentLoaded: PropTypes.number.isRequired,
  hasLoaded: PropTypes.bool.isRequired,
  topic: PropTypes.objectOf(PropTypes.any).isRequired,
  isVisible: PropTypes.bool.isRequired,
  onSubMenuContinueClick: PropTypes.func.isRequired,
};

export default SubMenuIntro;
