import React, { Component } from 'react';

import PropTypes from 'prop-types';

const TopicBrowserTopNav = ({ parentTopics, currentActiveTopic, isVisible, handleTopNavClick }) => {
  const onNavClick = (e) => {
    const index = e.currentTarget.tabIndex - 3;
    handleTopNavClick(index);
  };

  const onKey = (e) => {
    if (e.keyCode === 32) {
      const index = e.target.tabIndex - 3;
      handleTopNavClick(index);
    }
  };

  return (
    <div className="top-nav-container">
      <ul className={['top-nav', isVisible ? 'active' : ''].join(' ')}>
        {parentTopics.map((l, i) => {
          let isActive = '';
          if (i === parseInt(currentActiveTopic, 10)) {
            isActive = 'topic-active';
          }
          return (
            <li key={l.UID} className={[`topic-${l.UID.toLocaleLowerCase()}`, isActive].join(' ')}>
              <div role="button" tabIndex={i + 6} onClick={onNavClick} onKeyDown={onKey}>
                <span className="top-nav-number">{i + 1}</span>
                <span className="top-nav-label">{l.name}</span>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

TopicBrowserTopNav.defaultProps = {
  handleTopNavClick: null,
};

TopicBrowserTopNav.propTypes = {
  parentTopics: PropTypes.arrayOf(PropTypes.any).isRequired,
  currentActiveTopic: PropTypes.number.isRequired,
  isVisible: PropTypes.bool.isRequired,
  handleTopNavClick: PropTypes.func,
};

export default TopicBrowserTopNav;
